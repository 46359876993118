import {logger} from "../../../logger/Logger";

//Implements a generic progressive reader for OTG fixed record size binary streams,
//including the material and geometry hash lists and the fragment list.
export function ProgressiveReadContext(itemCB, defaultByteStride) {

	let currentRow = 0;
	let byteStride;
	let version;
	let bdata;
	let fdata;
	let idata;
	let i16data;

	const HEADER_SIZE = 4;
	const BUFFER_SIZE_ELEMENTS = 1024; // completely arbitrary

	let pendingSources = new Array();
	let src;
	let srcOffset = 0;
	let dst = new Uint8Array(HEADER_SIZE);
	let dstWriteOffset = 0;
	let dstReadOffset = 0;
	let skipBytes;


	function readHeader() {

		byteStride = (dst[1] << 8) | dst[0];

		if (!byteStride)
			byteStride = defaultByteStride || 0;

		if (!byteStride)
			logger.error("Unknown byte stride.");

		if (byteStride % 4)
			logger.error("Expected byte size to be multiple of 4, but got " + byteStride);

		version = (dst[3] << 8) | dst[2];

		if (version > 0) {
			//currently unused
			//var flags = idata[offset+3];
		}

		skipBytes = byteStride - HEADER_SIZE;
	}


	this.onData = function(chunk) {
		if (chunk) {
			if (!src) {
				src = chunk;
				srcOffset = 0;
			} else {
				pendingSources.push(chunk);
			}
		}

		while (src) {
			// copy over bytes
			let bytesToRead = Math.min(dst.length - dstWriteOffset, src.length - srcOffset);
			if (bytesToRead > 0) {
				dst.set(src.subarray(srcOffset, srcOffset + bytesToRead), dstWriteOffset);
				srcOffset += bytesToRead;
				dstWriteOffset += bytesToRead;
			}

			if (!currentRow) { // consume header
				if (dstWriteOffset === dst.length) {
					readHeader();
					dst = new Uint8Array(skipBytes);
					dstWriteOffset = 0;
					currentRow++;
				}
			}
			else if (skipBytes > 0) { // consume padding
				if (dstWriteOffset === dst.length) {
					skipBytes = 0;

					bdata = new Uint8Array(byteStride * BUFFER_SIZE_ELEMENTS);
					fdata = new Float32Array(bdata.buffer);
					idata = new Uint32Array(bdata.buffer);
					i16data = new Uint16Array(bdata.buffer);
					dst = bdata;
					dstWriteOffset = 0;
				}
			}
			else { // actually process an element
				//The callback will return true if it was able
				//to process the item at this time. If not, we will
				//call it later with the same item, until it accepts it.
				while (dstReadOffset + byteStride <= dstWriteOffset) {
					if (!itemCB(this, dstReadOffset, currentRow)) {
						return;
					}
					dstReadOffset += byteStride;
					currentRow++;
				}
				// dst buffer full, reset
				if (dstWriteOffset === dst.length) {
					dstReadOffset = 0;
					dstWriteOffset = 0;
				}
			}
			// source ran dry, get a new one
			if (srcOffset === src.length) {
				src = pendingSources.shift();
				srcOffset = 0;
			}
		}
	};

	this.flush = function() {
		this.onData(null);

		onDoneCb();

		if (dstWriteOffset % byteStride > 0) {
			logger.warn("unexpected extra bytes at end of stream");
		}
	};

	this.idata = function() { return idata; };
	this.i16data = function() { return i16data; };
	this.fdata = function() { return fdata; };
	this.bdata = function() { return bdata; };
	this.version = function() { return version; };
	this.byteStride = function() { return byteStride; };
	let onDoneCb = () => null;
	this.done = new Promise(resolve => onDoneCb = resolve);
}
