import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { CameraUniforms } from "./CameraUniforms";
import { IBL } from "./IBL";

export class FrameBindGroup {

    /**
     * @param {number} bindGroup
     * @returns {string}
     */
    static getDeclaration(bindGroup) {
        return $wgsl(`
            $cameraUniforms
            $iblUniforms
        `, {
            cameraUniforms: CameraUniforms.getDeclaration(bindGroup, 0),
            iblUniforms: IBL.getDeclaration(bindGroup, 1),
        });
    }

    /** @type {GPUDevice} */
    #device;
    /** @type {CameraUniforms} */
    #cameraUniforms;
    /** @type {IBL} */
    #ibl;

    /** @type {GPUBindGroupLayout} */
    #bindGroupLayout;
    /** @type {GPUBindGroup} */
    #bindGroup;

    /**
     * @param {GPUDevice} cameraUniforms
     * @param {CameraUniforms} cameraUniforms
     * @param {IBL} ibl
     */
    constructor(device, cameraUniforms, ibl) {
        this.#device = device;
        this.#cameraUniforms = cameraUniforms;
        this.#ibl = ibl;

        this.#bindGroupLayout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: 0,
                    visibility: this.#cameraUniforms.getVisibility(),
                    buffer: {}
                },
                ...this.#ibl.getLayoutEntries(1),
            ]
        });
    }

    updateBindGroup() {
        this.#bindGroup = this.#device.createBindGroup({
            layout: this.#bindGroupLayout,
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer: this.#cameraUniforms.getBuffer(),
                    }
                },
                ...this.#ibl.getEntries(1),
            ]
        });
    }

    getLayout() {
        return this.#bindGroupLayout;
    }

    getBindGroup() {
        return this.#bindGroup;
    }

}
