import * as globals from '../globals';
import { FrustumIntersector } from './FrustumIntersector';
import * as THREE from "three";
import { RenderFlags } from "./RenderFlags";
import { ResetFlags } from "./ResetFlags";
import { ModelExploder } from "./ModelExploder";
import { OutOfCoreTileManager } from './out-of-core-tile-manager/OutOfCoreTileManager';
import { ConsolidatedRenderBatch } from "./RenderBatch";

/** @import { RenderBatch } from "./RenderBatch" */
/** @import { RenderModel } from "./RenderModel" */

/*
 * Keep these threeJs objects file local for performance reasons,
 * as these are changed frequently, so we keep expensive object creation minimal.
 */
const tmpBox = new THREE.Box3(); // Reused for return values of getVisibleBounds()

/**
 * RenderScene
 * Represents the full graphical scene.
 * Used for iterating through the scene for progressive rendering,
 * hit testing, etc.
 */
export class RenderScene
{
    // true indicates that progressive rendering has finished
    // since last reset call, i.e. all batches have been traversed.
    #done = false;                              

    /** @type {RenderModel[]} - All RenderModels to be rendered. */
    #models = [];

    /** @type {RenderBatch[]} - points to the next batch to be rendered from _models[i]. Same length as _models. */
    #candidateRenderBatches = []; 

    /** @type {RenderBatch[]} - points to the previous batch rendered from _models[i]. Same length as _models. */
    #previousRenderBatches = [];    

    /** @type {RenderModel[]} - All models that are currently loaded, but excluded from rendering/selection etc. */
    #hiddenModels = [];

    // updated for current camera in this.reset().
    #frustum = new FrustumIntersector();

    #raycaster = new THREE.Raycaster();

    // During motion, we usually restart rendering at any frame, i.e. a frame is never resumed. When setting this
    // option, we exploit this to render transparent shapes earlier. (and skip less important opaque ones)
    enableNonResumableFrames = false;

    // Determines how much of the render budget is reserved for transparent shapes.
    // E.g., a value of 0.1 means that 10% of the render budget is spent for transparent shapes.
    budgetForTransparent = 0.1;

    // If true, we assume the current frame not to be resumed and
    // render some transparent shapes before the opaque ones are done.
    #frameWillNotBeResumed = false;

    // If frameWillNotBeResumed is true, this array collects transparent renderbatches and renders them
    // back-to-front at the end of a frame.
    #transparentRenderBatches = [];

    // needed for back-to-front sorting of transparent objects (see renderTransparentRenderBatches)
    #camera = null;

    /** @type {RenderBatch|undefined} A renderbatch that got interrupted in the last pass and did not yet finish*/
    interruptedRenderBatch = undefined;

    constructor() {
    }

    frustum() {
        return this.#frustum;
    }
    
    #findById(models, modelId) {
        for (let i = 0; i < models.length; i++) {
            const model = models[i];
            if (model && model.id === modelId) {
                return model;
            }
        }
        return null;
    }

    findModel(modelId) { 
        return this.#findById(this.#models, modelId);
    }

    findHiddenModel(modelId) { 
        return this.#findById(this.#hiddenModels, modelId); 
    }

    addModel(renderModel) {
        if (this.#models.indexOf(renderModel) !== -1) {
            return;
        }

        this.#models.push(renderModel);
        this.#candidateRenderBatches.length = this.#models.length;
        this.#previousRenderBatches.length = this.#models.length;
        this.recomputeLinePrecision();
    }

    removeModel(renderModel) {
        const idx = this.#models.indexOf(renderModel);
        if (idx >= 0) {
            this.#models.splice(idx, 1);
        }

        this.#candidateRenderBatches.length = this.#models.length;
        this.#previousRenderBatches.length = this.#models.length;
        this.recomputeLinePrecision();

        return idx >= 0;
    }

    addHiddenModel(renderModel) {
        const idx = this.#hiddenModels.indexOf(renderModel);
        if (idx < 0) {
            this.#hiddenModels.push(renderModel);
        }
        return idx < 0;
    }

    removeHiddenModel(renderModel) {
        const idx = this.#hiddenModels.indexOf(renderModel);
        if (idx >= 0) {
            this.#hiddenModels.splice(idx, 1);
        }
        return idx >= 0;
    }

    isEmpty() {
        return this.#models.length === 0;
    }

    recomputeLinePrecision() {
        let value = 1;
        const sizeTarget = new THREE.Vector3();

        for (let i = 0, len = this.#models.length; i < len; ++i) {
            const modelBox = this.#models[i].getData().bbox;

            // Skip empty boxes, as they lead to a zero threshold
            if (modelBox.getSize(sizeTarget).length() === 0) {
                continue;
            }

            // Note that modelBox.getBoundingSphere() may not exist if the box is an LmvBox3. 
            const modelValue = THREE.Box3.prototype.getBoundingSphere.call(modelBox, new THREE.Sphere()).radius * 0.001;
            value = Math.min(value, modelValue);
        }

        this.#raycaster.params.Line.threshold = value;
    }

    /**
     *  For each sub-scene, keep a running average of how long it took to render over the
     *  last few frames.
     *   @param {THREE.Scene|RenderBatch} renderbatch
     *   @param {number}                  frameTime - last measured rendering time in ms
     */
    _updateAvgFrameTime(renderBatch, frameTime) {        
        if (renderBatch.avgFrameTime === undefined) {
            renderBatch.avgFrameTime = frameTime;            
            renderBatch.outliers = [];
            return;
        }
        
        const outlier =  (frameTime < 0.7 * renderBatch.avgFrameTime || frameTime > 1.5 * renderBatch.avgFrameTime);
        let updateTime = true;
        if (outlier) {
            renderBatch.outliers.push(frameTime);
            updateTime = false;
            if (renderBatch.outliers.length > 4) {
                renderBatch.avgFrameTime = renderBatch.outliers.reduce( (a,b) => a + b, 0) / renderBatch.outliers.length;
                renderBatch.outliers.splice(0, 1000);
            }
        } else {
            renderBatch.outliers.splice(0, 1000);
        }

        if (updateTime)  {
            renderBatch.avgFrameTime = 0.95 * renderBatch.avgFrameTime + 0.05 * frameTime;
        }
    }

    /**
     *  Renders transparent renderbatches in back-to-front order.
     *
     *  @param {RenderCB}      renderObjectsCB - Called for each element of the renderbatches array
     *  @param {UnifiedCamera} camera
     *  @param {RenderBatch[]} renderbatches   - Array of RenderBatches (or THREE.Scene with .boundingBox property)
     */
    #renderTransparentRenderBatches(renderBatches, camera, renderBatchCB) {
        let i;
        let renderBatch;

        // compute camera distance for each renderbatch        
        for (i = 0; i < renderBatches.length; i++) {
            renderBatch = renderBatches[i];
            const bbox = renderBatch.boundingBox || renderBatch.getBoundingBox();
            renderBatch.cameraDistance = bbox.distanceToPoint(camera.position);
        }

        // sort by decreasing camera distance
        function sortOrder(a, b) {
            return b.cameraDistance - a.cameraDistance;
        }
        renderBatches.sort(sortOrder);

        // render each renderBatch and update average frame time
        let t0 = performance.now();
        for (i = 0; i < renderBatches.length; i++) {
            renderBatch = renderBatches[i];
            renderBatch.render ? renderBatch.render(renderBatchCB) : renderBatchCB(renderBatch);

            // measure elapsed time
            const t1 = performance.now();
            const delta = t1 - t0;
            t0 = t1;

            // track average frame time
            this._updateAvgFrameTime(renderBatch, delta);
        }
    }

    /**
     * Indicates if the current traversal is done with the assumption that this frame will not be resumed.
     *  @returns {boolean}
     */
    frameResumePossible() {
        return !this.#frameWillNotBeResumed;
    }

    /**
     * Incrementally render some meshes until we run out of time.
     *  @param {RenderBatchCB} renderBatchCB - Called that does the actual rendering. Called for each RenderBatch to be rendered.
     *  @param {number}   timeRemaining       - Time in milliseconds that can be spend in this function call.
     *  @param {number}   [scalingFactor]     - The scaling factor that was used to compute the time budget.
     *  @returns {number} Remaining time left after the call. Usually <=0.0 if the frame could not be fully finished yet.
     * 
     * @callback RenderScene~renderBatchCB
     * @param {RenderBatch} finalRenderBatch
     */
    renderSome(renderBatchCB, timeRemaining, scalingFactor = 1.0) {

        let t0 = performance.now();
        let t1 = t0;



        // We use two separate deadlines for consolidated and non-consolidated meshes here. 
        // This deadline is mainly intended to be used for non consolidated batches, because 
        // those are much more expensive than consolidated ones and usually are CPU bound. 
        // So for consolidated batches, we want to cancel exactly at the consolidation 
        // timeline to make sure we keep the frame budget. Being CPU bound, these batches 
        // should have a fairly accurate rendertime estimate.

        // For consolidated batches, we don't want to have such a hard limit, because those 
        // are GPU bound and the render time estimates are less accurate and canceling those
        // wasn't the main purpose of this function anyways. So we keep a less strict limit 
        // as an emergency stop for them.
        let consolidatedDeadline = t0 + 2.0 * timeRemaining / scalingFactor;
        let nonConsolidatedDeadline = t0 + timeRemaining / scalingFactor;


        // reserve some time for transparent shapes.
        const timeForTransparent = this.budgetForTransparent * timeRemaining;
        
        let model = null;

        let batchCount = 0;
        let batchTimeSum = 0;
        let rejectOutliers = 0;
        let perFrameConsolidationBudget = globals.PER_FRAME_CONSOLIDATION_TIME_BUDGET;

        const getNextBatch = (/** @type RenderModel */ model) => {
            // We only allow perFrameConsolidationBudget ms of consolidation at each frame, but
            // still don't allow spending more than half of the remaining frame budget in consolidation
            // to prevent triggering an expensive consolidation at the end of the frame.
            const consolidationDeadline = performance.now() + Math.min(perFrameConsolidationBudget, 0.5 * timeRemaining);
            const frameDeadline = globals.USE_OUT_OF_CORE_TILE_MANAGER ? performance.now() + timeRemaining : Infinity;

            let traversalStartTime = performance.now();
            
            let batch = model.nextBatch(frameDeadline, consolidationDeadline);
            let endTime = performance.now();
            let traversalTime = endTime - traversalStartTime;
            
            // We divide the traversal time by the frame time scaling factor, because it will not be
            // affected by GPU processing time and should be instead regarded as wall clock time.
            timeRemaining -= traversalTime / scalingFactor;

            // Update the consolidation budget
            perFrameConsolidationBudget -= traversalTime;

            // Reset start time, to not include consolidation time in render batch time estimation
            t0 = endTime; 

            return batch;   
        };

        // repeat until time budget is consumed...
        while (true) {
            // Find the best candidate render batch to render now -- in case there are multiple models.
            // TODO In case a huge number of models is loaded, we may have to rethink the linear loop below and use some priority heap or somesuch.
            let candidateIdx = 0;
            /** @type {RenderBatch|THREE.Scene|null} */ let finalRenderBatch = null;

            if (this.interruptedRenderBatch) {
                // If a render batch in the previous frame was interrupted, we continue with this one.
                finalRenderBatch = this.interruptedRenderBatch;
                this.interruptedRenderBatch = undefined;
            } else {

                for (let iq = 0; iq < this.#candidateRenderBatches.length; iq++) {

                    // candidate is the next RenderBatch to be processed from this._models[q] 
                    let candidateRenderBatch = this.#candidateRenderBatches[iq];
                    model = this.#models[iq];

                    if (!candidateRenderBatch) {
                        this.#candidateRenderBatches[iq] = candidateRenderBatch = getNextBatch(model);
                    }

                    if (timeRemaining <= 0) {
                        return timeRemaining;
                    }

                    // If the camera is in motion and the time for opaque renderbatches is over, continue with transparent shapes.
                    const skipOpaque = this.#frameWillNotBeResumed && timeRemaining < timeForTransparent;
                    if (skipOpaque) {
                        // check if the next candidate is still an opaque one. Note that the .sortObjects
                        // flag indicates whether a RenderBatch contains transparent objects.
                        const isOpaque = candidateRenderBatch && !candidateRenderBatch.sortObjects;
                        if (isOpaque) {
                            // skip current candidate and use the first available transparent renderbatch instead
                            model.skipOpaqueShapes();
                            this.#candidateRenderBatches[iq] = candidateRenderBatch = getNextBatch(model);
                        }
                    }

                    // No more batches to render from this model
                    if (candidateRenderBatch === null) {
                        continue;
                    }

                    // If all previous candidates were null, candidateRenderBatch is obviously the best one so far.
                    if (!finalRenderBatch) {
                        candidateIdx = iq;
                        finalRenderBatch = candidateRenderBatch;
                    }

                    // If final renderbatch and candidate have the same transparency, choose current candidate only if its renderImportance is higher.
                    // The renderImportance of RenderBatches is set by model iterators.
                    const chooseByRenderImportance = candidateRenderBatch.sortObjects == finalRenderBatch.sortObjects && candidateRenderBatch.renderImportance > finalRenderBatch.renderImportance;

                    // if the renderbatch is transparent and the candidate is opaque, choose the candidate
                    const mustReplaceTransparentRenderBatch = !candidateRenderBatch.sortObjects && finalRenderBatch.sortObjects;
                    if (chooseByRenderImportance || mustReplaceTransparentRenderBatch) {
                        candidateIdx = iq;
                        finalRenderBatch = candidateRenderBatch;
                    }
                }
            }


            // Render the batch we chose above and determine whether to continue the loop
            if (finalRenderBatch) {

                // Has the renderbatch been interrupted in the previous frame? (Note: can only be called if this function is provided
                // by the renderbatch. Renderbatches can also just be THREE.Scenes that do not have this interface)
                const interruptedInPreviousFrame = finalRenderBatch.hasBeenInterrupted && finalRenderBatch.hasBeenInterrupted();
                if (!interruptedInPreviousFrame) {
                    //Fetch a new render batch from the model that we took the current batch from.
                    this.#candidateRenderBatches[candidateIdx] = getNextBatch(this.#models[candidateIdx]);
                }

                // If we are in a non-resumable frame, we try to get the most important ones of opaque and transparent renderbatches.
                // Therefore, the traversal of transparent renderbatches will also be ordered by decreasing priority just like for opaque ones. 
                // For correct rendering, however, we cannot render them directly here. Instead, we must collect them first and render them back-to-front at the end of the function.
                if (finalRenderBatch.sortObjects && this.#frameWillNotBeResumed) {
                    // defer to the end of the frame
                    this.#transparentRenderBatches.push(finalRenderBatch);

                    // reserve frame time based on past rendering times. Just for the very first use, we use an initial guess value as fallback.
                    // get time that we spent for rendering of the last batch
                    timeRemaining -= finalRenderBatch.avgFrameTime === undefined ? 0.05 : finalRenderBatch.avgFrameTime;
                } else {

                    if (globals.USE_OUT_OF_CORE_TILE_MANAGER && finalRenderBatch.setDeadline) {
                        if (finalRenderBatch instanceof ConsolidatedRenderBatch) {
                            finalRenderBatch.setDeadline(consolidatedDeadline);
                        } else {
                            finalRenderBatch.setDeadline(nonConsolidatedDeadline);
                        }
                    }
                    // do the actual rendering
                    finalRenderBatch.render ? finalRenderBatch.render(renderBatchCB) : renderBatchCB(finalRenderBatch);
                    
                    if (finalRenderBatch.setDeadline) {
                        // explicitly disable the deadline
                        // We only want to use the deadline in the main render pass
                        finalRenderBatch.setDeadline(undefined);
                    }
                    if (finalRenderBatch.hasBeenInterrupted && finalRenderBatch.hasBeenInterrupted()) {
                        this.interruptedRenderBatch = finalRenderBatch;
                        timeRemaining = -1;
                    } else {
                        if (Object.prototype.hasOwnProperty.call(finalRenderBatch, "drawEnd")) {
                            finalRenderBatch.drawEnd = finalRenderBatch.lastItem;
                        }

                        // get time that we spent for rendering of the last batch
                        t1 = performance.now();
                        let delta = t1 - t0; // in milliseconds
                        t0 = t1;

                        // We only update the render batch time if it was not interrupted in the previous frame, 
                        // as the time measured in this frame would be too short.
                        if (!interruptedInPreviousFrame) {

                            // We exclude large outlier from the render batch average computation. The reason for this is
                            // that the renderer sometimes blocks for a long time in a single render batch, if its queue is
                            // full. If we add this time to that batch, we would completely miss estimate the price for this
                            // individual batch. Instead, we only keep its average time. This way, the overall frame time
                            // estimation might be too small, but it will not have strong outliers. We instead rely on the
                            // frame time budget computation to compensate for this underestimation.
                            let batchTimeAverage;
                            if (batchCount > 5) {
                                batchTimeAverage = batchTimeSum / batchCount;
                                if (delta > 5 * batchTimeAverage &&  rejectOutliers < 3) {
                                    delta = finalRenderBatch.avgFrameTime ?? batchTimeAverage;
                                    rejectOutliers++;
                                }
                            }
                            batchTimeSum += delta;
                            batchCount++;

                            // If we do not yet have a frame time average for this batch,
                            // we won't use the actual time for the estimation, since
                            // this time will include the upload time of the geometry
                            // the first time it is rendered. Instead, we use the average
                            // per render batch time (if available).
                            if (finalRenderBatch.avgFrameTime === undefined) {
                                if (globals.USE_OUT_OF_CORE_TILE_MANAGER) {
                                    // If the out of core tile manager is used, we no longer
                                    // upload in the render call, so in that case we can use the actual time
                                    // if no batch time average is available.
                                    delta = batchTimeAverage ?? delta;
                                } else {
                                    delta = batchTimeAverage ?? 0.5;
                                }
                            }

                            // For each sub-scene, keep a running average of how long it took to render over the last few frames.
                            this._updateAvgFrameTime(finalRenderBatch, delta);


                            // update remaining time
                            // Note that we don't do accurate timing here, but compute with average values instead.
                            // In this way, the number of rendered batches is more consistent across different frames
                            timeRemaining -= finalRenderBatch.avgFrameTime;
                        } else {
                            timeRemaining -= delta;
                        }
                    }
                }

                // Check if we should exit the loop...
                if (timeRemaining <= 0) {
                    break;
                }
            } else {
                // No more batches => Frame rendering finished, if all models are loaded
                this.#done = true;
                break;
            }
        }

        // Render some deferred transparent shapes (this._transparentShapes). Note that this array will
        // usually be empty if this._frameWillNotBeResumed is false
        if (this.#transparentRenderBatches.length > 0) {
            this.#renderTransparentRenderBatches(this.#transparentRenderBatches, this.#camera, renderBatchCB);

            // all scenes processed. Clear array.
            this.#transparentRenderBatches.length = 0;
        }

        return timeRemaining;
    }

    // TODO This method needs to be revisited as on demand loading is removed from the code base  
    /** Resets the renderBatch traversal 
     *   @param  {UnifiedCamera} camera
     *   @param  {number}        drawMode     - E.g., RENDER_NORMAL. See RenderFlags.js
     *   @param: {number}        [resetType]  - Must be one of RESET_NORMAL, RESET_REDRAW or RESET_RELOAD.
     *                                          Only used when on demand loading is enabled. RESET_RELOAD will reload and redraw geometry.
     *                                          RESET_REDRAW will redraw geometry. RESET_NORMAL will only redraw geometry that hasn't already been drawn. 
     *                                          If undefined RESET_NORMAL is used.
     */
    reset(camera, drawMode, resetType, cutPlanes, cutplanesHideInterior = false) {
        // Reset the interrupted renderbatch, if there is one
        if (this.interruptedRenderBatch) {
            this.interruptedRenderBatch.resetInterrupted();
            this.interruptedRenderBatch = undefined;
        }
        
        this.#done = false;

        // Calculate the viewing frustum
        // TODO same math is done in the renderer also. We could unify
        this.#frustum.reset(camera, cutPlanes, cutplanesHideInterior);
        this.#frustum.areaCullThreshold = globals.PIXEL_CULLING_THRESHOLD;

        if (!this.#models.length) {
            return;
        }

        // If the camera is in-motion, we assume the frame not to be resumed.
        // This allows us to render transparent shapes earlier. This special treatment is only used/needed for the main renderBatch pass.
        this.#frameWillNotBeResumed = this.enableNonResumableFrames && resetType == ResetFlags.RESET_RELOAD && drawMode === RenderFlags.RENDER_NORMAL;

        this.#camera = camera;

        const consolidationDeadline = performance.now() + globals.PER_FRAME_CONSOLIDATION_TIME_BUDGET;
        const frameDeadline = globals.USE_OUT_OF_CORE_TILE_MANAGER ? performance.now() + globals.PER_FRAME_CONSOLIDATION_TIME_BUDGET : Infinity;

        // Begin the frustum based renderBatch iteration process per model.
        // A "Model" is all the objects to display. There's typically one model in a scene, so length is 1.
        for (let i = 0; i < this.#models.length; i++) {
            // decide what iterator to use, usually the BVH iterator
            this.#models[i].resetIterator(camera, this.#frustum, drawMode, resetType);

            // get the first RenderBatch (some set of fragments) to render.
            this.#candidateRenderBatches[i] = this.#models[i].nextBatch(frameDeadline, consolidationDeadline);

            this.#previousRenderBatches[i] = null;
        }
    }

    isDone() {
        return this.#done || this.isEmpty();
    }

    ///////////////////////////////////////////////////////////////////////
    // Visibility and highlighting methods: see RenderModel.js for details.

    setAllVisibility(value) {
        for (let i=0; i<this.#models.length; i++)
            this.#models[i].setAllVisibility(value);
    }

    hideLines(hide) {
        for (let i=0; i<this.#models.length; i++)
            this.#models[i].hideLines(hide);
    }

    hidePoints(hide) {
        for (let i=0; i<this.#models.length; i++)
            this.#models[i].hidePoints(hide);
    }

    hasHighlighted() {
        for (let i=0; i<this.#models.length; i++)
            if (this.#models[i].hasHighlighted())
                return true;

        return false;
    }

    areAllVisible() {
        for (let i=0; i<this.#models.length; i++)
            if (!this.#models[i].areAllVisible())
                return false;

        return true;
    }

    ///////////////////////////////////////////////////////////////////////

    areAll2D() {
        for (let i=0; i<this.#models.length; i++)
            if (!this.#models[i].is2d())
                return false;

        return true;
    }

    areAll3D() {
        for (let i=0; i<this.#models.length; i++)
            if (!this.#models[i].is3d())
                return false;

        return true;
    }

    /** Trigger bbox recomputation. See RenderModel.js for details. */
    invalidateVisibleBounds() {
        for (let i=0; i<this.#models.length; i++)
            this.#models[i].invalidateBBoxes();
    }
    
    /**
    * @param {bool}            includeGhosted
    * @param {function(model)} [modeFilter]
    * @param {bool}            excludeShadow - Remove shadow geometry (if exists) from model bounds.
    * @returns {THREE.Box3} 
    *
    * NOTE: The returned box object is always the same, i.e. later calls
    *       affect previously returned values. E.g., for
    *        let box1 = getVisibleBounds(true);
    *        let box2 = getVisibleBounds(false);
    *       the second call would also change box1.
    */
    getVisibleBounds(includeGhosted, bboxFilter, excludeShadow) {
        tmpBox.makeEmpty();
        for (let i=0; i<this.#models.length; i++) {
            const model = this.#models[i];
            const modelBox = model.getVisibleBounds(includeGhosted, excludeShadow); 

            // Consider bboxFilter
            let skipModel = bboxFilter && !bboxFilter(modelBox);
            if (skipModel) {
                continue;
            }

            tmpBox.union(modelBox);
        }
        return tmpBox;
    }

    /**
     * @param {THREE.Vector3} position            - Ray origin.
     * @param {THREE.Vector3} direction           - Ray direction.
     * @param {bool}          [ignoreTransparent] - Shoot trough transparent objects.
     * @param {number[]|number[][]} [dbIds]       - Optional filter of dbIds to be considered for testing. see RenderModel.rayIntersect().
     *                                              If modelIds is set, dbIds[i] must provide a separate dbId array for modelIds[i].
     * @param {number[]}      [modelIds]          - Optional list of modelIds to be considered for rayIntersection. (default is to consider all)
     * @param {Array}         [intersections]     - Optional return array with all found intersections.
     * @param {function}      [getDbIdAtPointFor2D] - Optional callback. For 2D models, to return the dbId and modelId in an array.
     * @param {Object}        [options]             - Rayintersection options (see RenderModel.rayIntersect)
     * 
     * @returns {Object|null} Intersection result object (see RenderModel.rayIntersect)
     */ 
    // Add "meshes" parameter, after we get meshes of the object using id buffer,
    // then we just need to ray intersect this object instead of all objects of the model.
    rayIntersect(position, direction, ignoreTransparent, dbIds, modelIds, intersections, getDbIdAtPointFor2D, options) {
        // Init raycaster
        this.#raycaster.set(position, direction);

        // For multiple RenderModels, perform raytest on each of them and find the closest one.
        if (this.#models.length > 1) {
            // Collect raytest result objects from each 3D model
            const modelHits = [];

            if (modelIds) {
                for (let i = 0; i < modelIds.length; i++) {
                    const model = this.findModel(modelIds[i]);
                    if (model) {
                        const modelDbIds = dbIds && dbIds[i];
                        const res = model.rayIntersect(this.#raycaster, ignoreTransparent, modelDbIds, intersections, getDbIdAtPointFor2D, options);
                        if (res) {
                            modelHits.push(res);
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.#models.length; i++) {
                    // Perform raytest on model i
                    const res = this.#models[i].rayIntersect(this.#raycaster, ignoreTransparent, dbIds, intersections, getDbIdAtPointFor2D, options);

                    if (res) {
                        modelHits.push(res);
                    }
                }
            }

            if (!modelHits.length)
                return null;

            // Return closest hit
            modelHits.sort(function(a,b) {return a.distance - b.distance;});
            return modelHits[0];
        } else {
            // If we don't have any RenderModel, just return null.
            if (!this.#models.length)
                return null;

            // Apply modelIds filter
            const model = this.#models[0];
            if (modelIds && modelIds.indexOf(model.id) === -1) {
                return null;
            }

            // If we only have a single RenderModel, just call rayIntersect() on it.
            return model.rayIntersect(this.#raycaster, ignoreTransparent, dbIds, intersections, getDbIdAtPointFor2D, options);
        }
    }

    /**
     *  Progress of current frame rendering. 
     *  @returns {number} Value in [0,1], where 1 means finished.
     */
    getRenderProgress() {
        return this.#models[0].getRenderProgress();
    }

    /** @returns {RenderModel[]} */
    getModels() {
        return this.#models;
    }

    /** @returns {RenderModel[]} */
    getHiddenModels() {
        return this.#hiddenModels;
    }

    /** @returns {RenderModel[]} */
    getAllModels() {
        return this.#models.concat(this.#hiddenModels);
    }

    // ----------------------------
    // Warning: The methods in the section below assume that there is exactly one RenderModel.
    //          They will ignore any additional models and cause an exception if the model list is empty.
    // 

    // Direct access to FragmentList, GeometryList, and total number of RenderBatches.
    //
    // Note: 
    //  - The methods do only care for model 0 and ignore any additional ones.
    //  - Will cause an error when called if the RenderModel array is empty.
    getFragmentList() {
        return this.#models[0].getFragmentList();
    }

    getGeometryList() {
        return this.#models[0].getGeometryList();
    }

    getSceneCount() {
        return this.#models[0].getSceneCount();
    }

    //Used by ground shadow update, ground reflection update, and screenshots
    getGeomScenes() {
        let scenes = [];
        for (let i = 0; i < this.#models.length; i++) {
            // Collect all scenes from next model
            const modelScenes = this.#models[i].getGeomScenes();
            for (let j = 0; j < modelScenes.length; j++) {
                // Some scenes may not exist. E.g., if it corresponds to an empty BVH node.
                const scene = modelScenes[j];
                if (scene) {
                    scenes.push(scene);
                }
            }
        }
        return scenes;
    }

    // Used by ground shadow update, ground reflection update,
    getGeomScenesPerModel() {
        return this.#models.reduce((acc, m) => { 
            acc.push(m.getGeomScenes());
            return acc;
        }, []);
    }

    // ---------------- End of section of functions without support for multiple RenderModels

    /** Sets animation transforms for all fragments to create an "exploded view": Each fragment is displaced  
         * away from the model bbox center, so that you can distuinguish separate components. 
         *
         * If the model data provides a model hierarchy (given via model.getData().instanceTree), it is also considered for the displacement.
         * In this case, we recursively shift each object away from the center of its parent node's bbox. 
         *
         * @param {number} scale - In [0,1]. 0 means no displacement (= reset animation transforms). 
         *                                   1 means maximum displacement, where the shift distance of an object varies 
         *                                   depending on distance to model center and hierarchy level.
         * @param {Object} options - Additional setting for STRATEGY_HIERARCHY.
         * @param {Number} options.magnitude - Controls the spread of explode.
         * @param {Number} options.depthDampening - Controls the reduction of the explode effect with
         *                                          depth of the object in the hierarchy.  
         */
    explode(scale, options = {}) {
        if (!this.#models.length) {
            return;
        }

        for (let q=0; q<this.#models.length; q++) {
            const model = this.#models[q];
            ModelExploder.explode(model, scale, options);
        }

        this.invalidateVisibleBounds();
    }

    /** 
     *  @params  {number} timeStamp
     *  @returns {bool}   true if any of the models needs a redraw
     */
    update(timeStamp) {
        // call update for all RenderModels and track
        // if any of these needs a redraw
        let needsRedraw = false;
        for (let q=0; q<this.#models.length; q++) {
            const model = this.#models[q];
            needsRedraw = needsRedraw || model.update(timeStamp);
        }
        return needsRedraw;
    }

    /*
    *  Move model from visible models to hidden models
    *   @param {number} modelId - id of a currently visible model
    *   @returns {bool} true on success
    */
    hideModel(modelId) {
        // find model in the list of visible ones
        for (let i=0; i<this.#models.length; i++) {
            const model = this.#models[i];
            if (model && model.id === modelId) {
                // move model from visible to hidden models
                this.removeModel(model);
                this.#hiddenModels.push(model);
                return true;
            }
        }
        // modelID does not refer to any visible model
        return false;
    }

    /*
    * Move previously hidden model to the array of rendered models.
    *  @param {number} modelId - id of a RenderModel in hiddenModels array
    *  @returns {bool} true on success
    */
    showModel(modelId) {
        // find model in list of hidden models
        for (let i=0; i<this.#hiddenModels.length; ++i) {
            const model = this.#hiddenModels[i];
            if (model && model.id === modelId) {
                // mode model from hidden to visible models
                this.addModel(model);
                this.#hiddenModels.splice(i, 1);
                return true;
            }
        }
        // modelId does not refer to a hidden model
        return false;
    }
}
