import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {UniformBuffer} from "../UniformBuffer";
import blendUniforms from "./blend_uniforms.wgsl";
import unProjectUniforms from "./unproject_uniforms.wgsl";

export function getBlendSettingsDeclaration(bindGroup) {
	return $wgsl(blendUniforms, (bindGroup + 0 || 0));
}

export class BlendSettings extends UniformBuffer {

	constructor() {
		super(null, 20, true, false, 'blend-settings');
	}

	init(device) {
		this.setDevice(device);
	}

	setAOEnabled(on) {
		this.setInt(13, on ? 1 : 0);
	}

	setHighlightObjectId(dbId) {
		this.setInt(8, dbId);
	}

	setHighlightModelId(modelId) {
		this.setInt(9, modelId);
	}

	setHighlightIntensity(value) {
		this.setFloat(12, value);
	}

	getHighlightIntensity() {
		return this.getFloat(12);
	}

	setRolloverHighlightColor(color) {
		this.setColor(0, color, 1.0); //TODO: this changes so rarely that it would be better off as constant
	}

	setEdgeHighlightObjectId(dbId, modelId) {
		this.setInt(15, dbId);
		this.setInt(16, modelId);
	}

	setGlowFlag(flag) {
		this.setInt(10, flag);
	}

	setGlowOptions(color, compFunc) {
		//TODO: glow color uniform
		this.setInt(11, compFunc);
	}

	setAOBias(blendBias) {
		this.setFloat(14, blendBias);
	}

	setSelectionColor(color) {
		this.setColor(4, color, 1.0);
	}

	setUseOverlay(value) {
		this.setInt(17, value ? 1 : 0);
	}

	setZRange(min, max) {
		this.setFloat2(18, min, max);
    }
}

export function getUnProjectSettingsDeclaration(bindGroup) {
	return $wgsl(unProjectUniforms, (bindGroup + 0 || 0));
}

export class UnProjectSettings extends UniformBuffer {

	constructor() {
		super(null, 36, true, false);
	}

	init(device) {
		this.setDevice(device);
	}

	setCamera(camera) {
        const projectionMatrixInverse = camera.projectionMatrix.clone().invert();

        this.setMatrix4x4( 0, camera.matrixWorld.elements);
        this.setMatrix4x4(16, projectionMatrixInverse.elements);

        this.setFloat2(32, camera.near, camera.far);
		this.setFloat(34, camera.isPerspective);
	}
}
