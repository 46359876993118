import * as THREE from "three";
import { USE_HLOD, USE_OUT_OF_CORE_TILE_MANAGER } from "../../globals";
import { getVertexCount } from "../VertexEnumerator";
import { createBufferGeometry } from "../BufferGeometry";
import { getByteSize } from "../BufferGeometryUtils";
import { runMergeSingleThreaded, ParallelGeomMerge, createRangeArray } from "./ParallelGeomMerge";
import { writeIdToBuffer } from "./GeomMergeTask";
import { logger } from "../../../logger/Logger";
import { MATERIAL_VARIANT } from "../../render/MaterialManager";
import { MeshFlags } from "../MeshFlags";
import { RenderFlags } from "../RenderFlags";
import * as EventTypes from "../../../application/EventTypes";
import { applyInstancingToRange } from "./FragmentListConsolidation";
import { OtgLoader } from "../../../file-loaders/main/OtgLoader";

import { STREAMING_DRAW_ONCE_DURING_UPLOAD } from "../../render/constants";
import { OutOfCoreTileManager } from '../out-of-core-tile-manager/OutOfCoreTileManager';
import { ModelIteratorBVH } from '../ModelIteratorBVH';
export const NO_MESH_FOR_FRAGMENT = -1;
export const MESH_STILL_PENDING = -2;
export const MIN_VALUE_FOR_PENDING_RANGE = MESH_STILL_PENDING - 1;


/** @import { RenderModel } from "../RenderModel" */

/**
 * Can we use the earlier consolidation?
 * This is only possible if the OutOfCoreTileManager is enabled and we are using the OtgLoader.
 * 
 * The traditional consolidation algorithm was based on the assumption that the memory is statically partitioned
 * into a part that is used for the consolidations and the rest is used to upload the geometries. So the algorithm
 * had to decide which geometries can be consolidated to fit into this global memory limit (across all geometries).
 * This meant, that the consolidation could only be performed once all geometries were loaded, since we needed to know
 * the memory requirements of all geometries to decide which ones would still fit into the global consolidation memory.
 * 
 * With the OutOfCoreTileManager, we now manage the memory dynamically and no longer decide globally which geometries
 * can be consolidated. Instead, we make the decision for each of the BVH nodes separately. This allows us to change the
 * criterion from a global criterion (i.e. half of the available memory for all geometries), to a local criterion (the 
 * number of triangles in each of the geometries).
 * 
 * We now are able to do the computation of the Consolidation Map (the actual consolidation is deferred to a task in the
 * OutOfCoreTileManager) once we know for all fragments the geometryIDs and materials (those are stored in the fragment list)
 * as well as the bounding boxes, transparency flags and polycount (from the fragments extra data).
 * 
 * @param {RenderModel} model - The model 
 * @returns 
 */
export function useEarlyConsolidation(model) {
    return USE_OUT_OF_CORE_TILE_MANAGER && model.loader instanceof OtgLoader && !model.loader.svf.loadDone;
}

// Maximum vertex count that we allow for a consolidated mesh. For simplicity, we keep it within 16 bit scope, so that
// we can always use Uint16 indices. Allowing too large containers may backfire in several ways, e.g.,
// it would reduce granularity for progressive rendering and frustum culling too much.
var MaxVertexCountPerMesh = 0xFFFF;

var PRIMITIVE_TYPE = {
    UNKNOWN:    0,
    TRIANGLES:   1,
    LINES:       2,
    WIDE_LINES:  3,
    POINTS:      4
};

// We use a global shared array buffer which is used when uploading consolidated geometries to the GPU.
// Once the data has been uploaded, the buffer can be reused for the next consolidation. This way, we
// avoid allocating a new buffer for each consolidation, which would lead to a lot of garbage collection.
const globalArrayBufferSize = 20 * 1024 * 1024;
let globalArrayBuffer = undefined;
let globalArrayBufferOffset = 0;

/**
 * Allocates a typed array in the shared array buffer. If the buffer is full, a new typed array is allocated.
 * 
 * @param {Float32ArrayConstructor|Float64ArrayConstructor|Uint16ArrayConstructor|Int16ArrayConstructor|Int32ArrayConstructor} constructor - The constructor of the typed array to allocate.
 * @param {number} size - The size of the typed array to allocate.
 * @returns {Float32Array|Float64Array|Uint16Array|Int16Array|Int32Array} The allocated typed array.
 */
function allocateInGlobalArrayBuffer(constructor, size) {
    if (!USE_OUT_OF_CORE_TILE_MANAGER ||
        (globalArrayBufferOffset + size * constructor.BYTES_PER_ELEMENT > globalArrayBufferSize)) {
        return new constructor(size);
    } else {
        let offset = size  + ((size %4) !== 0 ? 4 - (size %4) : 0); // Align to 4 bytes

        if (globalArrayBuffer === undefined) {
            globalArrayBuffer = new ArrayBuffer(globalArrayBufferSize);
        }

        const result = new constructor(globalArrayBuffer, globalArrayBufferOffset, size);
        globalArrayBufferOffset += offset * constructor.BYTES_PER_ELEMENT;
        return result;
    }
}

export function resetGlobalArrayBuffer() {
    globalArrayBufferOffset = 0;
}

function getPrimitiveType(geom) {
    if (geom.isLines)     return PRIMITIVE_TYPE.LINES;
    if (geom.isPoints)    return PRIMITIVE_TYPE.POINTS;
    if (geom.isWideLines) return PRIMITIVE_TYPE.WIDE_LINES;
    return PRIMITIVE_TYPE.TRIANGLES;
}

function setPrimitiveType(geom, type) {

    // clear any previous flags
    if (geom.isLines     === true) geom.isLines     = undefined;
    if (geom.isWideLines === true) geom.isWideLines = undefined;
    if (geom.isPoints    === true) geom.isPoints    = undefined;

    switch(type) {
        case PRIMITIVE_TYPE.LINES:      geom.isLines     = true; break;
        case PRIMITIVE_TYPE.WIDE_LINES: geom.isWideLines = true; break;
        case PRIMITIVE_TYPE.POINTS:     geom.isPoints    = true; break;
    }
}

var MESH_HIGHLIGHTED = MeshFlags.MESH_HIGHLIGHTED;
var flagMask  = MeshFlags.MESH_VISIBLE | MeshFlags.MESH_HIDE | MESH_HIGHLIGHTED;
var flagVisible = MeshFlags.MESH_VISIBLE;
var flagHiddenMask = MeshFlags.MESH_VISIBLE | MeshFlags.MESH_HIDE;
var flagHiddenVisible = 0;
var flagHighlightMask = MeshFlags.MESH_HIGHLIGHTED | MeshFlags.MESH_HIDE;
var flagHighlightVisible = MeshFlags.MESH_HIGHLIGHTED;
var RENDER_HIDDEN = RenderFlags.RENDER_HIDDEN;
var RENDER_HIGHLIGHTED = RenderFlags. RENDER_HIGHLIGHTED;

// Should the object with flags get drawn in this render pass.
export function isVisible(flags, drawMode) {
    switch (drawMode) {
        case RENDER_HIDDEN:
            return (flags & flagHiddenMask) === flagHiddenVisible; //Ghosted not visible and not hidden
        case RENDER_HIGHLIGHTED:
            return (flags & flagHighlightMask) === flagHighlightVisible; //highlighted (bit 1 on)
    }
    return ((flags & flagMask) == flagVisible); //visible but not highlighted, and not a hidden line (bit 0 on, bit 1 off, bit 2 off)
}

const _tmpMatrix = new THREE.Matrix4();

/**
  *  Helper class to collect shapes with identical materials and merge them into a single large shape.
  *
  *  @constructor
  *    @param {number} materialId - Material must be the same for all added geometries.
  *    @param {number} bvhNodeId - BVH node id of the bucket
  */
function MergeBucket(materialId, bvhNodeId) {
    this.geoms       = [];
    this.matrices    = [];
    this.vertexCount = 0;
    this.materialId  = materialId;
    this.fragIds     = [];
    this.worldBox    = new THREE.Box3();
    this.cost        = 0;
    this.bvhNodeId   = bvhNodeId;
}

MergeBucket.prototype = {
    constructor: MergeBucket,

    /**
     * @param {THREE.BufferGeometry} geom
     * @param {THREE.Box3}           worldBox
     * @param {Number}               fragId
     * @returns {Number}             costs - memory cost increase caused by the new geometry
     */
    addGeom: function(geom, worldBox, fragId) {

        this.fragIds.push(fragId);

        this.worldBox.union(worldBox);

        if (geom === null) {
            return 0;
        }

        this.geoms.push(geom);
        this.vertexCount += getVertexCount(geom);

        // Track memory costs. As long as the bucket has only a single shape,
        // we have no costs at all.
        var numGeoms = this.geoms.length;
        if (numGeoms==1) {
            return 0;
        }

        // Fragment geometries are usually BufferGeometry, which provide a byteSize for the
        // interleaved buffer. Anything else is currently unexpected and needs code change.
        if (geom.byteSize === undefined) {
            logger.warn("Error in consolidation: Geometry must contain byteSize.");
        }

        // For any bucket with >=2 geoms, all geometries must be considered for the costs.
        let costDelta = geom.byteSize + (numGeoms==2 ? this.geoms[0].byteSize : 0);
        this.cost += costDelta;

        return costDelta;
    }
};

/**
 *  Set vertex attributes and vbstride of dstGeom to the same vertex format as srcGeom.
 *  Note that this can only be used for interleaved vertex buffers.
 *   @param {BufferGeometry} srcGeom
 *   @param {BufferGeometry} dstGeom
 */
export function copyVertexFormat(srcGeom, dstGeom) {

    if (!srcGeom.vb) {
        logger.warn("copyVertexFormat() supports only interleaved buffers");
    }

    dstGeom.vbstride = srcGeom.vbstride;

    let unusedAttributes = new Set(Object.keys(dstGeom.attributes));

    for (var attrib in srcGeom.attributes) {
        // VertexAttribute objects of WGS BufferGeometry do not contain actual vertex data.
        // Therefore, identical BufferAttribute objects are shared among different
        // BufferGeometries. (see findBufferAttribute in BufferGeometry.js)
        dstGeom.attributes[attrib] = srcGeom.attributes[attrib];
        unusedAttributes.delete(attrib);
    }

    unusedAttributes.delete('id'); // keep id attribute

    // remove unused attributes
    for (let attrib of unusedAttributes) {
        delete dstGeom.attributes[attrib];
    }

    // copy attribute keys
    dstGeom.attributesKeys = srcGeom.attributesKeys.slice(0); // shallow copy
}

/**
 *  Set primitive type and related params (lineWidth/pointSize) of dstGeom to the same values as srcGeom.
 *   @param {BufferGeometry} srcGeom
 *   @param {BufferGeometry} dstGeom
 */
export function copyPrimitiveProps(srcGeom, dstGeom) {

    var primType = getPrimitiveType(srcGeom);
    setPrimitiveType(dstGeom, primType);

    // pointSize/lineWidth
    dstGeom.lineWidth = srcGeom.lineWidth;
    dstGeom.pointSize = srcGeom.pointSize;
}

/**
 * Creates target BufferGeometry used to merge several src BufferGeometries into one. (see mergeGeometries)
 *
 * Returns a new BufferGeometry for which...
 *  - vb/ib are large enough to fit in all src geometry vertices/indices (allocated, but not filled yet)
 *  - the vertex-format of the interleaved vb is the same as for the input geometries
 *  - primitive type is the same as for (including pointSize/lineWidth)
 *  - it has an additional attribute for per-vertex ids
 *
 *  @param {BufferGeometry[]} geoms - source geometry buffers.
 *  @returns {BufferGeometry}
 */
function createMergeGeom(geoms) {

    // floats per vertex
    let stride = geoms[0].vbstride; // same for src and dst, because we add per-vertex ids as separate attribute

    // compute summed vertex and index count (and summed box if needed)
    var indexCount  = 0;
    var vertexCount = 0;
    let indexlines;
    let indexlinesCount = 0;
    for (let i = 0; i < geoms.length; i++) {
        const geom = geoms[i];
        vertexCount += getVertexCount(geom);
        // Make sure that indexCount is a multiple of 3 for triangle meshes or 2 for lines. 
        // This should always be the case, but if there would be any case, where this condition
        // is not met, this would break the consolidated buffer, because
        // all triangles/lines would be shifted by one or two vertices.
        let numIndices = geom.ib.length;
        let numIndicesPerPrimitive = geom.isLines ? 2 : 3;
        numIndices = numIndices - (numIndices % numIndicesPerPrimitive);
        indexCount += numIndices;
        indexlines  = geom.iblines;
        if (indexlines) {
            // Make sure that indexCount is a multiple of 2. This should always
            // be the case, but we encountered meshes where this condition was violated.
            // In cases, where this condition is not met, this would break the consolidated
            // buffer, because all later lines would be shifted by one vertex.
            let numIndicesLines = indexlines.length;
            numIndicesLines = numIndicesLines - (numIndicesLines % 2);
            indexlinesCount += numIndicesLines;
        }
    }

    var mergedGeom = createBufferGeometry();
    mergedGeom.byteSize = 0;

    // allocate new geometry with vertex and index buffer
    mergedGeom.vb = allocateInGlobalArrayBuffer(Float32Array, vertexCount * stride);
    mergedGeom.ib = allocateInGlobalArrayBuffer(Uint16Array, indexCount);

    if (indexlinesCount > 0) {
        indexlines = allocateInGlobalArrayBuffer(Uint16Array, indexlinesCount);
        mergedGeom.byteSize += indexlines.byteLength;
        mergedGeom.iblines = indexlines;
    }

    // make sure that byteSize is set just like for input geometry. This is required for later memory tracking.
    mergedGeom.byteSize += mergedGeom.vb.byteLength + mergedGeom.ib.byteLength;

    // copy primitive type + params (pointSize/lineWidth)
    copyPrimitiveProps(geoms[0], mergedGeom);

    // copy common properties from geom[0]
    copyVertexFormat(geoms[0], mergedGeom);

    // In the shader, an id is a vec3 with components in [0,1].
    // In memory, each component has 8 Bits of the dbId.
    var IDItemSize   = 3; // IDs are vec3 in the shader

    // create/add additional per-vertex id attribute
    //
    // Note: The actual array buffer is not created yet, but assigned later.
    //       (see mergeGeometries)
    var idAttrib = mergedGeom.attributes.id || new THREE.BufferAttribute(new Float32Array(), IDItemSize);
    idAttrib.normalized    = true; // shader needs normalized components
    idAttrib.bytesPerItem = 1;
    mergedGeom.setAttribute('id', idAttrib);

    // set primitive type
    var firstGeom = geoms[0];
    var primType = getPrimitiveType(firstGeom);
    setPrimitiveType(mergedGeom, primType);

    // copy size/width for points/wide-lines
    if (firstGeom.isPoints)    mergedGeom.pointSize = firstGeom.pointSize;
    if (firstGeom.isWideLines) mergedGeom.lineWidth = firstGeom.lineWidth;

    return mergedGeom;
}

/**
 * Copies the vertex/index buffers of geoms into mergedGeom. Indices are modified by an offset
 * so that they point to the correct position in mergedGeom's vertex buffer.
 *  @param {BufferGeometry[]} geoms
 *  @param {BufferGeometry}   mergedGeom
 */
function copyVertexAndIndexBuffers(geoms, mergedGeom) {

    // write-offset in mergedGeom.vb (in floats)
    var dstOffset = 0;

    // create combined vertex and index buffer - including transforms
    var vertexOffset = 0;
    var indexOffset  = 0;
    var indexOffsetLines = 0;

    for (var i = 0; i < geoms.length; i++) {
        var geom        = geoms[i];
        var vertexCount = getVertexCount(geom);

        const vb = geom.vb;
        const ib = geom.ib;
        let indexCount = ib.length;
        const mergedVb = mergedGeom.vb;
        const mergedIb = mergedGeom.ib;
        const iblines = geom.iblines;
        let indexlinesCount = iblines?.length ?? 0;
        const mergedIblines = mergedGeom.iblines;

        // copy indices (+ offset)

        // Make sure that indexCount is a multiple of 3 for triangle meshes or 2 for lines. 
        // This should always be the case, but if there would be any case, where this condition
        // is not met, this would break the consolidated buffer, because
        // all triangles would be shifted by one or two vertice.
        let numIndicesPerPrimitive = geom.isLines ? 2 : 3;
        indexCount = indexCount - (indexCount % numIndicesPerPrimitive);
        for (let j = 0; j < indexCount; j++) {
            mergedIb[indexOffset + j] = ib[j] + vertexOffset;
        }

        // copy line indices

        // Make sure that indexCount is a multiple of 2. This should always
        // be the case, but we encountered meshes where this condition was violated.
        // In cases, where this condition is not met, this would break the consolidated
        //  buffer, because all later lines would be shifted by one vertex.
        indexlinesCount = indexlinesCount - (indexlinesCount % 2);
        for (let j = 0; j < indexlinesCount; j++) {
            mergedIblines[indexOffsetLines + j] = iblines[j] + vertexOffset;
        }
        indexOffsetLines += indexlinesCount;

        // copy vertex buffer
        mergedVb.set(vb, dstOffset);
        dstOffset += vb.length;

        // set offsets for next geom
        vertexOffset += vertexCount;
        indexOffset  += indexCount;
    }
}

/**
 * Create a single BufferGeometry that contains all geometries.
 * Requirements:
 *  - All geoms must have identical vertex format.
 *  - Geometries must have interleaved vertex buffers
 *  - Geometries must not have instance buffers. But the same geometry may be added with different matrices.
 *
 *  @param {THREE.BufferGeometry[]} geoms
 *  @param {Float32Array}           matrices - array of matrices per geometry. Each matrix is a range of 16 floats.
 *  @param {Int32Array}             dbIds    - db per input geometry. Used to create per-vertex ids.
 *  @param {THREE.Box3}             worldBox - summed worldBox of all transformed geometries
 *  @param {ParallelGeomMerge}      [parallelMerge] - Coordinates worker threads for parallel merge.
 *                                                    Not needed for single-threaded use.
 *  @returns {LmvBufferGeometry}
 */
export function mergeGeometries(geoms, matrices, dbIds, worldBox, parallelMerge) {

    var mergedGeom = createMergeGeom(geoms);

    if (mergedGeom.boundingBox) {
        mergedGeom.boundingBox.copy(worldBox);
    } else {
        mergedGeom.boundingBox = worldBox.clone();
    }

    // copy src vertex/index buffers into mergedGeom
    copyVertexAndIndexBuffers(geoms, mergedGeom);

    // The last steps are either done directly or delegated to a worker thread
    if (parallelMerge) {
        parallelMerge.addMergeTask(geoms, mergedGeom, matrices, dbIds);
    } else {
        runMergeSingleThreaded(geoms, mergedGeom, matrices, dbIds);
    }

    return mergedGeom;
}

/**
 *  Returns true if geom1 and geom2 have compatible vertex format to allow merging.
 *  For this, vbstride and all vertex attributes must be equal.
 *
 * Requirement: This function is only called for geoms that...
 *  1. use interleaved vertex buffers
 *  2. do not use instancing
 *
 * @param {THREE.BufferGeometry} geom1
 * @param {THREE.BufferGeometry} geom2
 * @returns {boolean}
 */
export function canBeMerged(geom1, geom2) {

    if (geom1.vbstride != geom2.vbstride) {
        return false;
    }

    var primType1 = getPrimitiveType(geom1);
    var primType2 = getPrimitiveType(geom2);
    if (primType1 !== primType2) {
        return false;
    }

    // compare pointSize/lineWidth for points/wideLines
    if (geom1.isPoints    && geom1.pointSize !== geom2.pointSize) return false;
    if (geom1.isWideLines && geom1.lineWidth !== geom2.lineWidth) return false;

    if (geom1.attributesKeys.length != geom2.attributesKeys.length) {
        return false;
    }

    // compare each attribute
    for (var i=0, iEnd=geom1.attributesKeys.length; i<iEnd; i++) {
        var key = geom1.attributesKeys[i];

        // get BufferAttributes of both geoms
        var attrib1 = geom1.attributes[key];
        var attrib2 = geom2.attributes[key];

        // if geom2 does not have this, we are done
        if (!attrib2) {
            return false;
        }

        // Since attributes are cached in WGS BufferGeometry, we will mostly detect equality here already.
        if (attrib1 === attrib2) {
            continue;
        }

        // Compare values. Note that it's not enough to compare the THREE.BufferAttribute properties itemSize and normalized, but
        // also some WGS-specific values (see BufferGeometry.js).
        const differentBytesPerItem = attrib1.bytesPerItem !== attrib2.bytesPerItem;
        if (
            attrib1.offset       !== attrib2.offset       ||
            attrib1.normalized   !== attrib2.normalized   ||
            attrib1.itemSize     !== attrib2.itemSize     ||
            differentBytesPerItem                         ||
            attrib1.isPattern    !== attrib2.isPattern
        ) {
            return false;
        }
    }
    return true;
}

/**
 * Creates consolidated geometry by either calculating the world transform on the CPU or
 * by using transform feedback to calculate the world transform on the GPU. If transform feedback
 * is used, the renderer must be provided.
 * 
 * @param {Consolidation} consolidation - The consolidation object.
 * @param {number} meshIndex - The index of the mesh to create the consolidated geometry for.
 * @param {FragmentList} fragList - The fragment list for the model.
 * @param {boolean} [useTransformFeedback=false] - Whether to use transform feedback to calculate the world transform on the GPU.
 * @param {WebGLRenderer} [renderer=null] - The renderer to use for transform feedback.
 */
export function createConsolidatedGeometry(consolidation, meshIndex, fragList, useTransformFeedback = false, renderer = null) {
    // CPU Consolidation
    if (!useTransformFeedback) {
        return consolidation.createMeshGeometry(meshIndex, fragList);
    }

    // GPU Consolidation
    const consolidationMap = consolidation.consolidationMap;
    const mesh = consolidation.meshes[meshIndex];
    const geometry = mesh.geometry;

    if (geometry.attributes) {
        return geometry;
    }
        mesh.proxyGeometry = mesh.geometry;

        const fragIds = consolidationMap.fragOrder;
        const rangeBegin = mesh.rangeBegin;
        const rangeLength = mesh.rangeCount;

        consolidationMap.tmpGeoms.length = rangeLength;
        const matrices = new Float32Array(rangeLength * 16);
        const dbIds = new Uint32Array(rangeLength);

        let fragId;
        for (let i = 0; i < rangeLength; i++) {
            fragId = fragIds[rangeBegin + i];
            consolidationMap.tmpGeoms[i] = fragList.getGeometry(fragId);
            fragList.getOriginalWorldMatrix(fragId, consolidationMap.tmpMatrix);
            matrices.set(consolidationMap.tmpMatrix.elements, i * 16);
            dbIds[i] = fragList.getDbIds(fragId);
        }

        const mergedGeom = createMergeGeom(consolidationMap.tmpGeoms);
        const box = consolidationMap.boxes[mesh.oldRangeIndex];

        if (mergedGeom.boundingBox) {
            mergedGeom.boundingBox.copy(box);
        } else {
            mergedGeom.boundingBox = box.clone();
        }
        copyVertexAndIndexBuffers(consolidationMap.tmpGeoms, mergedGeom);

        const IDBytesPerVertex = 3;
        const ranges = createRangeArray(consolidationMap.tmpGeoms);
        const dstIds = new Uint8Array(IDBytesPerVertex * mergedGeom.vb.length / mergedGeom.vbstride);
        for (let i = 0; i < rangeLength; i++) {
            const rangeBegin = ranges[i + 0];
            const rangeEnd = ranges[i + 1];
            const rangeLength = rangeEnd - rangeBegin;
            const dbId = dbIds[i];
            
            let dstIdsByteOffset = rangeBegin * IDBytesPerVertex;
            for (let j = 0; j < rangeLength; j++) {
                writeIdToBuffer(dbId, dstIds, dstIdsByteOffset);
                dstIdsByteOffset += IDBytesPerVertex;
            }
        }
        mergedGeom.attributes.id.array = dstIds;
        mergedGeom.streamingDraw = STREAMING_DRAW_ONCE_DURING_UPLOAD;
        mergedGeom.streamingIndex = false;

        const consolidatedBuffer = renderer.createConsolidatedBuffer(mergedGeom, matrices, consolidationMap.tmpGeoms);
        mergedGeom.vbbuffer = consolidatedBuffer;
        mergedGeom.needsUpdate = false;
        mergedGeom.ibNeedsUpdate = true;
        mergedGeom.vbNeedsUpdate = false;
        mergedGeom.vbLength = mergedGeom.vb.length;
        mergedGeom.vb = null;
        mergedGeom.discardAfterUpload = true;
        mergedGeom.__webglContextId = renderer.lostContextRecovery?.webglContextId;

        mesh.geometry = mergedGeom;
        mesh.geometry.streamingDraw = geometry.streamingDraw;
        mesh.geometry.streamingIndex = geometry.streamingIndex;
        mesh.geometry.discardAfterUpload = geometry.discardAfterUpload;

        return mergedGeom;
}


/**
 * @typedef ConsolidationPendingInstancingRangeInfo Information required to trigger the computation of an instancing range.
 * @property {number}   [rangeStart]
 * @property {number[]} [rangeEnd]
 */

/**
 * @typedef ConsolidationPendingMeshInfo Information about still pending geometries and materials 
 * necessary to create the placeholder consolidation meshes in the Consolidation.meshes array.
 * 
 * The placeholders meshes that correspond to a range in the consolidation map can only be created once 
 * the actual geometries have been received, because there might be multiple placeholders, due to 
 * incompatible vertex layouts or consolidation meshes becoming too large. This structure keeps track of
 * the materials and the number of geometries that are still pending, before the computation of the 
 * placeholders can be triggered.
 * 
 * @property {boolean}   [materialMissing]
 * @property {number}    [numberMissingGeometries]
 */

/**
 * @typedef ConsolidationPendingWork Pending work for a single geometry or material.
 * 
 * This data structure stores information for each geometry or material that is still pending.
 * For instancing ranges the work is stored directly inside this structure (since those only depend
 * on a single geometry). For consolidation, we store the index of the ConsolidationPendingMeshInfo
 * entry, which maintains the list of all geometries and materials that are still pending.
 * 
 * @property {ConsolidationPendingInstancingRangeInfo[]}  [pendingInstancingRanges]
 * @property {number[]}                                   [pendingMeshes] - indices into pendingConsolidatesMeshInfos
 */

/** 
 * @class Helper class to collect results of ConsolidationBuilder. 
 * @param {number} fragCount - number of fragments in the model
 * @param {number} modelId - model id
 */
export function Consolidation(fragCount, modelId) {

    // all consolidated meshes (+ some original geometries if they could not be merged)
    // Note: the entries in this array are no longer necessarily in 1:1 correspondence
    // with the ranges in the ConsolidationMap. It can happen, that a range gets split into multiple
    // meshes (if the fragment layouts are not compatible or the mesh would get too larger).
    /** @type {(THREE.Mesh|Number[])[]} */ this.meshes = []; 

    // for each initially added source geometry, this array provides the position
    // in this.meshes where we can find the corresponding output mesh. The output mesh
    // is either
    //  a) a consolidated mesh that includes the input geometry or
    //  b) a mesh that shares the original material and geometry (if it couldn't be merged)
    this.fragId2MeshIndex = new Int32Array(fragCount);

    // init with -1. This will be overwritten, if a mesh is created for the fragment, but fragments
    // without any associated geometry will keep this
    for (var i=0; i<this.fragId2MeshIndex.length; i++) {
        this.fragId2MeshIndex[i] = NO_MESH_FOR_FRAGMENT;
    }

    // track summed size
    this.byteSize = 0;

    // Id of the model that has been consolidated
    this.modelId = modelId;

    // keep intermediate result to make reruns faster
    this.consolidationMap = null;

    // With HLOD we don't use THREE.Meshes for single fragments, but instead use the renderbatch mechanism for rendering.
    // For that we need a separate single fragment handling function.
    if (USE_HLOD) {
        this.addSingleFragment = this._addSingleFragmentAsRenderBatch;
        this.nodeId2SingleFragIds = [];
    } else {
        this.addSingleFragment = this._addSingleFragmentAsTHREEMesh;
    }

    /** @type {Map<number, ConsolidationPendingWork>} */  this.pendingGeometries = new Map();
    /** @type {Map<string, ConsolidationPendingWork>} */  this.pendingMaterials = new Map();
    /** @type {Map<number, ConsolidationPendingMeshInfo>} */  this.pendingConsolidationMeshInfos = new Map();
}

Consolidation.prototype = {

    constructor: Consolidation,

    /** Add a consolidation mesh that combines several source geometries.
     *   @param {THREE.BufferGeometry} geom
     *   @param {THREE.Material}       material
     *   @param {number[]}             fragIds         - array of fragment ids associated with this container
     *   @param {number}               [firstFrag]     - Optional: Use (firstFrag, fragCount) to specify
     *   @param {number}               [fragCount]       a range within the fragIds array.
     *   @param {number}               [oldRangeIndex] - Index of the entry in the ranges array
     */
    addContainerMesh: function(geom, material, fragIds, firstFrag, fragCount, oldRangeIndex) {

        // add new mesh
        var newMesh = new THREE.Mesh(geom, material);
        newMesh.modelId = this.modelId;
        this.meshes.push(newMesh);

        // track byte size
        this.byteSize += geom.byteSize;

        // default range: full array
        var rangeStart  = firstFrag || 0;
        var rangeLength = fragCount || fragIds.length;
        var rangeEnd    = rangeStart + rangeLength;

        // Disable THREE frustum culling for all shapes.
        //
        // Reason:
        // Default frustum culling of THREE.js does not work and would let the mesh disappear.
        // This happens because newMesh.computeBoundingSphere() fails for interleaved vertex buffers.
        // (see Frustum.intersectsObject used in FireFlyWebGLRenderer.projectObject)
        //
        // Instead, we apply culling before passing a mesh to the Renderer. (see ConsolidationIterator.js)
        newMesh.frustumCulled = false;

        // The indices in the meshes array are not in 1:1 correspondence with the indices in the rages array,
        // because processing a single range may result in multiple meshes. Therefore, we store the range index
        // and the start and length of  the range in the generated mesh object.        
        newMesh.rangeBegin = firstFrag;
        newMesh.rangeCount = fragCount;
        newMesh.oldRangeIndex = oldRangeIndex;

        // For each source fragment, remember in which container we find it
        var meshIndex = this.meshes.length - 1;
        for (var i=rangeStart; i<rangeEnd; i++) {
            var fragId = fragIds[i];
            this.fragId2MeshIndex[fragId] = meshIndex;
        }
    },

    /**
     *  Add a single mesh that has unique matrix, fragId, and dbId. This is used to add meshes
     *  that share original geometry that could not be merged with anything else.
     *
     *   @param {THREE.BufferGeometry} geom
     *   @param {THREE.Material}      material
     *   @param {number}               fragId
     *   @param {THREE.Matrix4}        matrix
     *   @param {number}               dbId
     */
    addSingleMesh: function(geom, material, fragId, matrix, dbId) {

        // create new mesh
        var newMesh = new THREE.Mesh(geom, material);
        newMesh.matrix.copy(matrix);
        newMesh.matrixAutoUpdate = false;
        newMesh.dbId = dbId;
        newMesh.fragId = fragId;
        newMesh.modelId = this.modelId;

        // add it to mesh array
        this.meshes.push(newMesh);

        // Note: We don't track byteSize for these, because these geometries are shared, i.e., do
        //       not consume any extra memory compared to original geometry.

        // Disable frustum culling (see comment in addContainerMesh)
        newMesh.frustumCulled = false;

        // make it possible to find it later
        this.fragId2MeshIndex[fragId] = this.meshes.length - 1;
    },

    /**
     *  Shortcut to add geometry, material etc. of a single fragment to the consolidation.
     *  This is used for all fragments that could not be combined with others.
     *   @param {number}        fragId
     *   @param {FragmentList}  fragList
     */
    _addSingleFragmentAsTHREEMesh: function(fragId, fragList) {
        const geometry = fragList.getGeometry(fragId);
        const material = fragList.getMaterial(fragId);
        const dbId = fragList.getDbIds(fragId);

        // Note that the model may be moved using the model transform at any time.
        // We don't want the consolidation computation to be affected by this.
        // Therefore, consolidation is always done with excluded dynamic model transform.
        // The model transform is applied later by the ConsolidationIterator.
        // So, it's important to use the originalWorldMatrix here, which is not affected by model transform changes.
        fragList.getOriginalWorldMatrix(fragId, _tmpMatrix);

        this.addSingleMesh(geometry, material, fragId, _tmpMatrix, dbId);
    },

    /**
     *  Shortcut to add a single fragment that should not be rendered via consolidation or instancing.
     *  Only used with per tile consolidation. We later use nodeId2SingleFragIds to create ConsolidatedRenderbatches
     *  containing these single fragments per bvh node.
     *  @param {number} fragId
     */
    _addSingleFragmentAsRenderBatch: function(fragId) {
        // Gather single fragments per bvh node
        const nodeIdx = this.consolidationMap.fragIdToNodeIdx[fragId]; 
        let singleFragIds = this.nodeId2SingleFragIds[nodeIdx];
        if (!singleFragIds) {
            this.nodeId2SingleFragIds[nodeIdx] = singleFragIds = [];
        }
        singleFragIds.push(fragId);

        this.fragId2MeshIndex[fragId] = this.meshes.length;
        this.meshes.push({ fragId });
    },

    /**
     * Create the consolidation geometry for the requested meshIndex
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     * @param {FragmentList} fragList Fragment list for the model
     * @param {Number} [consolidationDeadline] The time (as returned by performance.now) up to which we might perform consolidation
     * @return {THREE.Mesh|null} Consolidate/instanced mesh. If the time didn't suffice
     *                           to compute a consolidated mesh, it will return null.
     */
    createMeshGeometry: function(meshIndex, fragList, consolidationDeadline) {
        var curMesh = this.meshes[meshIndex];// Current mesh
        var curGeom = curMesh.geometry;// Current geometry

        var consolidationMap = this.consolidationMap;

        // if curGeom is a consolidation placeholder created by _buildConsolidationPlaceholder(),
        //   create actual geometry before proceeding and link it to the mesh
        if (!curGeom.attributes) {
            if (consolidationDeadline !== undefined &&
                performance.now() > consolidationDeadline) {
                return null;
            }

            curMesh.proxyGeometry = curMesh.geometry; 
            consolidationMap._buildConsolidationGeometry(curMesh, this, meshIndex, fragList);

            // copy over memory assignment
            curMesh.geometry.streamingDraw = curGeom.streamingDraw;
            curMesh.geometry.streamingIndex = curGeom.streamingIndex;
            
            // If the out of core tile manager is used, we always must discard the geometry after upload, since it is using the temporary buffer
            curMesh.geometry.discardAfterUpload = USE_OUT_OF_CORE_TILE_MANAGER ? true : curGeom.discardAfterUpload;
            curGeom = curMesh.geometry;
        }

        return curGeom;
    },

    /**
     * Free the consolidated/instanced geometry for the specified mesh
     *
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     */
    freeMeshGeometry: function(meshIndex) {
        var curMesh = this.meshes[meshIndex];// Current mesh
        var curGeom = curMesh.geometry;// Current geometry
        if (curGeom === undefined) {
            return;
        }

        if (curGeom.attributes) {
            let parent = curMesh.parent;
            if (parent) {
                curMesh.parent.remove(curMesh);
            }
            curMesh.geometry.dispose();
            curMesh.geometry = curMesh.proxyGeometry;

            curGeom.vb = null;
            curGeom.ib = null;
            curGeom.iblines = null;
            
            for (let attrib in curGeom.attributes) {
                if (curGeom.attributes[attrib].array) {
                    curGeom.attributes[attrib].array = null;
                }
            }
            
            if (parent) {
                parent.add(curMesh);
            }
        }
    },

    /**
     * Check, whether a consolidated mesh geometry is available for the given meshIndex
     * 
     * @param {number} meshIndex - The mesh index 
     * @returns {boolean} - True if the mesh geometry is available, false otherwise
     */
    meshGeometryAvailable: function(meshIndex) {
        return this.meshes[meshIndex]?.geometry?.attributes !== undefined;
    },

    /**
     * Returns the number of bytes the consolidated geometry for the given meshIndex would consume.
     * 
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     * @param {FragmentList} fragList Fragment list for the model
     * @returns {number} The number of bytes the consolidated geometry would consume
     */
    getMemoryCostForConsolidatedMesh: function(meshIndex, fragList) {
        const curMesh = this.meshes[meshIndex];// Current mesh

        if (curMesh.geometry.numInstances > 1 || curMesh.fragId !== undefined) {
            throw new Error('getMemoryCostForConsolidatedMesh() is only valid for consolidated meshes' );
        }

        // Compute the accurate cost
        let requiredMemory = 0;
        let mesh = this.meshes[meshIndex];
        const rangeStart = mesh.rangeBegin;
        const rangeEnd = mesh.rangeBegin + mesh.rangeCount;

        // The use of 3 bytes is currently hardcoded in the geom merge task
        const IDBytesPerVertex = 3;

        const fragIds = this.consolidationMap.fragOrder;
        for (let i = rangeStart; i < rangeEnd; i++) {
            const fragId = fragIds[i];
            const geom = fragList.getGeometry(fragId);
            
            requiredMemory += geom.getAccurateByteSize();
            
            // Add memory that will be needed for the id buffer
            const vertexCount = geom.vb.length / geom.vbstride;
            requiredMemory += IDBytesPerVertex * vertexCount;
        }

        return requiredMemory;
    },

    /**
     * Returns the nodeId for the given rangeIndex
     * 
     * @param {Number} rangeIndex Index of range
     */
    getBvhNodeIdForRange: function(rangeIndex) {
        return this.consolidationMap.bvhNodeIndices[rangeIndex];
    },

    /**
     * Returns the geometry hashes for the pending geometries for the given rangeIndex
     * @param {Number} rangeIndex - Index of range
     * @param {RenderModel} model - Model for which the geometry hashes should be returned
     * @returns {string[]} - Array of geometry hashes
     */
    getPendingGeometryHashesForRange: function(rangeIndex, model) {
        let frags = Array.from(this.consolidationMap.fragOrder.slice(this.consolidationMap.ranges[rangeIndex],this.consolidationMap.ranges[rangeIndex + 1]));
        let geoms = new Set(frags.map( x => model.loader.svf.fragments.geomDataIndexes[x]).filter(x => this.pendingGeometries?.get(x)?.pendingMeshes || []));

        return geoms.map(x => model.loader.svf.getGeometryHash(x));
    },


    /**
     * Apply the current vizflags and theming colors to the mesh and return it
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     * @param {FragmentList} fragList Fragment list for the model
     * @param {Number} drawMode Render pass id from RenderFlags.
     * @param {Bool} specialHandling True if the mesh needs special handling
     * @param {Number} [consolidationDeadline] The time (as returned by performance.now) up to which we might perform consolidation
     * @return {THREE.Mesh|null} Consolidate/instanced mesh. If the time didn't suffice
     *                           to compute a consolidated mesh, it will return null.
     */
    applyAttributes: function(meshIndex, fragList, drawMode, specialHandling, consolidationDeadline) {
        var curMesh = this.meshes[meshIndex];// Current mesh
        var curGeom; // Current geometry
        
        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            // Geometry is  created by the corresponding task
            curGeom = curMesh.geometry;
        } else {
            // If the out-of-core tile manager is not used, we need to create the geometry here
            curGeom = this.createMeshGeometry(meshIndex, fragList, consolidationDeadline);
        }

        // Stop, if no valid geometry is available yet
        if (curGeom === null) {
            return null;
        }

        var consolidationMap = this.consolidationMap;
        var vizflags = fragList.vizflags;

        var fragIds = consolidationMap.fragOrder;
        var instanced = curGeom.numInstances;// Instanced or conslidated
        var rangeStart;     // Start of fragment range
        var rangeEnd;       // End of fragment range
        var fragId;
        var themingActive = fragList.db2ThemingColor.length > 0 || undefined;

        // Get the range of fragments for the mesh.
        if (instanced) {
            // Instanced buffer. The start of the fragment in fragIds
            // is in the rangeStart property of the mesh. The end is
            // numInstances fragments later.
            rangeStart = curMesh.rangeStart;
            rangeEnd = rangeStart + curMesh.geometry.numInstances;
        } else if (curGeom.attributes.id) {
            // Consolidated buffer - The start ranges are in the
            // consolidated map
            if (USE_OUT_OF_CORE_TILE_MANAGER) {
                let mesh = this.meshes[meshIndex];
                rangeStart = mesh.rangeBegin;
                rangeEnd = mesh.rangeBegin + mesh.rangeCount;
            } else {
                rangeStart = consolidationMap.ranges[meshIndex];
                rangeEnd = meshIndex + 1 >= consolidationMap.ranges.length ?
                    consolidationMap.numConsolidated : consolidationMap.ranges[meshIndex + 1];
            }
        } else {
            // No range, just one fragment
            fragId = curMesh.fragId;
        }

        // If the mesh doesn't need special handling, then return it.
        if (!specialHandling || fragId !== undefined) {
            // Clear offsets, but not for single meshes
            if (curGeom.groups && fragId === undefined) {
                curGeom.groups = undefined;
            }
            // set the visibility from the drawMode
            curMesh.visible = isVisible(vizflags[fragId === undefined ? fragIds[rangeStart] : fragId], drawMode);
            curMesh.themingColor = themingActive && fragList.getThemingColor(fragId);
            return curMesh;
        }
        var start = 0;      // Start of current draw call indices
        var end = 0;        // End of currend draw call endices - so far
        var startLines = 0;      // Start of current draw call indices
        var endLines = 0;        // End of currend draw call endices - so far
        var curVisible;     // Current draw call visibility
        var curColor;       // Current draw call color
        var curDrawCall = 0;// Current draw call index

        // Add a draw call to the consolidated mesh
        function addDrawCall() {
            // If the draw call isn't visible, just skip it
            if (curVisible) {
                curGeom.groups = curGeom.groups || [];
                // Avoid calling addDrawCall because this is inside the draw loop
                // and we would like to reduce the number of object created and
                // released, when possible.
                var offset = curGeom.groups[curDrawCall] || { index: 0 };
                curGeom.groups[curDrawCall++] = offset;
                // Only add the draw call if there is something to draw.
                if (instanced) {
                    offset.start = 0;
                    offset.count = curGeom.ib ? curGeom.ib.length : curGeom.ibLength;
                    if (curGeom.iblines || curGeom.iblinesLength) {
                        offset.edgeStart = 0;
                        offset.edgeCount = curGeom.iblines ? curGeom.iblines.length : curGeom.iblinesLength;
                    }
                    offset.instanceStart = start;
                    offset.numInstances = end - start;
                } else {
                    offset.start = start;
                    offset.count = end - start;
                    if (curGeom.iblines || curGeom.iblinesLength) {
                      offset.edgeStart = startLines;
                      offset.edgeCount = endLines - startLines;
                    }
                }
                // Set the theming color in the draw call
                offset.themingColor = curColor;
            }
        }

        function addLastDrawCall() {
            if (start === 0) {
                // Only one draw call, Set theming and visibility for entire mesh
                curMesh.themingColor = curColor;
                curMesh.visible = curVisible;
                curMesh.material = Array.isArray(curMesh.material) ? curMesh.material[0] : curMesh.material;
            } else {
                curMesh.visible = true;
                addDrawCall();
                // Clear existing draw calls
            }
            curGeom.groups && (curGeom.groups.length = curDrawCall);
        }

        // Loop through the fragments in the fragment list
        for (var i = rangeStart; i < rangeEnd; ++i) {
            fragId = fragIds[i];

            // Get the visibility and theming color for the fragment
            var visible = isVisible(vizflags[fragId], drawMode);
            var color = themingActive && fragList.getThemingColor(fragId);

            // Skip the first time through the loop
            if (visible !== curVisible || (visible && (color !== curColor))) {
                // Visibility or color change, add a draw call
                if (end > start) {
                    addDrawCall();
                }
                // Reset the draw call variables
                start = end;
                startLines = endLines;
                curVisible = visible;
                curColor = color;
            }

            // Add current fragment into the next draw call
            if (instanced) {
                end += 1;
            } else {
                var geom = fragList.getGeometry(fragId);
                end += geom.ib ? geom.ib.length : geom.ibLength;
                if (geom.iblines || geom.iblinesLength) {
                    endLines += geom.iblines ? geom.iblines.length : geom.iblinesLength;
                }
            }
        }
        // Add last draw call for the last mesh
        addLastDrawCall();

        return curMesh;
    },

    dispose: function() {
        var DISPOSE_EVENT = {type: 'dispose'};
        var REMOVED_EVENT = {type: 'removed'};

        this.consolidationMap.dispose();

        for (var i=0; i<this.meshes.length; i++) {
            var mesh = this.meshes[i];
            var geom = mesh?.geometry;
            if (geom) {
                //Both of these are needed -- see also how it's done in FragmentList dispose
                mesh.dispatchEvent(REMOVED_EVENT);
                geom.dispatchEvent(DISPOSE_EVENT);

                // In case of later reuse, setting needsUpdate is essential to render it again.
                geom.needsUpdate = true;
            }
        }

    }
};


/**
 *  @class ConsolidationBuilder is a utility to merge several (usually small) objects into larger ones to
 *  improve rendering performance.
 */
export function ConsolidationBuilder() {
    this.buckets = new Map(); // Map<MergeBucket[]>
    this.bucketCount = 0;
    this.costs   = 0;  // Consolidation costs in bytes (=costs of merged Geometries for each bucket with >=2 geoms)
}


ConsolidationBuilder.prototype = {

    /**
     *  Add a new Geometry for consolidation. Note that some geometries cannot be merged (e.g., if their material
     *  is different from all others.). In this case, the output mesh just shares input geometry and material.
     *
     *   @param {THREE.BufferGeometry} geom
     *   @param {number}               materialId - id of the material used for this geometry
     *   @param {THREE.Box3}           worldBox - worldBox (including matrix transform!)
     *   @param {Number}               fragId   - used to find out later in which output mesh you find this fragment
     *   @param {Uint32Array}          [fragIdToNodeIdx] - Optional mapping from fragId to BVH node index
     */
    addGeom: function(geom, materialId, worldBox, fragId, fragIdToNodeIdx) {

        // find bucket of meshes that can be merged with the new one
        var bucket = null;
        let id = fragIdToNodeIdx ? `${materialId}_${fragIdToNodeIdx[fragId]}` : materialId;
        var buckets = this.buckets.get(id);
        if (buckets) {
            for (var i=0; i<buckets.length; i++) {

                // get next bucket
                var nextBucket = buckets[i];

                // compatible primitive type and vertex format?
                var bucketGeom = nextBucket.geoms[0];

                if (geom !== null) {
                    // We only check for mergeability and bucket vertex count here, if
                    // we are not using the out-of-core tile manager
                    // Otherwise, we will have to check these conditions later when
                    // building the mesh
                    if (!canBeMerged(bucketGeom, geom)) {
                        continue;
                    }

                    // this bucket would allow merging, but only if the vertex count doesn't grow too much
                    var vertexCount = getVertexCount(geom);
                    if (vertexCount + nextBucket.vertexCount > MaxVertexCountPerMesh) {
                        continue;
                    }
                }

                // we found a bucket to merge with
                bucket = nextBucket;
                break;
            }
        }

        // create a new bucket to collect this mesh
        if (!bucket) {
            bucket = new MergeBucket(materialId, fragIdToNodeIdx?.[fragId]);
            this.bucketCount++;

            if (!buckets)
                this.buckets.set(id, [bucket]);
            else
                buckets.push(bucket);
        }

        // add geometry to bucket
        this.costs += bucket.addGeom(geom, worldBox, fragId);
    },

    /**
     * When all geometries have been added to buckets using addGeom() calls, this function converts the buckets into a
     * more compact representation called ConsolidationMap. This map summarizes all information that we need to build
     * the FragmentList consolidation.
     *
     * @param {Uint32Array}    allFragIds      - all fragIds, sorted by consolidation costs.
     * @param {numConsolidate} numConsolidated - number of ids in allFragIds that have been added to consolidation buckets
     *                                           all remaining ones are processed separately by instancing.
     * @returns {ConsolidationMap}
     */
    createConsolidationMap: function(allFragIds, numConsolidated, fragIdToNodeIdx) {

        // init result object
        var fragCount   = allFragIds.length;
        var result = new ConsolidationMap(fragCount, this.bucketCount, fragIdToNodeIdx);

        // fill fragOrder and ranges. Each range contains all fragIds of a single bucket
        var nextIndex = 0;
        var bucketIdx = 0;
        this.buckets.forEach((buckets) => {

            for (var b=0; b<buckets.length; b++) {

                var bucket = buckets[b];

                // store start index of the range in fragOrder that corresponds to this bucket
                result.ranges[bucketIdx] = nextIndex;
                result.geometryCosts[bucketIdx] = bucket.cost;
                result.bvhNodeIndices[bucketIdx] = bucket.bvhNodeId;

                // store bucket box (no need to copy)
                result.boxes[bucketIdx] = bucket.worldBox;

                // append all fragIds in this bucket
                result.fragOrder.set(bucket.fragIds, nextIndex);

                // move nextIndex to the next range start
                nextIndex += bucket.fragIds.length;
                bucketIdx++;
            }
        });

        // remember which fragIds remain and must be processed by instancing
        result.numConsolidated = numConsolidated;
        for (var i=numConsolidated; i<allFragIds.length; i++) {
            result.fragOrder[i] = allFragIds[i];
        }
        return result;
    }
};

/**
 * A ConsolidationMap is an intermediate result of a FragmentList consolidation. It describes which
 * fragments are to be merged into consolidated meshes and which ones have to be processed by instancing.
 * 
 * @param {number} fragCount              - Number of fragments in the model
 * @param {number} bucketCount            - Number of consolidation buckets
 * @param {Uint32Array} [fragIdToNodeIdx] - Optional mapping from fragId to BVH node index
 */
export function ConsolidationMap(fragCount, bucketCount, fragIdToNodeIdx  = null, cachedData = null) {

    if(cachedData) {
        this.fragOrder = cachedData.fragOrder;
        this.ranges = cachedData.ranges;
        this.boxes = cachedData.boxes;
        this.numConsolidated = cachedData.numConsolidated;
        this.bvhNodeIndices = cachedData.bvhNodeIndices;
    }
    else {
        // Ordered array of fragIds. Each range of the array defines a merge bucket.
        this.fragOrder = new Uint32Array(fragCount);

        // Offsets into fragOrder. ranges[i] is the startIndex of the range corresponding to merge bucket i.
        this.ranges = new Uint32Array(bucketCount);
        this.geometryCosts = new Uint32Array(bucketCount);
        this.bvhNodeIndices = new Uint32Array(bucketCount);

        // Cached bboxes of consolidated meshes
        this.boxes = new Array(bucketCount);

        // Store how many fragIds in fragOrder have been added to merge buckets.
        // (fragIds[0], ..., fragIds[numConsolidated-1].
        this.numConsolidated = -1; // will be set in createConsolidationMap
    }

    // tmp objects
    this.tmpGeoms  = [];
    this.tmpMatrix = new THREE.Matrix4();

    // Optional mapping from fragId to BVH node index
    this.fragIdToNodeIdx = fragIdToNodeIdx;

    this.freeListeners = null;
}

ConsolidationMap.prototype = {

    /**
     * Create consolidated meshes.
     *  @param {FragmentList}    fragList
     *  @param {MaterialManager} matman
     *  @param {RenderModel}     model
     *  @param {boolean}         [useDeferredConsolidation] - If true, only some preparation work is executed immediately. Actual mesh creation happens on rendering.
     *                                                        If false, the system automatically determines if some part is delegated to a
     *                                                        worker thread, so that the blocking time is shorter, or everything happens immediately.
     *  @returns {Consolidation}
     */
    buildConsolidation: function(fragList, matman, model, useDeferredConsolidation = false) {

        // some shortcuts
        var fragCount = fragList.getCount();
        var rangeCount = this.ranges.length;

        var result = new Consolidation(fragCount, model.getModelId());

        // Init worker thread if enabled
        var parallelMerge = null;

        if (!useDeferredConsolidation) {
            // Check if a worker-implementation is available.
            if (multithreadingSupported()) {
                // Activate multithreaded consolidation
                parallelMerge = new ParallelGeomMerge(result);
            } else {
                //console.warn("Multithreaded consolidation requires to registers worker support. Falling back to single-threaded consolidation.");
            }
        }

        // store this consolidation map with the consolidation, so that we can rebuild it faster.
        result.consolidationMap = this;

        // each range of fragIds is merged into a consolidated mesh
        for (var c=0; c<rangeCount; c++) {
            this._buildConsolidationMesh(c, fragList, matman, model, useDeferredConsolidation, parallelMerge, result);
        }

        // Register an event listener to handle the case where a geometry is added to the model after the consolidation
        if (useEarlyConsolidation(model) &&
            result.pendingConsolidationMeshInfos.size > 0) {
            this._registerPendingFragmentEventListeners(fragList, model, matman, result);
        }

        if (parallelMerge) {
            // start workers for geometry merging. This will invoke the worker operations and
            // set result.inProgress to true until all worker results are returned.
            parallelMerge.runTasks();
        }

        return result;
    },

    /**
     * Adds event listeners to wait for pending geometries and Meshes to be added to the model.
     * 
     * @param {FragmentList}    fragList     - Fragment List
     * @param {MaterialManager} matman       - Material Manager
     * @param {RenderModel} model            - The model for which the consolidation has been computed
     * @param {Consolidation} consolidation  - The consolidation object
     */
    _registerPendingFragmentEventListeners: function(fragList, model, matman, consolidation) {
        let updateNode = (rangeIdx) => {
            let nodeId = consolidation.getBvhNodeIdForRange(rangeIdx);
            let iterator = model.getIterator();

            if (iterator instanceof ModelIteratorBVH) {
                let outOfCoreTileManager = OutOfCoreTileManager.getManagerForIterator(iterator, model, true);
                outOfCoreTileManager?.[0].tryInitializeNode(nodeId);
            }
        };

        globalThis.listenersRegistered = true;
        const processPendingWork = (pendingWork, geometryReceived) => {
            const missingMeshesArray = pendingWork.pendingMeshes;
            if (missingMeshesArray) {
                for (let i = 0; i < missingMeshesArray.length; i++) {
                    const rangeIdx = missingMeshesArray[i];
                    const pendingMesh = consolidation.pendingConsolidationMeshInfos.get(rangeIdx);
                    if (!pendingMesh) {
                        continue;
                    }

                    if (geometryReceived) {
                        pendingMesh.numberMissingGeometries--;
                    } else {
                        pendingMesh.materialMissing = false;
                    }

                    if (pendingMesh.numberMissingGeometries === 0 && !pendingMesh.materialMissing) {
                        // We have to check, whether the mesh is still pending, because
                        // the material handler could have been invoked and could already have
                        // processed it it in the meantime
                        let pendingMesh = consolidation.pendingConsolidationMeshInfos.get(rangeIdx);
                        if (pendingMesh) {
                            this._buildConsolidationMesh(rangeIdx, fragList, matman, model, true, false, consolidation);
                            updateNode(rangeIdx);
                        }
                    }
                }
            }

            if (pendingWork.pendingInstancingRanges) {
                let ranges = pendingWork.pendingInstancingRanges;
                for (let range of ranges) {
                    applyInstancingToRange(model, matman, this.fragOrder, 
                                        range.rangeStart,
                                        range.rangeEnd,
                                        consolidation);
                    // TODO: How can we find the BVH nodes for the instance range?
                    //       A range can be split across multiple nodex.
                    //       For the moment, we continue to rely on the consolidation iterator
                    //       to handle this case.
                }
            }
        };

        const onGeometryLoadedListener = (event) => {
            if (event.model.getModelId() !== consolidation.modelId) {
                return;
            }

            const pendingWork = consolidation.pendingGeometries.get(event.geomId);
            if (!pendingWork) {
                return;
            }
            
            processPendingWork(pendingWork, true);
            consolidation.pendingGeometries.delete(event.geomId);
        };

        const onMaterialLoadedListener = (event) => {
            if (event.model.getModelId() !== consolidation.modelId) {
                return;
            };
            let material = event.material;
            const pendingWork = consolidation.pendingMaterials.get(material?.hash);
            if (!pendingWork) {
                return;
            }

            processPendingWork(pendingWork, false);
            consolidation.pendingMaterials.delete(material.hash);
        };

        model.loader.viewer3DImpl.api.addEventListener(EventTypes.NEW_GEOMETRY_ADDED, onGeometryLoadedListener);
        model.loader.viewer3DImpl.api.addEventListener(EventTypes.NEW_MATERIAL_ADDED, onMaterialLoadedListener);

        // The consolidation map can be reused when the model is reconsolidated. In that case, we already have event listeners
        // which had been registered previously. We free the old listeners and register new ones.
        if (this.freeListeners) {
            this.freeListeners();
        }
        this.freeListeners = () => {
            model.loader.viewer3DImpl.api.removeEventListener(EventTypes.NEW_GEOMETRY_ADDED, onGeometryLoadedListener);
            model.loader.viewer3DImpl.api.removeEventListener(EventTypes.NEW_MATERIAL_ADDED, onMaterialLoadedListener);
        };
    },

    /**
     * Unregisters the event listeners that were added to wait for pending geometries and materials.
     */
    _unregisterPendingFragmentEventListeners: function() {
        if (this.freeListeners) {
            this.freeListeners();
            this.freeListeners = null;
        }
    },

    /**
     * Internal function for creating a single consolidated meshes and adding it to the result.
     *  @param {Number}          rangeIdx                   - index into this.ranges
     *  @param {FragmentList}    fragList
     *  @param {MaterialManager} matman
     *  @param {RenderModel}     model
     *  @param {boolean}         [useDeferredConsolidation] - If true, only some preparation work is executed immediately. Actual mesh creation happens on rendering.
     *                                                        If false, the system automatically determines if some part is delegated to a
     *                                                        worker thread, so that the blocking time is shorter, or everything happens immediately.
     *  @param {ParallelGeomMerge} [parallelMerge]          - if provided and useDeferredConsolidation is false, consolidation tasks are accumulated in that object
     *  @param {Consolidation}   result                     - output
     */
    _buildConsolidationMesh: function(rangeIdx, fragList, matman, model, useDeferredConsolidation, parallelMerge, result) {
        var fragIds   = this.fragOrder;
        var rangeCount = this.ranges.length;

        // get range of fragIds in this.fragOrder from which we build the next consolidated mesh.
        // Note that this.ranges only contains the range begins and the last range ends at this.numConsolidated.
        var rangeBegin  = this.ranges[rangeIdx];
        var rangeEnd    = (rangeIdx===(rangeCount-1)) ? this.numConsolidated : this.ranges[rangeIdx+1];
        var rangeLength = rangeEnd - rangeBegin;

        // Make sure material and geometries have already been loaded
        if (useEarlyConsolidation(model)) {
            /** @type {ConsolidationPendingMeshInfo|undefined} */ let pendingMeshInfo;
            
            let missingGeomCount = 0;
            const alreadyAddedGeoms = new Set();
            for (let i = rangeBegin; i < rangeEnd; i++) {
                const fragId = fragIds[i];
                const geomId = fragList.getGeometryId(fragId);
                const geom  = fragList.geoms.getGeometry(geomId);
                if (!geom) {
                    if (geomId === 0 || alreadyAddedGeoms.has(geomId)) {
                        continue;
                    }
                    let pendingGeometryWork = result.pendingGeometries.get(geomId);
                    if (!pendingGeometryWork) {
                        pendingGeometryWork = {};
                        result.pendingGeometries.set(geomId, pendingGeometryWork);
                    }

                    let missingMeshesArray = pendingGeometryWork.pendingMeshes;
                    if (!pendingGeometryWork.pendingMeshes) {
                        missingMeshesArray = [];
                        pendingGeometryWork.pendingMeshes = missingMeshesArray;
                    }

                    if (missingMeshesArray.indexOf(rangeIdx) === -1) {
                        missingMeshesArray.push(rangeIdx);
                    }
                    missingGeomCount++;
                    alreadyAddedGeoms.add(geomId);
                }
            }

            if (missingGeomCount > 0) {
                pendingMeshInfo = {
                    materialMissing: false,
                    numberMissingGeometries: missingGeomCount
                };
            }

            if (rangeLength > 0) {
                let firstFrag = fragIds[rangeBegin];
                let materialID = model.loader.svf.fragments.materials[firstFrag];
                let materialHash = model.loader.svf.materialHashes.hashes[materialID];
                let material = matman.findMaterial(model, materialHash);

                if (!material) {
                    pendingMeshInfo = pendingMeshInfo || {
                        materialMissing: true,
                        numberMissingGeometries: 0
                    };

                    let pendingMaterialsInfo  = result.pendingMaterials.get(materialHash);
                    if (!pendingMaterialsInfo) {
                        pendingMaterialsInfo = {
                            pendingMeshes: []
                        };
                        result.pendingMaterials.set(materialHash, pendingMaterialsInfo);
                    }
                    pendingMaterialsInfo.pendingMeshes = pendingMaterialsInfo.pendingMeshes || [];
                    let pendingMeshes = pendingMaterialsInfo.pendingMeshes;
                    if (pendingMeshes.indexOf(rangeIdx) === -1) {
                        pendingMeshes.push(rangeIdx);
                    }

                    pendingMeshInfo.materialMissing = true;
                }
            }

            if (pendingMeshInfo) {
                result.pendingConsolidationMeshInfos.set(rangeIdx, pendingMeshInfo);

                // Mark the fragments as not yet being loaded
                for (var i=rangeBegin; i<rangeEnd; i++) {
                    var fragId = fragIds[i];
                    result.fragId2MeshIndex[fragId] = -rangeIdx + MIN_VALUE_FOR_PENDING_RANGE;
                }
                return;
            }
        }
        
        if (result.pendingConsolidationMeshInfos.has(rangeIdx)) {
            result.pendingConsolidationMeshInfos.delete(rangeIdx);
        }
        // just 1 shape? => just share original geometry and material
        if (rangeLength === 1) {
            const fragId = fragIds[rangeBegin];
            result.addSingleFragment(fragId, fragList);
            return;
        }

        const subRanges = [];
        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            // If we have multiple meshes in the bucket, we might have to further subdivide them into 
            // several consolidated meshes. Either because they cannot be merged or because they have
            // too many vertices.
            let subBuckets = [];
            for (let i = rangeBegin; i < rangeEnd; i++) {
                const fragId = fragIds[i];

                // At this point, all geometries should have been loaded and should
                // be available in the fragment list.
                // However, if a selective loading controller is enabled or we had some network issues, 
                // it is possible that some fragments didn't get activated. In that case, we ignore the fragment here.
                if (!fragList.isFragmentActive(fragId)) {
                    continue;
                }

                const geom = fragList.getGeometry(fragId);

                // find bucket of meshes that can be merged with the new one
                let foundSubBucket = null;
                let vertexCount = getVertexCount(geom);
                for (let j = 0; j < subBuckets.length; j++) {

                    // get next bucket
                    const subBucket = subBuckets[j];

                    // compatible primitive type and vertex format?
                    const subBucketGeom = subBucket.geoms[0];

                    // We only check for mergeability and bucket vertex count here, if
                    // we are not using the out-of-core tile manager
                    // Otherwise, we will have to check these conditions later when
                    // building the mesh
                    if (!canBeMerged(subBucketGeom, geom)) {
                        continue;
                    }

                    // this bucket would allow merging, but only if the vertex count doesn't grow too much
                    if (vertexCount + subBucket.vertexCount > MaxVertexCountPerMesh) {
                        continue;
                    }

                    foundSubBucket = subBucket;
                    break;
                }

                if (foundSubBucket) {
                    foundSubBucket.geoms.push(geom);
                    foundSubBucket.fragIDs.push(fragId);
                    foundSubBucket.vertexCount += vertexCount;
                } else {
                    subBuckets.push({
                        geoms: [geom],
                        fragIDs: [fragId],
                        vertexCount: vertexCount
                    });
                }
            }

            let index = rangeBegin;
            for (let i = 0; i < subBuckets.length; i++) {
                fragIds.set(subBuckets[i].fragIDs, index);
                subRanges.push([index, subBuckets[i].fragIDs.length]);
                index += subBuckets[i].fragIDs.length;
            }
            
        } else {
            subRanges.push([rangeBegin, rangeLength]);
        }

        for (const subRange of subRanges) {
            let mergedGeom = null;
            if (useDeferredConsolidation) {
                mergedGeom = this._buildConsolidationPlaceholder(subRange[0], subRange[1], fragIds, fragList);
            } else {
                mergedGeom  = this._buildConsolidationGeometryImpl(result, rangeIdx, subRange[0], subRange[1], fragIds, fragList, parallelMerge);
            }
            mergedGeom.nodeIdx = this.bvhNodeIndices[rangeIdx];

            // use material of first frag in the bucket
            var firstFrag = fragIds[subRange[0]];
            var material = fragList.getMaterial(firstFrag);
            var newMaterial = matman.getMaterialVariant(material, MATERIAL_VARIANT.VERTEX_IDS, model);

            // add result
            result.addContainerMesh(mergedGeom, newMaterial, fragIds, subRange[0], subRange[1], rangeIdx);
        }

    },

    /**
     * Internal function for creating a single consolidated geometry.
     *  @param {THREE.Mesh}      mesh                       - mesh object containing the geometry
     *  @param {Consolidation}   consolidation              - Consolidation object
     *  @param {Number}          rangeIdx                   - index into this.ranges
     *  @param {FragmentList}    fragList
     *  @param {ParallelGeomMerge} [parallelMerge]          - if provided consolidation tasks are accumulated in that object
     *  @returns {BufferGeometry}
     */
     _buildConsolidationGeometry: function(mesh, consolidation, rangeIdx, fragList, parallelMerge) {
        var fragIds   = this.fragOrder;

        // get range of fragIds in this.fragOrder from which we build the next consolidated mesh.
        let rangeBegin  = mesh.rangeBegin;
        let rangeLength = mesh.rangeCount;

        if (!USE_OUT_OF_CORE_TILE_MANAGER && rangeLength === 1) {
            // just 1 shape? => just share original geometry and material
            return;
        }

        mesh.geometry =  this._buildConsolidationGeometryImpl(consolidation, mesh.oldRangeIndex, rangeBegin, rangeLength, fragIds, fragList, parallelMerge);
    },

    /**
     * Very internal function for creating a single consolidated geometry from a given range.
     *  @param {Consolidation}   consolidation              - Consolidation object
     *  @param {Number}          rangeIdx                   - index into this.ranges
     *  @param {Number}          rangeBegin                 - first fragment in fragIds
     *  @param {Number}          rangeLength                - length of this range
     *  @param {Uint32Array}     fragIds                    - list of fragIds, from which the range is taken
     *  @param {FragmentList}    fragList
     *  @param {ParallelGeomMerge} [parallelMerge]          - if provided consolidation tasks are accumulated in that object
     *  @returns {BufferGeometry}
     */
     _buildConsolidationGeometryImpl: function(consolidation, rangeIdx, rangeBegin, rangeLength, fragIds, fragList, parallelMerge) {

        // create array of BufferGeometry pointers
        this.tmpGeoms.length = rangeLength;

        // create Float32Array containing the matrix per src fragment
        var matrices = new Float32Array(16 * rangeLength);

        // create Int32Array of dbIds
        var dbIds = new Uint32Array(rangeLength);

        let fragId;
        for (var i=0; i<rangeLength; i++) {
            fragId = fragIds[rangeBegin + i];

            // fill geoms
            this.tmpGeoms[i] = fragList.getGeometry(fragId);

            // store matrix as 16 floats
            fragList.getOriginalWorldMatrix(fragId, this.tmpMatrix);
            matrices.set(this.tmpMatrix.elements, 16*i);

            // store dbId in Int32Array
            dbIds[i] = fragList.getDbIds(fragId);
        }

        // get box of consolidated mesh
        var box = this.boxes[rangeIdx];
        var mergedGeom  = mergeGeometries(this.tmpGeoms, matrices, dbIds, box, parallelMerge);
        
        // Make sure, that consolidated meshes are always kept on the GPU
        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            mergedGeom.streamingDraw = false;
            mergedGeom.streamingIndex = false;
        }

        return mergedGeom;
    },

    /**
     * Very internal function for creating a single placeholder for consolidated geometry from a given range.
     *  @param {Number}          rangeBegin                 - first fragment in fragIds
     *  @param {Number}          rangeLength                - length of this range
     *  @param {Uint32Array}     fragIds                    - list of fragIds, from which the range is taken
     *  @param {FragmentList}    fragList
     *  @returns {Object}
     */
     _buildConsolidationPlaceholder: function(rangeBegin, rangeLength, fragIds, fragList) {
        // compute byteSize (for memory assignment)
        let byteSize = 0;
        for (let i = 0; i < rangeLength; i++) {
            const fragId = fragIds[rangeBegin + i];

            // fill geoms
            const geom = fragList.getGeometry(fragId);
            byteSize += getByteSize(geom);
        }

        // create temporary "geometry" (to be replaced on first use)
        // need to define dispatchEvent in case the geometry is never uploaded
        const mergedGeom = { byteSize, dispatchEvent: function () { } };
        return mergedGeom;
    },

    /**
     * Dispose the consolidation map.
     */
    dispose: function() {
        this._unregisterPendingFragmentEventListeners();
    }
};

function multithreadingSupported() {
    return  !!ParallelGeomMerge.createWorker;
}

/*
 * A too fine-grained BVH may neutralize the performance gain by consolidation. To avoid that, use these defaults
 * for bvh settings when consolidation is wanted. Model loaders do this automatically when useConsolidation is set to true.
 *  @param {Object} bvhOptions
 */
Consolidation.applyBVHDefaults = function(bvhOptions) {
    bvhOptions["frags_per_leaf_node"] = 512;
    bvhOptions["max_polys_per_node"]  = 100000;
};
