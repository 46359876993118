import {NumIdTargets} from "../CommonRenderTargets";
import {RenderBatch, ConsolidatedRenderBatch} from "../../scene/RenderBatch";
import {vectorToABGR} from "./uniforms/ObjectUniforms";
import {UberPipeline} from "./UberPipeline";
import {EdgePipeline} from "./EdgePipeline";
import {LinePipeline} from "../2d/LinePipeline";
import {MaterialUniforms } from "./MaterialUniforms";
import {RenderBatchShim} from "../sceneToBatch";
import {IBL} from "./IBL";
import {CameraUniforms} from "./CameraUniforms";
import {LineUniforms} from "../2d/LineUniforms";
import {FrameBindGroup} from "./FrameBindGroup";
import { Renderer } from "../Renderer";

export const useNewUniforms = true;

// These settings are only used during pipeline creation. No actual material needed.
const edgeMaterialSettings = {
	depthTest: true,
	depthWrite: true,
	depthFunc: "less-equal"
};

const edgeHighlightMaterialUnder = {
	depthTest: true,
	depthWrite: false,
	depthFunc: "greater"
};

const edgeHighlightMaterialOver = {
	depthTest: true,
	depthWrite: false,
	depthFunc: "less-equal"
};

const ghostMaterialSettings = {
	depthTest: true,
	depthWrite: true,
	depthFunc: "less"
};

// TODO connect to WebGL values in https://jira.autodesk.com/browse/VIZX-1508
//      Using the same values as WebGL, edges still look more prominent in WebGPU. There could be a
//      difference in how lines are rasterized (see also https://www.w3.org/TR/webgpu/#line-rasterization)
const EDGE_COLOR_DARK = { x: 0, y: 0, z: 0, w: 0.2 }; // WebGL: Viewer3DImpl.edgeColorMain
const EDGE_COLOR_GHOSTED = { x: 0.0, y: 0.0, z: 0, w: 0.1 }; // WebGL: Viewer3DImpl.edgeColorGhosted
const EDGE_COLOR_HIGHLIGHT = { x:1, y:1, z:1, w:1 }; // WebGL: RenderContextHelper._edgeColorHighlight
const EDGE_COLOR_HIGHLIGHT_UNDER = {x: 1, y: 1, z: 1, w: 0.5 }; // WebGL: RenderContextHelper._edgeColorHighlightUnder

/**
 * @param {Renderer} renderer
 */
export class MainPass {
	/** @type {Renderer} */
	#renderer;
	/** @type {GPUDevice} */
	#device;

	#mainPassDescriptorProg; #edgePassDescriptor; #clearPassDescriptor;
	#overlayPassDescriptorWithClear; #overlayPassDescriptorNoClear;
	#renderBundleDescriptor; #renderBundleEdgesDescriptor;

	#useRenderBundles; #renderBundle;

	#encoder; #count;

	/** @type {IBL} */
	#iblUniforms;

	//TODO: Investigate using a ring buffer here in case uploading
	//object uniforms for each render batch using the same buffer is considered a bottleneck
	#objectUniforms; #materialUniforms;
	/** @type {CameraUniforms} */
	#cameraUniforms;
	#lineUniforms;

	/** @type {FrameBindGroup} */
	#frameBindGroup;

	#mainPipeline; #edgePipeline; #overlayPipeline;
	/** @type {LinePipeline} */
	#linePipeline;
	/** @type {LinePipeline} */
	#linePipelineOverlays; // For overlays, we need a separate pipeline, because the Pipelines must be created for different target setups.

	#edgeColorMainInt = vectorToABGR(EDGE_COLOR_DARK);
	#edgeColorGhostedInt = vectorToABGR(EDGE_COLOR_GHOSTED);

	#bindGroupLayouts;

	#geometriesList = [];
	#renderIndicesList = [];

	// Only true within a renderScenePart call for a ConsolidatedRenderBatch. See #renderBatchCallback for details.
	#needsLookupForFragmentUniforms = false;

	// Reusable shim for wrapping THREE.Scenes so we don't need to recreate it multiple times per frame.
	#rBatchShim;

	constructor(renderer) {
		this.#renderer = renderer;
		this.#iblUniforms = new IBL(renderer);
		this.#rBatchShim = new RenderBatchShim();
	}

	#createMainPass() {

		let rt = this.#renderer.getRenderTargets();
		let colorTargetView = rt.getColorTarget().createView();
		let depthTargetView = rt.getDepthTarget().createView();
		let normalsTargetView = rt.getNormalsTarget().createView();
		let viewDepthTargetView = rt.getViewDepthTarget().createView();
		let overlayTargetView = rt.getOverlayTarget().createView();

		this.#clearPassDescriptor = {
			colorAttachments: [
				{
					view: normalsTargetView,
					clearValue: { r: 0, g: 0, b: 0, a: 0 },
					loadOp: 'clear',
					storeOp: 'store',
				},
				{
					view: viewDepthTargetView,
					clearValue: { r: 0, g: 0, b: 0, a: 0 },
					loadOp: 'clear',
					storeOp: 'store',
				},
			],
			depthStencilAttachment: {
				view: depthTargetView,
				depthClearValue: 1.0,
				depthLoadOp: 'clear',
				depthStoreOp: 'store',
			},
		};

		this.#mainPassDescriptorProg = {
			colorAttachments: [
				{
					view: colorTargetView,
					loadOp: 'load',
					storeOp: 'store',
				},
				{
					view: normalsTargetView,
					loadOp: 'load',
					storeOp: 'store',
				},
				{
					view: viewDepthTargetView,
					loadOp: 'load',
					storeOp: 'store',
				},
			],
			depthStencilAttachment: {
				view: depthTargetView,
				depthLoadOp: 'load',
				depthStoreOp: 'store',
			},
		};

		this.#renderBundleDescriptor = {
			colorFormats: [
				rt.getColorTarget().format,
				rt.getNormalsTarget().format,
				rt.getViewDepthTarget().format
			],
			depthStencilFormat: [rt.getDepthTarget().format]
		};

		for (let i=0; i<NumIdTargets; i++) {

			let attachment = {
				view: rt.getIdTarget(i).createView(),
				clearValue: { r: 0, g: 0, b:0, a: 0 },
				loadOp: "clear",
				storeOp: "store"
			};

			let attachmentProg = {
				view: attachment.view,
				loadOp: "load",
				storeOp: "store"
			};

			let format = rt.getIdTarget(i).format;

			this.#clearPassDescriptor.colorAttachments.push(attachment);
			this.#mainPassDescriptorProg.colorAttachments.push(attachmentProg);
			this.#renderBundleDescriptor.colorFormats.push(format);
		}

		this.#renderBundleEdgesDescriptor = {
			colorFormats: [
				rt.getColorTarget().format,
			],
			depthStencilFormat: [rt.getDepthTarget().format]
		};

		this.#edgePassDescriptor = {
			colorAttachments: [
				{
					view: colorTargetView,
					loadOp: 'load',
					storeOp: 'store',
				}
			],
			depthStencilAttachment: {
				view: depthTargetView,
				depthLoadOp: 'load',
				depthStoreOp: 'store',
			},
		};

		this.#overlayPassDescriptorWithClear = {
			colorAttachments: [
				{
					view: overlayTargetView,
					clearValue: { r: 0, g: 0, b: 0, a: 0 },
					loadOp: 'clear',
					storeOp: 'store',
				}
			]
		};

		this.#overlayPassDescriptorNoClear = {
			colorAttachments: [
				{
					view: overlayTargetView,
					loadOp: 'load',
					storeOp: 'store',
				}
			],
			depthStencilAttachment: {
				view: depthTargetView,
				depthLoadOp: 'load',
				depthStoreOp: 'store',
			},
		};

	}

	init(objectUniforms) {

		this.#device = this.#renderer.getDevice();

		this.#iblUniforms.init();
		this.#cameraUniforms = new CameraUniforms(this.#device);
		this.#frameBindGroup = new FrameBindGroup(
			this.#device, this.#cameraUniforms, this.#iblUniforms);

		//TODO: Investigate using a ring buffer here in case uploading
		//object uniforms for each render batch using the same buffer is considered a bottleneck
		this.#objectUniforms = objectUniforms;
		this.#geometriesList.length = this.#objectUniforms.MAX_BATCH;
		this.#renderIndicesList.length = this.#objectUniforms.MAX_BATCH;

		this.#materialUniforms = new MaterialUniforms(this.#device, undefined, this.#renderer.getPlaceholderTexture());

		this.#lineUniforms = new LineUniforms(this.#device);

		this.#mainPipeline = new UberPipeline(this.#renderer);
		this.#edgePipeline = new EdgePipeline(this.#renderer);
		this.#linePipeline = new LinePipeline(this.#renderer);
		this.#linePipelineOverlays = new LinePipeline(this.#renderer);

		//The overlay pipeline uses a different set of render targets,
		//so we maintain a separate instance of the UberPipeline for it (since targets list is not taken into account
		//in pipeline cache keys)
		this.#overlayPipeline = new UberPipeline(this.#renderer);

		this.#bindGroupLayouts = [
			this.#frameBindGroup.getLayout(),
			this.#objectUniforms.getLayout(),
			this.#materialUniforms.getLayout()
		];

		const lineLayouts = [
			this.#frameBindGroup.getLayout(),
			this.#objectUniforms.getLayout(),
			this.#lineUniforms.getLayout()
		];
		this.#linePipeline.setLayouts(...lineLayouts);
		this.#linePipelineOverlays.setLayouts(...lineLayouts);

	}

	resize(w, h) {
		this.#createMainPass(w, h);
		this.#lineUniforms.setTargetSize(w, h);
	}

	#clearTarget(descriptor) {
		let commandEncoder = this.#device.createCommandEncoder();
		let passEncoder = commandEncoder.beginRenderPass(descriptor);
		passEncoder.end();
		this.#device.queue.submit([commandEncoder.finish()]);
	}

	clearMainTargets() {
		this.#clearTarget(this.#clearPassDescriptor);
	}

	clearOverlayTargets() {
		this.#clearTarget(this.#overlayPassDescriptorWithClear);
	}

	setGhostingBrightness(darker) {
		// Not implemented.
	}

	updatePixelScale(pixelsPerUnit, camera) {
		if (!this.#lineUniforms) return;
		this.#lineUniforms.updatePixelScale(pixelsPerUnit, camera);
		this.#lineUniforms.upload();
	}

	setLineStyleBuffer(buffer, width) {
		if (!this.#lineUniforms) return;
		this.#lineUniforms.setLineStyleBuffer(buffer, width);
		this.#lineUniforms.upload();
	}

	getIBL() { return this.#iblUniforms; }

	#beginRenderPass(commandEncoder, passDescriptor, renderBundle, modelId = -1, renderBatch = undefined) {

		const sceneStart = renderBatch ? renderBatch.start : 0;
		let passEncoder = commandEncoder.beginRenderPass(passDescriptor);

		let encoder = passEncoder;
		if (renderBundle) {
			if (!renderBundle.record) {
				return passEncoder;
			}
			encoder = renderBundle;
		}

		let objectUniformBindGroup = useNewUniforms ? this.#objectUniforms.getBindGroup(renderBatch) : this.#objectUniforms.getBindGroup(modelId, sceneStart);

		encoder.setBindGroup(0, this.#frameBindGroup.getBindGroup());
		encoder.setBindGroup(1, objectUniformBindGroup);
		encoder.setBindGroup(2, this.#materialUniforms.getBindGroup());

		return passEncoder;
	}

	/**
	 *
	 * @param {GPUCommandEncoder} commandEncoder
	 * @param {GPURenderPassDescriptor} passDescriptor
	 * @param {LinePipeline} pipeline
	 * @returns
	 */
	#beginRenderPass2D(commandEncoder, passDescriptor, pipeline) {
		let passEncoder = commandEncoder.beginRenderPass(passDescriptor);

		pipeline.setBindGroups(
			passEncoder,
			this.#frameBindGroup.getBindGroup(),
			this.#objectUniforms.getBindGroup(-1),
			this.#lineUniforms.getBindGroup()
		);

		return passEncoder;
	}

	#flushObjects(commandEncoder, count, submit = true) {
		let commandGroup = commandEncoder.finish();

		// Submit will be false for main scene rendering.
		// Uniforms are updated separately, and command groups are submitted at a higher level,
		// which allows to batch multiple groups into a single submit call for better performance.
		if (submit && count) {
			this.#objectUniforms.writeToQueue(count);
		}

		if (submit) {
			this.#renderer.getVB().flushWrites();
			this.#device.queue.submit([commandGroup]);
		} else {
			return commandGroup;
		}
	}

	beginScene(camera, lights) {
		this.#cameraUniforms.update(camera);
		if (this.#iblUniforms.update()) {
			this.#frameBindGroup.updateBindGroup();
			this.#renderer.invalidateRenderBundles();
		}
	}

	// Render callbacks (passed to forEachWebGPU) for the main pass.
	// 2d and overlay passes still have custom callbacks defined inline.
	//  @param {number} fragIndex - Order index of the fragment within the renderBatch.
	#renderBatchCallback(geometry, material, fragIndex, fragId) {
		geometry = this.#renderer.initGeometry(geometry);
		if (!geometry) {
			return true;
		}

		let materialTextureMask;
		if (!this.#useRenderBundles || this.#renderBundle.record) {

			// Usually, ObjectUniforms assumes to be preconfigured for a fixed iterator per model in a way that
			// that the index of a fragment within a model can be directly used to look up the fragment's uniforms.
			// This assumption doesn't work when overloading the BVHIterator with ConsolidatedRenderBatch.
			// Here, we get the index within the consolidated batch, but need the one within the original one.
			// Therefore, we use the fragment ID to lookup the original index.
			const index = this.#needsLookupForFragmentUniforms ? this.#objectUniforms.getObjectIndex(fragId) : fragIndex;

			// TODO: Recording these is only required for the edge pass, so we could skip it when edges are not enabled.
			const renderIndex = this.#objectUniforms.getRenderIndex(index);
			this.#geometriesList[this.#count] = geometry;
			this.#renderIndicesList[this.#count] = renderIndex;

			//TODO: Two pass transparency (if we want that) needs to draw with flipped culling first
			//then a second time with regular culling

			//It looks like we set the uniforms after the draw call here, but remember
			//that all these calls are just queuing commands that get issued when we
			//flush the command encoder, nothing is actually getting drawn yet.
			materialTextureMask = this.#mainPipeline.drawOne(this.#encoder, renderIndex, geometry, material);

			this.#objectUniforms.initMaterialUpdateHook(material, materialTextureMask);
			material.needsUpdate = false;

			this.#count++;
		}
	}

	#renderBatchCallbackGhosted(geometry, material, fragIndex, fragId) {
		geometry = this.#renderer.initGeometry(geometry);
		if (!geometry) {
			return true;
		}

		// see comment in #renderBatchCallback
		const index = this.#needsLookupForFragmentUniforms ? this.#objectUniforms.getObjectIndex(fragId) : fragIndex;

		const renderIndex = this.#objectUniforms.getRenderIndex(index);
		this.#edgePipeline.drawOneGhosted(this.#encoder, renderIndex, geometry, ghostMaterialSettings);
	}

	#threeSceneCallback(mesh) {
		const material = mesh.material;
		const geometry = this.#renderer.initGeometry(mesh.geometry);
		if (!geometry) {
			return true;
		}

		this.#geometriesList[this.#count] = geometry;
		this.#renderIndicesList[this.#count] = this.#count;

		//TODO: Two pass transparency (if we want that) needs to draw with flipped culling first
		//then a second time with regular culling

		//It looks like we set the uniforms after the draw call here, but remember
		//that all these calls are just queuing commands that get issued when we
		//flush the command encoder, nothing is actually getting drawn yet.
		const materialTextureMask = this.#mainPipeline.drawOne(this.#encoder, this.#count, geometry, material);

		const numInstances = geometry.numInstances;
		if (numInstances !== undefined) {
			this.#objectUniforms.setObjectDataFromInstanceBuffer(mesh, this.#count);
			this.#count += numInstances;
		} else {
			this.#objectUniforms.setOneObjectData(mesh, this.#count);
			this.#count++;
		}

		this.#objectUniforms.setOneMaterialData(material, materialTextureMask);
	}

	#threeSceneCallbackGhosted(mesh) {
		const geometry = this.#renderer.initGeometry(mesh.geometry);
		if (!geometry) {
			return true;
		}

		this.#objectUniforms.setOneObjectData(mesh, this.#count);

		this.#edgePipeline.drawOneGhosted(this.#encoder, this.#count, geometry, ghostMaterialSettings);
		this.#count++;
	}

	#createRenderBundle(descriptor) {
		const bundleEncoder = this.#device.createRenderBundleEncoder(descriptor);
		bundleEncoder.record = true;
		return bundleEncoder;
	}

	#finishRenderBundle(rBatch, index, passEncoder, renderBundle) {
		if (renderBundle) {
			let bundle = renderBundle;
			if (renderBundle.record) {
				bundle = renderBundle.finish();
				rBatch.setRenderBundle(index, bundle);
			}
			passEncoder.executeBundles([bundle]);
		}
	}

	renderScenePart( scene, showEdges ) {

		let rt = this.#renderer.getRenderTargets();
		rt.setIdTargetsDirty();

		let rBatch, modelId, isModelScene, callback, callbackGhosted;
		this.#useRenderBundles = false;
		let renderBundle, renderBundleEdges, renderBundleGhosted;

		// ConsolidatedRenderBatch is a special case for which an assumption in ObjectUniform isn't valid.
		// See #renderBatchCallback for details.
		const isConsolidated = (scene instanceof ConsolidatedRenderBatch);
		this.#needsLookupForFragmentUniforms = isConsolidated;

		if (!(scene instanceof RenderBatch)) {
			this.#rBatchShim.setFromScene(scene, this.#cameraUniforms.getViewProjectionMatrix())
			rBatch = this.#rBatchShim;

			modelId = -1;
			isModelScene = false;
			callback = this.#threeSceneCallback.bind(this);
			callbackGhosted = this.#threeSceneCallbackGhosted.bind(this);
		} else {
			rBatch = scene;
			modelId = rBatch.frags.modelId;
			isModelScene = true;
			callback = this.#renderBatchCallback.bind(this);
			callbackGhosted = this.#renderBatchCallbackGhosted.bind(this);
			this.#objectUniforms.resetUpdateHeuristic(modelId);

			// Consolidated render batch don't have an own uniform buffer, but rather wrapping the underlying RenderBatch from
			// the main model iterator (ModelIteratorBVH). Therefore, uniform updates must be done using the underlying source RenderBatch.
			const sourceBatch = isConsolidated ? rBatch.originalRenderBatch : rBatch;

			// Why not for 2D?:
			//  - Wouldn't work: objectUniforms.updateBatch() is only implemented for 3D.
			//  - Isn't needed:  Unlike 3D, 2D uniforms are currently updated dynamically per frame anyway (see #renderScenePart2D).
			if (sourceBatch.uniformsNeedUpdate && !rBatch.is2d()) {
				this.#objectUniforms.updateBatch(sourceBatch);
				sourceBatch.uniformsNeedUpdate = false;
			}

			this.#useRenderBundles = rBatch.useRenderBundles;
			this.#renderer.clearModelVisibilityDirty(modelId);
			if (this.#useRenderBundles) {
				renderBundle = rBatch.getRenderBundle(0);
				renderBundleEdges = rBatch.getRenderBundle(1);
				if (showEdges && renderBundle && !renderBundleEdges) {
					// The color render bundle has been recorded, but there's no edge render bundle yet
					// (edges might just have been enabled at runtime). We discard / re-record the existing color
					// render bundle, because we need to create the list of geometries for the edge pass anyway.
					renderBundle = null;
				}
				if (!showEdges && renderBundleEdges) {
					// A render bundle for edges has been recorded before, but edges are now disabled.
					// We discard the edge render bundle, to avoid using a stale one if edges are re-enabled later.
					rBatch.setRenderBundle(1, null);
					renderBundleEdges = null;
				}
				renderBundleGhosted = rBatch.getRenderBundle(2);
			}
		}

		if (rBatch.is2d()) {
			this.#renderScenePart2D(rBatch);
			return;
		}

		let targets = rt.getTargetsListMainPass();
		let edgeTargets = rt.getTargetsListEdgePass();

		this.#objectUniforms.setDoNotCutOverride(false);

		let startIndex = 0;
		let commandGroup;
		do {
			this.#count = 0;
			let commandEncoder = this.#device.createCommandEncoder();

			if (!scene.edgesOnly) {

				this.#objectUniforms.setEdgeColorInt(this.#edgeColorMainInt);

				if (this.#useRenderBundles) {
					if (!renderBundle) {
						renderBundle = this.#createRenderBundle(this.#renderBundleDescriptor);
					}
					this.#renderBundle = renderBundle;
				}

				//Main forward pass
				const passEncoder = this.#beginRenderPass(commandEncoder, this.#mainPassDescriptorProg, renderBundle, modelId, rBatch);

				this.#encoder = renderBundle ? renderBundle : passEncoder;

				this.#mainPipeline.reset(this.#bindGroupLayouts, targets);

				let endIndex = 0;
				if (!this.#useRenderBundles || renderBundle.record) {
					endIndex = rBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, callback);
				}

				this.#finishRenderBundle(rBatch, 0, passEncoder, renderBundle);

				passEncoder.end();

				//Draw edge pass if required
				if (showEdges) {

					//Main pass edges
					if (this.#useRenderBundles && !renderBundleEdges) {
						renderBundleEdges = this.#createRenderBundle(this.#renderBundleEdgesDescriptor);
					}

					const edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#edgePassDescriptor, renderBundleEdges, modelId, rBatch);

					this.#encoder = renderBundleEdges ? renderBundleEdges : edgePassEncoder;

					this.#edgePipeline.reset(this.#bindGroupLayouts, edgeTargets);

					if (!this.#useRenderBundles || renderBundleEdges.record) {
						for (let i=0; i<this.#count;) {
							const geom = this.#geometriesList[i];
							const numInstances = geom.numInstances ?? 1;
							this.#edgePipeline.drawOne(this.#encoder, this.#renderIndicesList[i], geom, edgeMaterialSettings);

							// For instanced meshes, drawOne actually draws multiple instances at once, each requiring
							// one slot in the uniform batch. So we need to increment the index accordingly.
							i += numInstances;
						}
					}

					this.#finishRenderBundle(rBatch, 1, edgePassEncoder, renderBundleEdges);

					edgePassEncoder.end();
				}

				commandGroup = this.#flushObjects(commandEncoder, this.#count, !isModelScene);

				startIndex = endIndex;

			} else {
				//Ghosted pass

				//This tricky bit plays along with the logic in RenderContext and RenderCommandSystem
				//Ghosting pass is edges only, but uses the override material.
				//Main pass edges use edgeMaterial, with color in its uniforms.
				//let overrideMaterial = scene.overrideMaterial;

				this.#objectUniforms.setEdgeColorInt(this.#edgeColorGhostedInt);

				//Ghosted pass (currently draws edges and lines in a "ghosted/stippled" effect
				if (this.#useRenderBundles && !renderBundleGhosted) {
					renderBundleGhosted = this.#createRenderBundle(this.#renderBundleEdgesDescriptor);
				}

				const edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#edgePassDescriptor, renderBundleGhosted, modelId, rBatch);

				this.#encoder = renderBundleGhosted ? renderBundleGhosted : edgePassEncoder;

				this.#edgePipeline.reset(this.#bindGroupLayouts, edgeTargets);

				this.#count = 0;

				let endIndex = 0;
				if (!this.#useRenderBundles || renderBundleGhosted.record) {
					endIndex = rBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, callbackGhosted);
				}

				this.#finishRenderBundle(rBatch, 2, edgePassEncoder, renderBundleGhosted);

				edgePassEncoder.end();

				commandGroup = this.#flushObjects(commandEncoder, this.#count, !isModelScene);
				startIndex = endIndex;
			}

		} while (startIndex > 0);

		this.#encoder = null;
		this.#renderBundle = null;

		return commandGroup;
	}

	#renderScenePart2D(scene, overrideMaterial, renderToOverlay = false) {

		let rt = this.#renderer.getRenderTargets();
		// Rendering to overlay doesn't affect the id targets.
		if (!renderToOverlay) {
			rt.setIdTargetsDirty();
		}

		let rBatch = scene;

		let targets = renderToOverlay ? rt.getOverlayTargetsList() : rt.getTargetsListMainPass();

		this.#objectUniforms.setDoNotCutOverride(false);

		let startIndex = 0;
		do {
			let count = 0;
			let commandEncoder = this.#device.createCommandEncoder({ label: '2d mainpass encoder' });

			const passDescriptor = renderToOverlay ? this.#overlayPassDescriptorNoClear : this.#mainPassDescriptorProg;

			// The targets setup is different for 2D in overlay passes. Therefore, we need a separate pipeline for this case,
			// because the target setup is assumed to be constant by LinePipeline.
			const linePipeline = renderToOverlay ? this.#linePipelineOverlays : this.#linePipeline;
			linePipeline.reset(targets);

			//Main forward pass
			let passEncoder = this.#beginRenderPass2D(
				commandEncoder, passDescriptor, linePipeline);

			let endIndex = rBatch.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, (mesh) => {
				const material = overrideMaterial || mesh.material;
				const geometry = this.#renderer.initGeometry(mesh.geometry);
				if (!geometry) {
					return true;
				}

				//TODO: update line uniforms per material here
				linePipeline.drawOne(passEncoder, count, geometry, material);

				this.#objectUniforms.setOneObjectData(mesh, count);
				this.#objectUniforms.setOneMaterialData2D(material, 0);

				count++;
			});

			passEncoder.end();

			this.#flushObjects(commandEncoder, count);
			startIndex = endIndex;

		} while (startIndex > 0);
	}

	renderOverlay( scene, camera, materialPre, materialPost, showEdges, customEdgeColor, lights ) {

		//TODO: is that really needed, given we always have a beginScene first?
		this.#cameraUniforms.update(camera);

		//NOTE: This logic renders the top side of the highlighted objects first,
		//and then the bottom side. The reason is that the top side material is opaque,
		//while we want to render the hidden parts of the object with faint transparency.
		//For objects that covers themselves and are also covered by other objects
		//this is a problem, since the opaque parts would prevent the back parts from showing.

		//However, edge rendering uses painter's algorithm settings for the depth,
		//since we don't care to show hidden edges from under top edges.

		// Note: We assume that this is only called for three scenes, i.e. actual overlays,
		// and NOT for RenderBatches that are part of an actual model.
		this.#rBatchShim.setFromScene(scene, this.#cameraUniforms.getViewProjectionMatrix());

		if (this.#rBatchShim.is2d()) {
			this.#renderScenePart2D(this.#rBatchShim, materialPre, true);
			return;
		}

		let rt = this.#renderer.getRenderTargets();
		let targets = rt.getOverlayTargetsList();

		this.#objectUniforms.setDoNotCutOverride(!this.#rBatchShim.needsCutPlanes());

		let startIndex = 0, endIndex;
		do {
			let count = 0;
			let commandEncoder = this.#device.createCommandEncoder();

			//Render top side of the object using the primary highlight material
			//or the overlay object's own material
			let overrideMaterial;
			if (materialPre) {
				overrideMaterial = materialPre;
			}

			this.#objectUniforms.setEdgeColorInt(vectorToABGR(customEdgeColor || EDGE_COLOR_HIGHLIGHT_UNDER));

			let passEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

			this.#overlayPipeline.reset(this.#bindGroupLayouts, targets);

			endIndex = this.#rBatchShim.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, (mesh) => {
				const objMaterial = mesh.material;
				const geometry = this.#renderer.initGeometry(mesh.geometry);
				if (!geometry) {
					return true;
				}

				this.#geometriesList[count] = geometry;

				const material = overrideMaterial || objMaterial;

				const isInstanced  = (geometry.numInstances !== undefined);
				const numInstances = geometry.numInstances ?? 1;

				//It looks like we set the uniforms after the draw call here, but remember
				//that all these calls are just queuing commands that get issued when we
				//flush the command encoder, nothing is actually getting drawn yet.
				const materialTextureMask = this.#overlayPipeline.drawOne(passEncoder, count, geometry, material);

				//The uniforms set here are also used for the edges pass below
				mesh.material = material; // make sure to set the correct material reference

				if (isInstanced) {
					// For instanced meshes, drawOne above actually draws multiple instances at once, each requiring
					// one slot in the uniform batch. So we need to increment the index accordingly.
					this.#objectUniforms.setObjectDataFromInstanceBuffer(mesh, count);
					count += numInstances;
				} else {
					this.#objectUniforms.setOneObjectData(mesh, count);
					count++;
				}

				this.#objectUniforms.setOneMaterialData(material, materialTextureMask);
			});

			passEncoder.end();


			if (materialPost) {

				if (showEdges) {

					let edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

					this.#edgePipeline.reset(this.#bindGroupLayouts, targets);

					for (let i=0; i<count; i++) {
						this.#edgePipeline.drawOne(edgePassEncoder, i, this.#geometriesList[i], edgeHighlightMaterialUnder);
					}

					edgePassEncoder.end();
				}

				//We need to flush the rendering pipe here, because the material settings are encoded
				//into the per-object uniforms (even though the same material is used for all objects in the scene)
				//This can be optimized by using two set of object uniforms buffers, or by using a pipeline
				//other than the uber shader pipeline, that can draw all objects with a fixed material
				this.#flushObjects(commandEncoder, count);

				commandEncoder = this.#device.createCommandEncoder();

				//Render bottom side of the object
				//for selection that's done using light transparency to show
				//areas the object spans under other objects
				{
					materialPost.depthFunc = "greater";
					materialPost.needsUpdate = true;
					this.#objectUniforms.setEdgeColorInt(vectorToABGR(customEdgeColor || EDGE_COLOR_HIGHLIGHT));

					let passEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

					this.#overlayPipeline.reset(this.#bindGroupLayouts, targets);

					let count3 = 0;
					const material = materialPost;

					// TODO: This can be optimized away. The list of geometries to render has already been recorded
					// above. All we really need to do here is iterate over all indices and set the material reference.
					endIndex = this.#rBatchShim.forEachWGPU(startIndex, this.#objectUniforms.MAX_BATCH, (mesh) => {
						const geometry = this.#renderer.initGeometry(mesh.geometry);
						if (!geometry) {
							return true;
						}

						const materialTextureMask = this.#overlayPipeline.drawOne(
							passEncoder, count3, geometry, material);

						//The uniforms set here are also used for the edges pass below
						this.#objectUniforms.setMaterialReference(count3 * this.#objectUniforms.OBJECT_STRIDE_32, material);
						this.#objectUniforms.setOneMaterialData(material, materialTextureMask);
						count3++;
					});

					passEncoder.end();

				}

			}

			//Finally render top side edges last
			if (showEdges) {

				let edgePassEncoder = this.#beginRenderPass(commandEncoder, this.#overlayPassDescriptorNoClear);

				this.#edgePipeline.reset(this.#bindGroupLayouts, targets);

				for (let i=0; i<count; i++) {
					this.#edgePipeline.drawOne(edgePassEncoder, i, this.#geometriesList[i], edgeHighlightMaterialOver);
				}

				edgePassEncoder.end();
			}

			this.#flushObjects(commandEncoder, count);

			startIndex = endIndex;

		} while (startIndex > 0);
	}

}
