import { OutOfCoreTaskBase } from "./OutOfCoreTaskBase";

/** @import { OutOfCoreTileManager } from "../OutOfCoreTileManager" */

/**
 * A task that executes a generic callback
 */
export class CallbackTask extends OutOfCoreTaskBase {

    /**
     * 
     * @param {OutOfCoreTileManager} outOfCoreTileManager The OutOfCoreTileManager instance
     * @param {*} callback The callback to be executed
     */
    constructor(outOfCoreTileManager, callback) {
        super(outOfCoreTileManager);

        this.callback = callback;
    }

    execute() {
        const result = this.callback();
        // make sure a memory cost was returned, otherwise return zero as fallback
        return typeof result === "number" ? result : 0;
    }
}