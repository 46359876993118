import { USE_HLOD, USE_OUT_OF_CORE_TILE_MANAGER, ENABLE_PIXEL_CULLING } from "../globals";
import { FrustumIntersector } from './FrustumIntersector';
import * as THREE from "three";
import { BVHModule, BVH } from "./BVHBuilder";
import { OutOfCoreTileManager } from './out-of-core-tile-manager/OutOfCoreTileManager.js';

/** @import { NodeArray } from "../../wgs/scene/BVHBuilder" */
/** @import { RenderModel } from "./RenderModel" */
/** @import { FragmentList } from "../scene/FragmentList" */

/*
 * Keep these threeJs objects file local for performance reasons,
 * as these are changed frequently, so we keep expensive object creation minimal.
 */
const tmpBox = new THREE.Box3();
const tmpBox2 = new THREE.Box3();
const tmpSphere = new THREE.Sphere();
const tmpVec = new THREE.Vector3();
const sqrt3 = Math.sqrt(3);

export class ModelIteratorBVH
{
    /** @type {?FragmentList} */ #frags;

    // Nodes in the BVH, in an array for easy access to all of them.
    // There are up to two trees, one for opaques, one for transparent objects.
    // These are normally listed top-down, in a flattened list, e.g., if all the objects
    // in the scene were transparent, _bvhNodes[0] = 0, and the 0 node would have not
    // children and no primitives, as this node would contain all the opaque fragments,
    // of which there are none. The transparent tree is always in _bvhNodes[1], and might
    // look something like this:
    //     1
    //  2     3
    // 4 5   6 7
    // with the children 4-7 each containing a RenderBatch of some number of fragments. Note
    // that inner nodes can also have RenderBatches.                
    /** @type {?NodeArray} */ #bvhNodes;

    // There's indirection for each RenderBatch. A RenderBatch contains a number of fragments.
    // Rather than an array per RenderBatch, a single array is accessed by all RenderBatches.
    // The primitives are in a list sorted by surface area. We preserve this. In this
    // _bvhFragOrder array we by a flattened list of children fragment indices. So child 4,
    // above, might have 3 objects, and their indices might be [291 12 55].
    // primStart and primCount access this array.
    // Also see bvh_partition and the comment there.
    /** @type {?Int32Array} */#bvhFragOrder;

    // _bvhRenderBatches is a sparse array of RenderBatches, each RenderBatch has a few fragments.
    // Only those elements in the array that have a RenderBatch are defined.
    #bvhRenderBatches;

    // What is the containment state of this node, if known? Is either CONTAINMENT_UNKNOWN
    // or INTERSECTS or CONTAINS. If CONTAINS, we don't have to run the frustum cull
    // test, saving a few percent in speed.
    #bvhContainment;

    #bvhNodeQueue;
    #bvhNodeScreenspaceErrors;
    #bvhHead;
    #bvhTail;
    #bvhLIFO;
    #bvhPrioritizeScreenSize;
    #bvhOpaqueDone;

    /** @type {OutOfCoreTileManager|undefined} */ #outOfCoreTileManager;

    // true if skipOpaqueShapes has been called in the current traversal.
    #bvhOpaqueSkipped;

    // true if nodes from transparent tree should be ignored for rendering.
    #bvhIgnoreTransparency;

    #frustum;
    #done;
    #RenderBatch;
    #resetVisStatus;
    /** @type {?RenderModel} */ #renderModelLinear;
    #options;
    
    /** @type {?number} */ #modelIteratorId;

    /** This factor is multiplied to the screen space error that is reported to the BVH iterator. At the moment, we use
     *  only the viewport size as scaling factor */
    #screenSpaceErrorScalingFactor = 1.0;

    constructor() {
        this.#frags = null;
        this.#bvhNodes = null;
        this.#bvhFragOrder = null;
        this.#bvhRenderBatches = null;
        this.#bvhContainment = null;
        this.#bvhNodeQueue = null;
        this.#bvhNodeScreenspaceErrors = null;
        this.#bvhHead = 0;
        this.#bvhTail = 0;
        this.#bvhLIFO = 1;
        this.#bvhPrioritizeScreenSize = true;
        this.#bvhOpaqueDone = false;
        this.#bvhOpaqueSkipped = false;
        this.#bvhIgnoreTransparency = false;
        this.#frustum = null;
        this.#done = false;
        this.#RenderBatch = null;
        this.#resetVisStatus = true;
        this.#renderModelLinear = null;
        this.#options = null;
        this.#modelIteratorId = null;
    }

    /**
     * Initializes the model iterator with the given parameters.
     * @param {RenderModel} renderModelLinear - The render model for which this iterator is being created
     * @param {BVH} bvh
     */
    initialize(renderModelLinear, bvh) {
        this.#renderModelLinear = renderModelLinear;
        this.#options = bvh.options;
        this.#frags = renderModelLinear.getFragmentList();
        // Take the RenderBatch class from the model, so on demand loading can
        // use a different class to handle redraws properly
        this.#RenderBatch = renderModelLinear.RenderBatch;

        if (this.#options && this.#options.hasOwnProperty("prioritize_screen_size")) {
            this.#bvhPrioritizeScreenSize = this.#options.prioritize_screen_size;
        }

        this.#bvhFragOrder = bvh.primitives;
        const nodes = bvh.nodes;
        this.#bvhNodes = nodes;
        this.#bvhRenderBatches = new Array(nodes.nodeCount);
        this.#bvhContainment = new Int8Array(nodes.nodeCount);
        this.#bvhNodeQueue = new Int32Array(nodes.nodeCount + 1);
        this.#bvhNodeScreenspaceErrors = new Float32Array(nodes.nodeCount);

        if (USE_OUT_OF_CORE_TILE_MANAGER && !this.#options.raycasting_only) {
            [this.#outOfCoreTileManager, this.#modelIteratorId] = OutOfCoreTileManager.getManagerForIterator(this, renderModelLinear)
        }

        const enableLargestFragmentBoxCulling =  USE_OUT_OF_CORE_TILE_MANAGER && ENABLE_PIXEL_CULLING && this.#frags.boxes;
        for (let i = 0; i < nodes.nodeCount; i++) {
            // does this node have real objects in it?
            const primCount = nodes.getPrimCount(i);
            if (primCount === 0 ) {
                continue;
            }

            let largestFragmentId = bvh.largestFragmentOffsets?.[i];
            let transparent = !!(nodes.getFlags(i) & 8);
            this.#bvhRenderBatches[i] = new this.#RenderBatch(this, this.#frags, nodes.getPrimStart(i), primCount, undefined, largestFragmentId, transparent, enableLargestFragmentBoxCulling );

            const currentRenderBatch = this.#bvhRenderBatches[i];

            // These are set manually, because we will not be adding fragments to the
            // render batch one by one -- the fragments are already loaded.
            currentRenderBatch.lastItem = currentRenderBatch.start + primCount;
            currentRenderBatch.numAdded = primCount;
            currentRenderBatch.numAddedTotal = primCount;
            currentRenderBatch.nodeIndex = i;

            if (nodes.getFlags(i) & 8) {
                currentRenderBatch.sortObjects = true; //scene contains transparent objects
            }

            nodes.getBoxArray(i, currentRenderBatch.bboxes);
        }
    };

    dtor() {
        this.#RenderBatch = null;
        this.#frags = null;
        this.#renderModelLinear = null;

        this.#outOfCoreTileManager?.releaseIterator(this.#modelIteratorId);
    };

    /**
     * Reset the iterator for the given frustum and camera.
     * @param {FrustumIntersector} frustum - The frustum
     * @param {THREE.Camera} camera - The camera 
     */
    reset(frustum, camera) {
        this.#frustum = frustum;
        
        // We scale the errors from the BVH iterator by the projection scale of the frustum
        // to take the size of the viewport into account when comparing the errors from different
        // viewports with each other in the OutOfCoreTileManager.
        // TODO: How should we handle the case when the camera is orthographic? We don't compute 
        // a real orthographic screen space error, but divide by distance also in orthographic
        // views, because we consider closer objects more important, even if they do not occupy more
        // pixels. However, this poses a problem when comparing errors from orthographic and perspective
        // views because the errors for the orthographic view do not correspond to pixel sizes at all.
        // The projection factor used here, only gives the correct result for objects at a distance of 1
        // from the camera. We can use a different reference distance for orthographic views by scaling the error,
        // but it is unclear which reference distance we should use for this.
        this.#screenSpaceErrorScalingFactor = frustum.projScale;

        this.#bvhHead = 0;
        this.#bvhTail = 0;

        // means "unknown containment state"
        this.#bvhContainment[0] = this.#bvhContainment[1] = FrustumIntersector.CONTAINMENT_UNKNOWN;

        // prime the pump: the first entry is set to BVH node 0,
        // which is the first node in the first hierarchy (the opaque one) that we'll examine.
        // The ++ here is just for consistency; we could have set tail to 1
        // and used 0 as the index. _bvhTail will immediately get decremented to 0 by nextBatch;
        // it's incremented here to initially pass the while() loop there.
        this.#bvhNodeQueue[this.#bvhTail++] = 0;

        this.#bvhOpaqueDone = false;
        this.#bvhOpaqueSkipped = false;
        this.#done = false;
        
        if (this.#resetVisStatus) {	
          let scenes = this.#bvhRenderBatches;	
          let len = scenes.length;	
          for (let i = 0; i < len; ++i) {	
              let scene = scenes[i];	
              if (scene && scene.resetVisStatus) {	
                  scene.resetVisStatus();	
              }
          }
          this.#resetVisStatus = false;
        }

        // The out of core manager maintains a counter for the current frame (full frame, not each individual pr
        // progressive frame) to determine whether an update of the screen space error is still valid. We increment
        // this counter here every time the traversal is being reset.
        this.#outOfCoreTileManager?.incrementFrameCount(this.#modelIteratorId);

        // The root nodes (for opaques and transparent) are never added to the processing queue
        // and we never compute their screen area below. Therefore, we trigger the update already
        // here.
        if (USE_HLOD) {
            this.updateNodeArea(0);
            this.updateTransparentNodeArea(1);
        }
    };

    // Used to insert nodes into the (sorted) render queue based on
    // a heuristic other than strict front to back or back to front order.
    // Currently we always use this for sorting by screen area.
    #insertNode(idx) {
        //This is basically a single sub-loop of an insertion sort.
        const val = this.#bvhNodeScreenspaceErrors[idx];
        let j = this.#bvhTail;

        if (this.#bvhLIFO) {
            // For LIFO we insert the largest at the end of the list, since they
            // are the first to be popped
            while (j > this.#bvhHead && this.#bvhNodeScreenspaceErrors[this.#bvhNodeQueue[j - 1]] > val) {
                this.#bvhNodeQueue[j] = this.#bvhNodeQueue[j - 1];
                j--;
            }
        } else {
            // For FIFO we insert the largest at the front of the list.
            while (j > this.#bvhHead && this.#bvhNodeScreenspaceErrors[this.#bvhNodeQueue[j - 1]] < val) {
                this.#bvhNodeQueue[j] = this.#bvhNodeQueue[j - 1];
                j--;
            }
        }

        this.#bvhNodeQueue[j] = idx;
        this.#bvhTail++;
    };

    nextBatch() {
        if (!this.#bvhOpaqueSkipped && !this.#bvhOpaqueDone && this.#bvhHead === this.#bvhTail) {
            // In case we want to skip all nodes in the transparent subtree.
            if (!this.#bvhIgnoreTransparency) {
            //If we are done with the opaque nodes, queue the transparent ones
            //before processing the contents of the last opaque node
            this.#bvhNodeQueue[this.#bvhTail++] = 1; //root of transparent subtree is at index 1
            }
            this.#bvhOpaqueDone = true;
        }

        // _bvhHead and _bvhTail are indices into the BVH node list. For the opaque objects
        // these start at 0 and 1, respectively. The idea here is to work through the bounding
        // volume hierarchy, with inner nodes sorted into the list by large-to-small screen area
        // (or front-to-back, or back-to-front) order as we go. The way this loop ends is when
        // nothing is on the BVH node stack, or a render batch of stuff to render is found.
        // The next time this method is called, the current _bvhHead and _bvhTail values pick
        // up where they left off, continuing to traverse the tree, until another batch is found
        // or the stack (list) is emptied.
        // Note: this is a breadth-first traversal, but render batches can and do get returned
        // before the whole tree is traversed, because these can be found in inner nodes.
        // This means that there may be nodes with larger screen areas that come later on.
        while (this.#bvhHead !== this.#bvhTail) {
            // Retrieve node index for what to process in the BVH. _bvhNodeQueue contains the indices
            // of the node(s) in the BVH that are to be processed.
            // For LIFO, for example, when the nodeIdx is first retrieved, _bvhTail initially
            // goes to 0, and so grabs the index at location 0 in _bvhNodeQueue, typically the top of
            // the opaque tree. The rest of this loop may add to this queue, and/or return fragments to
            // render, in which case it exits. If nothing got returned (yet) and the loop continues,
            // the next time around through this loop, the last
            // BVH node put on this _bvhNodeQueue stack (if LIFO is true) is retrieved (if not LIFO,
            // the first object on the list is retrieved and _bvhHead is incremented).
            // Inner nodes will add their two children in proper order to _bvhNodeQueue and increment _bvhTail, twice.
            const nodeIdx = (this.#bvhLIFO || this.#bvhOpaqueDone) ? this.#bvhNodeQueue[--this.#bvhTail] : this.#bvhNodeQueue[this.#bvhHead++];

            // Is box already found to be contained? This happens when a box's parent is fully contained.
            // We can then avoid the frustum test.
            let intersects = this.#bvhContainment[nodeIdx];
            if (intersects !== FrustumIntersector.CONTAINS) {
                // could be outside or intersecting, so do test
                this.#bvhNodes.getBoxThree(nodeIdx, tmpBox);
                intersects = this.#frustum.intersectsBox(tmpBox);
            }

            // Node is entirely outside, go on to the next node
            if (intersects !== FrustumIntersector.OUTSIDE) {
                const child = this.#bvhNodes.getLeftChild(nodeIdx);
                let isInner = child !== -1;
                let firstIdx, secondIdx;

                // Is it inner node? Add children for processing.
                if (isInner) {
                    const flags       = this.#bvhNodes.getFlags(nodeIdx);
                    const reverseAxis = this.#frustum.viewDir[flags & 3] < 0 ? 1 : 0;
                    let firstChild    = (flags >> 2) & 1;
                    let transparent   = (flags >> 3) & 1;
                    const depthFirst  = (this.#bvhLIFO || this.#bvhOpaqueDone) ? 1 : 0;
                    let areaFirst     = 0;
                    let areaSecond    = 0;

                    // For opaque objects, use the screen size to sort the two children,
                    // or front to back order (back to front for transparent objects).
                    if (this.#bvhPrioritizeScreenSize && !this.#bvhOpaqueDone) {
                        // If traversing based on visible screen area, we have to compute the area for each child
                        // and insert them into the queue accordingly.
                        firstIdx = child + firstChild;
                        secondIdx = child + 1 - firstChild;

                        // When using HLOD (without out of core) we currently use hlod traversal with the initial unconsolidated BVH. Its bvh options doesn't 
                        // have the sortFuncType and therefore falls back to the box area one.
                        // By checking for this flag, we avoid using the hlod traversal for non-hlod bvhs
                        if (USE_HLOD && this.#options.sortFuncType) {
                            areaFirst = this.updateNodeArea(firstIdx);
                            areaSecond = this.updateNodeArea(secondIdx);
                        } else {
                            this.#bvhNodes.getBoxThree(firstIdx, tmpBox);
                            this.#bvhNodeScreenspaceErrors[firstIdx] = areaFirst = this.#frustum.projectedBoxArea(tmpBox, intersects === FrustumIntersector.CONTAINS);
                            this.#bvhNodes.getBoxThree(secondIdx, tmpBox);
                            this.#bvhNodeScreenspaceErrors[secondIdx] = areaSecond = this.#frustum.projectedBoxArea(tmpBox, intersects === FrustumIntersector.CONTAINS);
                        }

                        // "worst case" containment is recorded for later examination.
                        this.#bvhContainment[firstIdx] = this.#bvhContainment[secondIdx] = intersects;

                        // Insert each node in the right place based on screen area,
                        // so that the queue (or stack, if LIFO traversal) is kept sorted at every step of the way.
                        // Note that with LIFO, for example, the larger object is put last on the list (a stack),
                        // since we want to pop this one off first.
                        if (areaFirst > 0) {
                            this.#insertNode(firstIdx);
                        }

                        if (areaSecond > 0) {
                            this.#insertNode(secondIdx);
                        }
                    } else {
                        // Traversal by view direction.
                        // Reverse order if looking in the negative of the child split axis.
                        // Reverse order if we are traversing last first.
                        // If node contains transparent objects, then reverse the result so we traverse back to front.
                        // In other words, reverse the order if an odd number of flags are true.
                        if (reverseAxis ^ depthFirst ^ transparent) {
                            firstChild = 1 - firstChild;
                        }

                        firstIdx = child + firstChild;
                        secondIdx = child + 1 - firstChild;

                        this.#bvhNodeQueue[this.#bvhTail++] = firstIdx;
                        this.#bvhNodeQueue[this.#bvhTail++] = secondIdx;

                        // "worst case" containment is recorded for later examination.
                        this.#bvhContainment[firstIdx] = this.#bvhContainment[secondIdx] = intersects;

                        // Update the cost for transparent nodes. At the moment, we only use the distance to the
                        // camera to determine the cost of the node, because nodes are rendered in back-to-front order
                        // and thus distant nodes are more important.
                        if (this.#outOfCoreTileManager) {
                            this.updateTransparentNodeArea(firstIdx);
                            this.updateTransparentNodeArea(secondIdx);
                        } else {
                            this.#bvhNodeScreenspaceErrors[firstIdx] = -1;
                            this.#bvhNodeScreenspaceErrors[secondIdx] = -1;
                        }
                    }
                }

                // Are there graphics in the node? Then return its scene, i.e. its RenderBatch.
                // Inner nodes with children can and do have render batches of their own.
                // This works against a pure screen=area or front-to-back ordering, as
                // these fragments will always get returned first, before further traversal of the tree.
                const prims = this.#bvhNodes.getPrimCount(nodeIdx);
                if (prims !== 0) {
                    const renderBatch = this.#bvhRenderBatches[nodeIdx];

                    if (USE_OUT_OF_CORE_TILE_MANAGER) {
                        // If the out of core tile manager is used, the nodes will be sorted based on the the node area
                        // and we should use the same measure for the render importance that is used to decide which
                        // batch to use next when doing multi-model rendering.
                        renderBatch.renderImportance = this.#bvhNodeScreenspaceErrors[nodeIdx];
                    } else {
                        renderBatch.renderImportance = this.#frustum.projectedBoxArea(renderBatch.getBoundingBox(), intersects === FrustumIntersector.CONTAINS);
                    }

                    // Frustum culling for the RenderBatch is done in RenderBatch.applyVisibility,
                    // so we don't need it here.
                    // Just returning the batch and it will get cull checked later.
                    return renderBatch;
                }
            }

            if (!this.#bvhOpaqueDone && !this.#bvhOpaqueSkipped && this.#bvhHead === this.#bvhTail) {
                // In case we want to skip all nodes in the transparent subtree.
                if (!this.#bvhIgnoreTransparency) {
                    // If we are done with the opaque nodes, queue the transparent ones
                    // before processing the contents of the last opaque node
                    this.#bvhNodeQueue[this.#bvhTail++] = 1; // root of transparent subtree is at index 1
                }
                this.#bvhOpaqueDone = true;
            }
        }

        this.#done = true;
        return null;
    };

    /**
     * Updates the node area for a transparent node
     * @param {number} index - Index of the node
     * @returns {number} - area of the node
     */
    updateTransparentNodeArea(index) {
        this.#bvhNodes.getBoxThree(index, tmpBox);
        let distance = tmpBox.getBoundingSphere().center.distanceTo(this.#frustum.eye);
        
        // We use a negative cost for transparent node to make sure they come after all opaque nodes
        // in the queue. The cost is the inverse of the distance to the camera, because we want to render
        // transparent nodes in back-to-front order.
        this.#bvhNodeScreenspaceErrors[index] = -(1 / distance);

        this.#outOfCoreTileManager?.updateBvhNodeScreenSpaceError(this.#modelIteratorId, index,
            this.#screenSpaceErrorScalingFactor * distance);

        return distance;
    }

    /**
     * Updates the node area for an opaque node
     * @param {number} index - Index of the node
     * @returns {number} - area of the node or -1 if area could not be computed
     */
    updateNodeArea(index) {
        let area;
        let renderBatch = this.#bvhRenderBatches[index];

        // Skip empty nodes that don't have a render batch
        if (!renderBatch) {
            return -1;
        }


        if (this.#options.sortFuncType === Autodesk.Viewing.Private.BVHSortFuncType.DIAMETER) {
            // Use the bounding sphere radius of the largest fragment in RenderBatch divided by the distance to the RenderBatch bounding box
            const largestFragRadius = this.#bvhNodes.getLargestPrimitiveSize(index);
            area = largestFragRadius;

            if (this.#frustum.perspective) {
                // Get bounding box shrunken by the radius of the largest object in the RenderBatch
                // (we have to scale the radius by sqrt(3), because the radius for the bounding box is 
                // computed as the diagonal of the box, whereas the the expansion value is subtracted 
                // from each of the three coordinate axes separately)
                renderBatch.getBoundingBox(tmpBox);
                const boxShrinkage = -largestFragRadius / sqrt3;
                tmpBox.expandByVector(tmpVec.set(boxShrinkage, boxShrinkage, boxShrinkage));

                // The radius can be larger than the size of the bounding box axis, so we reset those axis to the center value
                if (tmpBox.max.x < tmpBox.min.x) tmpBox.max.x = tmpBox.min.x = (tmpBox.max.x + tmpBox.min.x) * 0.5;
                if (tmpBox.max.y < tmpBox.min.y) tmpBox.max.y = tmpBox.min.y = (tmpBox.max.y + tmpBox.min.y) * 0.5;
                if (tmpBox.max.z < tmpBox.min.z) tmpBox.max.z = tmpBox.min.z = (tmpBox.max.z + tmpBox.min.z) * 0.5;

                // We clamp the distance to still order by largest object size if we are inside the bounding box
                area = largestFragRadius / Math.max(1, tmpBox.distanceToPoint(this.#frustum.eye));
            }
        } else {
            let renderBatch = this.#bvhRenderBatches[index];
            // Use the bounding box area of the largest fragment in RenderBatch divided by the squared distance to the RenderBatch bounding box
            area = this.#bvhNodes.getLargestPrimitiveSize(index);
            
            if (this.#frustum.perspective) {
                renderBatch.getBoundingBox(tmpBox);
                let distSquared = tmpBox.distanceToPoint(this.#frustum.eye);
                distSquared *= distSquared;
                area = area / Math.max(1, distSquared);
            }
        }

        this.#bvhNodeScreenspaceErrors[index] = area;

        // This code path only updates the cost of the node in the out of core tile manager for
        // opaque nodes. Transparent nodes are treated separately in the nextBatch method.
        this.#outOfCoreTileManager?.updateBvhNodeScreenSpaceError(this.#modelIteratorId, index, 
                                                      this.#screenSpaceErrorScalingFactor * area);
        return area;
    }

    skipOpaqueShapes() {
        if (!this.#bvhOpaqueDone && !this.#bvhOpaqueSkipped) {
            // start traversal of transparent hierarchy
            this.#bvhHead = 0;
            this.#bvhTail = 0;
            this.#bvhNodeQueue[this.#bvhTail++] = 1; // root of transparent subtree is at index 1
            this.#bvhOpaqueSkipped = true;
        }
    };

    setIgnoreTransparency(ignore) {
        this.#bvhIgnoreTransparency = ignore;
    }

    #updateBVHRec(nodeIdx) {
        const child = this.#bvhNodes.getLeftChild(nodeIdx);
        if (child !== -1) {
            this.#updateBVHRec(child);
            this.#updateBVHRec(child+1);
        }

        tmpBox.makeEmpty();

        if (child !== -1) {
            this.#bvhNodes.getBoxThree(child, tmpBox2);
            tmpBox.union(tmpBox2);

            this.#bvhNodes.getBoxThree(child+1, tmpBox2);
            tmpBox.union(tmpBox2);
        }

        const prims = this.#bvhNodes.getPrimCount(nodeIdx);
        if (prims) {
            tmpBox.union(this.#bvhRenderBatches[nodeIdx].getBoundingBox());
            tmpBox.union(this.#bvhRenderBatches[nodeIdx].getBoundingBoxHidden());
        }

        this.#bvhNodes.setBoxThree(nodeIdx, tmpBox);
    };

    getVisibleBounds(visibleBounds, visibleBoundsWithHidden) {
        for (let i=0; i<this.#bvhRenderBatches.length; i++) {
            let s = this.#bvhRenderBatches[i];

            if (!s) {
                continue;
            }

            s.calculateBounds();

            let bb = s.getBoundingBox();
            visibleBounds.union(bb);

            visibleBoundsWithHidden.union(bb);
            visibleBoundsWithHidden.union(s.getBoundingBoxHidden());
        }

        //Also update all bounding volume tree nodes' bounds.
        //If objects move too much this will make the BVH less effective.
        //However, this only happens during explode or animation, so it shouldn't
        //be an issue. We can always rebuild the BVH in case objects really move a lot.
        this.#updateBVHRec(0); //opaque root
        this.#updateBVHRec(1); //transparent root
    };

    rayCast(raycaster, intersectsOut, dbIdFilter, options = {}) {
        const rayOrigin = raycaster.ray.origin;
        let nodeStack   = [1, 0];
        let pt          = new THREE.Vector3();
        let nodeBBox    = new THREE.Box3();

        while (nodeStack.length > 0) {
            const nodeIdx = nodeStack.pop();

            this.#bvhNodes.getBoxThree(nodeIdx, nodeBBox);
            nodeBBox.expandByScalar(0.5); // Expand bounding box a bit, to take into account axis aligned lines

            if (options.maxDistance != undefined) {
                const distanceToPoint = nodeBBox.distanceToPoint(rayOrigin);
                if (distanceToPoint > options.maxDistance) {
                    continue;
                }
            }

            const intersectionPoint = raycaster.ray.intersectBox(nodeBBox, pt);
            if (intersectionPoint === null) {
                continue;
            }

            const child = this.#bvhNodes.getLeftChild(nodeIdx);
            if (child !== -1) {
                nodeStack.push(child);
                nodeStack.push(child + 1);
            }

            const prims = this.#bvhNodes.getPrimCount(nodeIdx);
            if (prims !== 0) {
                const scene = this.#bvhRenderBatches[nodeIdx];
                scene.raycast(raycaster, intersectsOut, dbIdFilter, options);
            }
        }
    };

    intersectFrustum(frustumIntersector, callback) {
        let nodeStack = [1, FrustumIntersector.CONTAINMENT_UNKNOWN, 0, FrustumIntersector.CONTAINMENT_UNKNOWN];

        while (nodeStack.length) {

            const parentIntersectionState = nodeStack.pop();
            const nodeIdx = nodeStack.pop();

            //Check if current BVH node intersects the frustum. Take into account
            //the intersection state of the parent node, in case we can short-circuit the frustum check
            //when containment is known.
            let result;
            if (parentIntersectionState === FrustumIntersector.CONTAINS) {
                result = FrustumIntersector.CONTAINS;
            } else {
                this.#bvhNodes.getBoxThree(nodeIdx, tmpBox);
                result = frustumIntersector.intersectsBox(tmpBox);
            }

            if (result === FrustumIntersector.OUTSIDE) {
                continue;
            }

            const child = this.#bvhNodes.getLeftChild(nodeIdx);
            if (child !== -1) {
                nodeStack.push(child);
                nodeStack.push(result);

                nodeStack.push(child + 1);
                nodeStack.push(result);
            }

            const prims = this.#bvhNodes.getPrimCount(nodeIdx);
            if (prims !== 0) {
                let scene = this.#bvhRenderBatches[nodeIdx];
                scene && scene.intersectFrustum(frustumIntersector, callback, result === FrustumIntersector.CONTAINS);
            }
        }
    };

    getSceneCount() {
        return this.#bvhRenderBatches.length;
    };

    getGeomScenes() {
        return this.#bvhRenderBatches;
    };

    getOutOfCoreTileManager() {
        return this.#outOfCoreTileManager;
    }

    getBVH() {
        return new BVH(this.#bvhNodes.nodesRaw, this.#bvhNodes.is_lean_node, this.#bvhFragOrder, this.#options);
    };

    getFragOrder() {
        return this.#bvhFragOrder;
    };

    done() {
        // not supported (yet)
        // Seems to be needed for search in gui/controls only. let's check if this information is required to be public.
        return this.#done;
    };

    resetVisStatus() {
        this.#resetVisStatus = true;
    };

    clone() {
        const clone = new ModelIteratorBVH();

        // When this iterator is cloned for use with another LeechViewer the index list needs to be copied
        // as each viewer can sort it in different ways when it renders.
        const fragOrderCopy = new Int32Array(this.#bvhFragOrder);
        const bvhCopy = new BVH(this.#bvhNodes.nodesRaw, this.#bvhNodes.is_lean_node, fragOrderCopy, this.#options);

        clone.initialize(this.#renderModelLinear, bvhCopy);
        return clone;
    };

    /**
     * Return the ID which identifies the model iterator in the out of core tile manager.
     * @return {number} the id
     */
    getIteratorOutOfCoreManagerId() {
        return this.#modelIteratorId;
    }

    /**
     * Returns the id of the largest fragment for the given node.
     * @param {number} nodeIdx - The index of the node
     * @returns {number} The id of the largest fragment
     */
    getLargestFragIdForNode(nodeIdx) {
        return this.#bvhRenderBatches[nodeIdx]?.getLargestFragId() ?? -1;
    }

    /**
     * Is the node transparent?
     * @param {number} nodeIdx 
     * @returns {boolean}
     */
    isNodeTransparent(nodeIdx) {
        const flags = this.#bvhNodes.getFlags(nodeIdx);
        return (flags & 8) !== 0;
    }
};
