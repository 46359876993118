import { OBJECT_STRIDE } from "./ObjectUniforms";

// Constant used in lookup table to indicate that a fragment is not on GPU.
const NotOnGPU = -1;

// TODO: This is chosen arbitrarily, but seems to give good enough results for now
const MASS_UPDATE_THRESHOLD = 1000;

const FragmentChangeType = {
    Material:  1,
    Transform: 2,
    Theming:   3,
    Full:      4
};

// A ModelUniformUpdater monitors change events on a single model
// and makes sure that the uniform buffers on GPU are correspondingly updated.
//
// This includes changes on...
//   - FragmentList:  E.g. setting a material, transform, or theming color of a fragment
//   - RenderBatches: Order changes after sorting
//   - Iterator:      Changing from LinearIterator to BVHIterator (which brings different batches)
export class ModelUniformUpdater {

    // Given a fragId, these allow to find where the uniforms for this fragment are stored in the GPU-side UniformBuffers.
    // May contain NotOnGPU if there is nothing allocated on GPU for a fragment.
    /** type {Int32Array} */
    #fragIdToBufferIndex; // which gpuBuffer (index into the GPU buffers of ObjectUniformBuilder)
    /** type {Int32Array} */
    #fragIdToItemIndex;   // At which position is it within this buffer.

    /** {FragmentList} */
    #fragmentList;
    /** {Model} */
    #model;

    // Currently used whenever we have to enumerate all RenderBatches of a model.
    // Should be replaced by a more scalable solution that is more dynamic and only knows about things on GPU.
    /** ModelIteratorBVH|ModelIteratorLinear */
    #iterator;

    // Manages all object uniform buffers
    /** ObjectUniformBuffer */
    #uniforms;

    // Bound callback functions
    #boundIteratorChangedCallback     = this.#iteratorChangedCallback.bind(this);
    #boundFragOrderChangedCallback    = this.#fragOrderChangedCallback.bind(this);
    #boundMeshSetCallback             = this.setOneFragment.bind(this);
    #boundTransformChangedCallback    = this.updateTransform.bind(this);
    #boundMaterialChangedCallback     = this.setMaterial.bind(this);
    #boundObjectFlagsChangedCallback  = this.#markScenesDirty.bind(this);
    #boundThemingColorChangedCallback = this.setThemingColor.bind(this);

    // Indicates that many fragments are updated simultaneously. We stop updating individual uniforms in this case.
    #massUpdate  = false;
    #updateCount = 0;

    // Needs to be notified if changes require a re-recoding of RenderBundles
    #renderer;

    /**
     * @param {Model}               model
     * @param {ObjectUniformBuffer} uniforms
     * @param {Renderer}            renderer
     */
    constructor(model, uniforms, renderer) {

        this.#model    = model;
        this.#renderer = renderer;

        // Listen to fragment data changes
        this.#fragmentList = model.getFragmentList();
        this.#registerFragmentListCallbacks();

        // Init lookup tables
        const fragCount = this.#fragmentList.getCount();
        this.#fragIdToBufferIndex = new Int32Array(fragCount);
        this.#fragIdToItemIndex   = new Int32Array(fragCount);
        this.#clearFragmentLookup(); // init all with 'NotOnGPU'

        this.#uniforms = uniforms;

        // Listen to iterator changes
        model.registerIteratorChangedCallback(this.#boundIteratorChangedCallback);

        // The model is already initialized at this point, so the initial iterator is set and we need to query it.
        this.#iteratorChangedCallback(this.#model.getIterator());
    }

    dtor() {
        // Remove callbacks
        this.#model.removeIteratorChangedCallback(this.#boundIteratorChangedCallback);
        this.#clearFragmentListCallbacks();
        this.#clearRenderbatchCallbacks();
    }

    #registerFragmentListCallbacks() {
        this.#fragmentList.registerMeshSetCallback(this.#boundMeshSetCallback);
        this.#fragmentList.registerTransformChangedCallback(this.#boundTransformChangedCallback);
        this.#fragmentList.registerMaterialChangedCallback(this.#boundMaterialChangedCallback);
        this.#fragmentList.registerObjectFlagsChangedCallback(this.#boundObjectFlagsChangedCallback);
        this.#fragmentList.registerThemingColorChangedCallback(this.#boundThemingColorChangedCallback);
    }

    #clearFragmentListCallbacks() {
        this.#fragmentList.removeMeshSetCallback(this.#boundMeshSetCallback);
        this.#fragmentList.removeTransformChangedCallback(this.#boundTransformChangedCallback);
        this.#fragmentList.removeMaterialChangedCallback(this.#boundMaterialChangedCallback);
        this.#fragmentList.removeObjectFlagsChangedCallback(this.#boundObjectFlagsChangedCallback);
        this.#fragmentList.removeThemingColorChangedCallback(this.#boundThemingColorChangedCallback);
    }

    #clearRenderbatchCallbacks() {
        this.#forAllBatches(scene => {
            scene.removeFragOrderChangedCallback(this.#boundFragOrderChangedCallback);
        });
    }

    /**
     * Sets and uploads the material reference uniform for the object at the specified index.
     * @param {number}         fragId     - The fragment to update.
     * @param {THREE.Material} material   - The material to associate with the fragment.
     * @param {boolean}        fromLoader - Hint about the origin of the material change - used for optimization (see below)
     */
    setMaterial(fragId, material, fromLoader) {
        this.#updateFragment(fragId, FragmentChangeType.Material, material);

        // No need to invalidate bundles during the load process. Batches won't be complete yet anyway, so we're not
        // using bundles, too.
        if (!fromLoader) {
            // We invalidate aggressively here. In many cases, invalidation might not actually be required. But if the
            // material is new, or if the fragments previously required a different shader (e.g. due to a different
            // texture configuration), we have to invalidate. It's just simpler to invalidate in all cases.
            this.#renderer.invalidateRenderBundles(this.#model);
        }
    }

    /**
     * @param {number} fragId - fragment to be updated
     */
    updateTransform(fragId) {
        this.#updateFragment(fragId, FragmentChangeType.Transform);
    }

    /**
     * @param {number}        fragId
     * @param {THREE.Vector4} color
     */
    setThemingColor(fragId, color) {
        this.#updateFragment(fragId, FragmentChangeType.Theming, color);
    }

    /**
     * @param {number} fragId
     * @returns {boolean} True if we find an entry for the given fragment in the GPU-side uniform buffer.
     */
    fragOnGpu(fragId) {
        return this.#fragIdToBufferIndex[fragId] !== NotOnGPU;
    }

    // Update all uniforms of the given fragment
    setOneFragment(fragId) {
        this.#updateFragment(fragId, FragmentChangeType.Full);
    }

    // Called after uploading uniforms of a RenderBatch or if the fragment order of a RenderBatch changed.
    // Tracks for each fragment where to find the corresponding slot in the
    // UniformBuffer for later updates.
    updateFragmentLookup(
        renderBatch,
        gpuBufferIndex,
        rangeStart,
        count
    ) {
        const cb = (fragId, index) => {
            this.#fragIdToBufferIndex[fragId] = gpuBufferIndex;
            this.#fragIdToItemIndex[fragId]   = rangeStart + index;
        };
        enumFragIds(renderBatch, count, cb);
    }

    // Call when removing this batch from GPU. Mark all fragments as "Not on GPU" again.
    removeFromFragmentLookup(
        renderBatch
    ) {
        const cb = (fragId) => {
            this.#fragIdToBufferIndex[fragId] = NotOnGPU;
            this.#fragIdToItemIndex[fragId]   = NotOnGPU;
        };
        enumFragIds(renderBatch, renderBatch.count, cb);
    }

    // Called in the render loop.
    //
    // If fragments are changed, we usually apply the updates immdediately, but track the number of such updates
    // between two consecutive frames. If they exceed a threshold value, we consider it as a "mass update", just
    // mark all scenes as dirty and update all uniforms in the next frame.
    resetUpdateHeuristic() {
        // TODO: For animations, this means that we will update the first MASS_UPDATE_THRESHOLD fragments individually
        // in the next iteration, before entering mass update mode again. Probably not a big deal, but we might want to
        // optimize this later.
        this.#updateCount = 0;
        this.#massUpdate = false;
    }

    // Get index of the GPU buffer slot that stores the uniforms for the given fragment (if fragment is on GPU).
    getGPUArrayIndex(fragId) {
        return this.#fragIdToItemIndex[fragId];
    }

    //
    // Private methods
    //

    /**
     * @param {number} fragId
     * @param {FragmentChangeType} type
     * @param {THREE.Vector4|THREE.Material} newValue - new value if provided. Only available for theming (Vector4) and Material.
     */
    #updateFragment(fragId, type, newValue) {
        if (this.#updateHeuristic()) {
            return;
        }

        // Find the gpu-side uniform buffer item that is used for this fragment
        const bufferIndex = this.#fragIdToBufferIndex[fragId];
        const index       = this.#fragIdToItemIndex[fragId];

        if (!this.fragOnGpu(fragId)) {
            return;
        }

        switch(type) {
            case FragmentChangeType.Material:
                this.#uniforms.setMaterial(newValue, bufferIndex, index);
                break;
            case FragmentChangeType.Transform:
                this.#uniforms.updateTransform(this.#fragmentList, fragId, bufferIndex, index);
                break;
            case FragmentChangeType.Theming:
                const color = this.#fragmentList.getThemingColor(fragId);
                this.#uniforms.setThemingColor(newValue, bufferIndex, index);
                break;
            case FragmentChangeType.Full:
                this.#uniforms.setOneFragment(this.#fragmentList, fragId, bufferIndex, index);
                break;
        };
    }

    #updateHeuristic() {
        if (this.#massUpdate) {
            return true;
        }

        // If the number of uniform updates exceeds a threshold, we stop updating individual fragments
        // (in some cases, e.g. when updating animation transforms or theming colors).
        // Scenes are marked as dirty and uniforms will be updated in batches in the render loop.
        if (++this.#updateCount > MASS_UPDATE_THRESHOLD) {
            this.#massUpdate = true;
            this.#markScenesDirty();
            return true;
        }

        return false;
    }

    // Loop through all non-empty RenderBatches of the current model iterator
    #forAllBatches(cb) {
        const scenes = this.#iterator.getGeomScenes();
        let scene;
        for (let i = 0; i < scenes.length; ++i) {
            scene = scenes[i];

            // Skip missing or empty batches
            const empty = !scene || !scene.count;
            if (!empty) {
                cb(scene);
            }
        }
    }
    /**
     * Flags all scenes as dirty, so that they can be batch-updated when they are rendered the next time.
     */
    #markScenesDirty() {
        this.#forAllBatches(scene => {
            scene.uniformsNeedUpdate = true;
        });
    }

    // Clears all data that was created for the previous iterator
    #unsetIterator() {

        if (!this.#iterator) {
            return;
        }

        // Clear all allocations within the UniformBuffer, but keep the buffers themselves for reuse.
        // Note: This assumes that uniformStorage is exclusively used for the prior model iterator.
        //       Otherwise, we will need a more expensive dynamic solution.
        this.#uniforms.clear(false);

        // Mark all fragments as "Not on GPU"
        this.#clearFragmentLookup();

        this.#forAllBatches(scene => {

            // Remove callback for fragment order changes
            scene.removeFragOrderChangedCallback(this.#boundFragOrderChangedCallback);

            // Remove attached gpuRanges (will not be used for this batch anymore)
            scene.__gpuUniformBufferRange = null;
        });

        this.#iterator = null;
    }

    #iteratorChangedCallback(iterator) {

        // clear data associated with previous iterator first (if any)
        this.#unsetIterator();

        this.#iterator = iterator;

        // track number of fragments for which we acquired a buffer slot already
        let fragsAdded = 0;

        this.#forAllBatches(scene => {

            // Note that for the initial allocation, it's important to use the "final size" of the batch,
            // i.e. scene.count. For uploading items in #fragOrderChangedCallback, we rather use the
            // actual number of valid fragments in the batch (scene.count - scene.start), which can be smaller for ModelIteratorLinear during loading.

             // acquire buffer range for this batch
             let range = this.#uniforms.allocUniformBufferRange(scene.count);

            if (!range) {
                // No more buffers available. This can happen in rare cases, if Renderbatches exceed the expected
                // MAX_BATCH number of fragments. We could check if the current last buffer can be re-allocated to
                // hold more batches, but this can cause trouble if the buffer is currently used in a draw call.
                // So we simply allocate a new buffer.

                // Compute allocation size, based on required space and device buffer limits
                const remainingFragments = this.#fragmentList.fragments.length - fragsAdded;
                const maxFragsPerBuffer  = this.#uniforms.maxFragsPerBuffer;
                const entryCount         = Math.min(remainingFragments, maxFragsPerBuffer); // how many entries the buffer will store
                const byteSize           = entryCount * OBJECT_STRIDE;

                this.#uniforms.allocNewGPUBuffer(byteSize);

                // Now, we can be sure that there is enough free space
                range = this.#uniforms.allocUniformBufferRange(scene.count);
            }

            scene.__gpuUniformBufferRange = range;

            // There are options to decide the renderBatch length. For ModelIteratorLinear, they may differ during loading:
            //  a) renderBatch.count: load-independent "final" number of fragments
            //  b) renderBatch.lastItem - renderBatch.start: Number of "valid" fragments that were really added so far
            // It's important to use a) the rangeLength here.
            //
            // Why?
            //  - Strictly speaking, b) would be cleaner, because using the full range implies the hidden assumption
            //    that iterating over not-yet-added fragIds works.
            //  - However, since we don't re-register on every add-callback, only using the valid range wouldn't work: Usually, it would
            //    mean here to register nothing at all when registering the ModelIteratorLinear.
            const rangeLength = scene.count;

            // Make sure that fragmentList change callbacks can find the fragment uniforms for this batch on GPU
            this.updateFragmentLookup(scene, range.bufferIndex, range.startIndex, rangeLength);

            // Register callback for fragment order changes
            scene.registerFragOrderChangedCallback(this.#boundFragOrderChangedCallback);

            // Make sure the actual uniform values are uploaded before next render
            scene.uniformsNeedUpdate = true;

            fragsAdded += scene.count;
        });
    }

    // Mark all fragments as "Not on GPU"
    #clearFragmentLookup() {
        this.#fragIdToBufferIndex.fill(NotOnGPU);
        this.#fragIdToItemIndex.fill(NotOnGPU);
    }

    /**
     * A callback that handles updates of the fragment order in a RenderBatch.
     */
    #fragOrderChangedCallback(start, count, renderBatch) {

        // Note: start and count are curently seem to be always the same for a RenderBatch anyway, so we actually don't need these params

        // TODO: Consider simplifying this function by...
        //   - Removing the "updateAll" case below (seem to never happen)
        //   - Using renderBatch as only param and substitute the other two by:
        //      - start = renderBatch.start
        //      - count = (renderBatch.lastItem - renderBatch.start)
        // Note that the count we get here is the range of "already added" fragments.
        // It's not always equal to renderBatch.count, but may be smaller during loading for ModelIteratorLinear.

        // If start is undefined, update all
        const updateAll = (start === undefined);
        if (updateAll) {
            this.#clearFragmentLookup();
            this.#markScenesDirty();
            return;
        }

        //
        // For all fragments on GPU, we need to update the index where to find them in the GPU-side buffer
        // Note that the buffer itself doesn't change.
        const range = renderBatch.__gpuUniformBufferRange;
        this.updateFragmentLookup(renderBatch, range.bufferIndex, range.startIndex, count);

        // Update the actual uniform values
        // Why not just setting renderBatch.uniformsNeedUpdate to true?
        //     In some case, this callback might be triggered within forEachWebGPU(), which is done AFTER the uniformsNeedUpdate check
        //     in MainPass. Therefore, we have to update the uniforms directly - otherwise it would be one frame too late.
        this.#uniforms.updateRange(
            renderBatch.frags,
            renderBatch.getIndices(),
            start,
            count,
            range.startIndex,
            range.bufferIndex
        );
    }
}

// Enumerate a range of fragments in a RenderBatch (always starting from 0)
const enumFragIds = (renderBatch, count, cb) => {
    const indices = renderBatch.getIndices();

    for (let i=0; i<count; i++) {
        const index  = renderBatch.start + i;
        const fragId = indices ? indices[index] : index;
        cb(fragId, i);
    }
};
