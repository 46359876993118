import { convertTexture, copyGPUTexture, UnpackRGB10DepthShader, UnpackRGB10NormalsShader } from './ShaderPassWebGPU.js';
import { getGlobal } from "../../../compat.js";


// Supported formats. Subset of WebGPU formats https://www.w3.org/TR/webgpu/#texture-formats
const Formats = {
    rgba8unorm:   "rgba8unorm",
    bgra8unorm:   "bgra8unorm",
    rgba8uint:    "rgba8uint",
    rgb10a2unorm: "rgb10a2unorm",
    depth32float: "depth32float",
    depth24plus:  "depth24plus",
    r32float:     "r32float",
    rgba32float:  "rgba32float",
};

const isByteColorFormat = (format) => {
    switch (format) {
        case Formats.rgba8unorm:
        case Formats.bgra8unorm:
        case Formats.rgba8uint:
            return true;
        default:
            return false;
    }
}

const Types = {
    unknown: "unknown",
    uint8:   "uint8",
    float32: "float32",
};

function getBytesPerPixel(webGPUTextureFormat) {
    switch (webGPUTextureFormat) {
        case "depth24plus": // depth24 just uses >=24Bit, but always requires 4 bytes per pixel
        case "depth32float":
        case "bgra8unorm":
        case "rgba8uint":
        case "rgb10a2unorm":
        case "r32float":
            return 4;
        case "rgba32float":
            return 16;
        default:
            throw new Error("Unsupported texture format: " + webGPUTextureFormat);
    }
}

function getTypeFromFormat(format) {
    switch (format) {
        // TODO: Add more formats
        case Formats.rgba8unorm:
        case Formats.bgra8unorm:
        case Formats.rgba8uint:
        case Formats.rgb10a2unorm:
            return Types.uint8;
        case Formats.depth32float:
        case Formats.r32float:
        case Formats.rgba32float:
            return Types.float32;
    }
    console.error("Unsupported format: " + format);
    return Types.unknown;
};

function getItemsPerPixel(format) {
    switch (format) {
        case Formats.rgba8unorm:   return 4;
        case Formats.bgra8unorm:   return 4;
        case Formats.rgba8uint:    return 4;
        case Formats.rgb10a2unorm: return 4;
        case Formats.depth32float: return 1;
        case Formats.depth24plus:  return 1;
        case Formats.r32float:     return 1;
        case Formats.rgba32float:  return 4;
        default:
            console.error("Unsupported format: " + format);
            return 0;
    }
}

function getTypedArrayClass(type) {
    switch (type) {
        case Types.uint8:   return Uint8Array;
        case Types.float32: return Float32Array;
        default: return null;
    }
}

function allocTypedArray(
    type,		 // e.g. Types.uint8
    arrayLength  // in array elements
) {
    const ArrayClass = getTypedArrayClass(type);
    return new ArrayClass(arrayLength);
}

function alignTo256(value) {
    return Math.ceil(value / 256) * 256;
}

async function canvasToBlob(canvas) {
    return new Promise(resolve => {
        canvas.toBlob(resolve);
    });
}

async function canvasToBlobUrl(canvas) {
    const blob = await canvasToBlob(canvas);
    const url = URL.createObjectURL(blob);
    return url;
}

// type Pixel        = { r: number, g: number, b: number, a: number };
// type PixelMapping = (inPixel: Pixel, outPixel: Pixel) => void;

// Color mapping to transform normalized float32 rgba normals to a color image.
const ColorizeFloatNormals = (
    inPixel, // in [0,1]^2 range
    outPixel // rgba8
) => {
    outPixel.r = 0.5 * (inPixel.r + 1.0) * 255;
    outPixel.g = 0.5 * (inPixel.g + 1.0) * 255;
    outPixel.b = 0.5 * (inPixel.b + 1.0) * 255;
    outPixel.a = 255;
};

// Annoyingly, Math.random() has no option to set a seed. So, we use a simple peudo-random
// number generator with a seed to ensure stability.
// Returns unsigned 32-bit integer in [0, 2^31-1) range.
function pseudoRandomValue(seed) {
    // Linear Congruential Generator (LCG) with basic constants
    const a = 1103515245;
    const c = 12345;
    const m = 2**31;

    seed = (a * seed + c) % m;
    return seed;
}
const ColorizeIDs = (
    inPixel,  // in [0,1]^2 range
    outPixel, // rgba8
) => {
    // Show black for "no id"
    const NullIdColor = 0x000000;

    // get 32-bit integer
    const value = inPixel.r | inPixel.g << 8 | inPixel.b << 16 | inPixel.a << 24;
    const randomValue = value ? pseudoRandomValue(value) : NullIdColor;

    // Generate random values for red, green, and blue (0-255)
    outPixel.r = randomValue & 0xFF;
    outPixel.g = (randomValue >> 8) & 0xFF;
    outPixel.b = (randomValue >> 16) & 0xFF;
    outPixel.a = 255;
};

// Convert 32-bit float to greyscale image
const DepthToGreyScale = (
    inPixel,  // in [0,1] range
    outPixel, // rgba8
) => {
    const grey = inPixel.r * 255;
    outPixel.r = grey;
    outPixel.g = grey;
    outPixel.b = grey;
    outPixel.a = 255;
}

//
// Utility class for readback and CPU-side handling of texture readback
//
export class CPUTexture {

    width  = 0;
    height = 0;
    data   = null;
    format = null;

    constructor() {};

    // Init CPUSide texture. Data may be shared, copied, or just allocated automatically.
    init(width, height, format, data, copyData = false) {

        this.width  = width;
        this.height = height;
        this.format = format;

        // Alloc, copy, or share data
        if (data) {
            this.data = copyData ? data.slice() : data;
        } else {
            const itemsPerPixel = getItemsPerPixel(format);
            const type          = getTypeFromFormat(format);
            const arrayLength   = width * height * itemsPerPixel;
            this.data = allocTypedArray(type, arrayLength);
        }
        return this;
    }

    // Initializes by copying data from a given GPUTexture
    static async createFromTexture(device, texture) {

        // If a texture neither allows copying nor texture binding, we can't read it.
        const canCopy = texture.usage & GPUTextureUsage.COPY_SRC;
        const canBind = texture.usage & GPUTextureUsage.TEXTURE_BINDING;
        if (!canCopy && !canBind) {
            console.error("GPUTexture must have COPY_SRC or TEXTURE_BINDING usage for readback.");
            return null;
        }

        // If the texture is not copyable, we have to transfer it into a renderable one using a render pass
        let srcTexture = canCopy ? texture : copyGPUTexture(device, texture);

        // create temporary mapped CPUTexture
        const mapped = await new CPUTexture().mapFromTexture(device, srcTexture);

        // copy data to this texture
        const tex = new CPUTexture().copyFrom(mapped, 0, 0, texture.width, texture.height);

        // unmap temporary texture
        mapped.unmap();

        return tex;
    }

    isEmpty() {
        return this.data === null;
    }

    // Copy a rectangular region from a src CPUTexture to this one.
    // Note:
    //  - Memory:  Texture must either by empty (mem is then auto-allocated) or have sufficient size
    //  - Formats: Either they match or this texture is empty.
    //  - Region:  Copy region must fit into src and dst
    copyFrom(
        srcTex,
        fromX, fromY, width, height,
        toX = 0, toY = 0
    ) {
        if (this.isEmpty()) {
            const neededWidth  = toX + width;
            const neededHeight = toY + height;
            this.init(neededWidth, neededHeight, srcTex.format);
        }

        const itemsPerPixel = getItemsPerPixel(srcTex.format);

        for (let y = 0; y < height; y++) {

            // get shallow copy of src row
            const srcStartPixel = srcTex.width * (fromY + y) + fromX;
            const srcEndPixel   = srcStartPixel + width;
            const srcRow        = srcTex.data.subarray(
                itemsPerPixel * srcStartPixel,
                itemsPerPixel * srcEndPixel
            );

            // copy to target row
            const dstStartPixel = this.width * (toY + y) + toX;
            const dstEndPixel   = dstStartPixel + width;
            this.data.set(
                srcRow,
                itemsPerPixel * dstStartPixel,
                itemsPerPixel * dstEndPixel,
            );
        }
        return this;
    }

    // Low-level variant of copyFromTexture that avoids a CPU-side data copy.
    // Restrictions:
    //  - Usage:   Only works for textures in COPY_SRC usage mode.
    //  - Unmap:   Data MUST be unmapped after use (will be invalid afterwards).
    //  - Padding: WebGPU requires the bytesPerRow of the buffer to be a multiple of 256 bytes.
    //             Therefore, the result might contain some padding pixels to the right of the image.
    //             In this case, result.width can be larger than w.
    //  - Formats: Some formats don't support readback, e.g. depth formts.
    async mapFromTexture(
        device,
        texture,
        // Optional: rectangular region to copy
        srcX = 0,
        srcY = 0,
        w = texture.width,
        h = texture.height
    ) {
        const bytesPerPixel = getBytesPerPixel(texture.format);

        // Bytes per row must be a multiple of 256
        let bytesPerRow = alignTo256(texture.width * bytesPerPixel);
        let bufferSize  = bytesPerRow * texture.height;

        const paddedWidth = bytesPerRow / bytesPerPixel;

        // Create a new GPU buffer
        const buffer = device.createBuffer({
            size: bufferSize,
            usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ,
        });

        // Encode commands into commandEncoder
        const encoder = device.createCommandEncoder();
        const origin  = [srcX, srcY, 0];
        encoder.copyTextureToBuffer(
            { texture, origin },
            { buffer,  bytesPerRow },
            [w, h]
        );

        // Submit the commands to the GPU
        device.queue.submit([encoder.finish()]);

        // Make sure the GPU is done before we try reading the data
        await buffer.mapAsync(GPUMapMode.READ);

        // Create a new array view for the buffer
        const type       = getTypeFromFormat(texture.format);
        const ArrayClass = getTypedArrayClass(type);
        const bufferData = new ArrayClass(buffer.getMappedRange());

        this.init(paddedWidth, h, texture.format, bufferData, false);

        // Allow unmap after use
        this.unmap  = () => {
            buffer.unmap();
            buffer.destroy();
        };

        return this;
    }

    //
    // Readback helpers for known targets for WebGPU renderer
    //

    static async createFromColorTarget(renderer) {
        const rt     = renderer.getRenderTargets();
        const device = renderer.getDevice();
        const color  = rt.getColorTarget();
        return await CPUTexture.createFromTexture(device, color);
    }

    // Read viewDepth target (rgb10a2unorm). For convenience, you can include a conversion to float32.
    static async createFromViewDepthTarget(renderer, unpackToFloat = false) {
        const rt        = renderer.getRenderTargets();
        const device    = renderer.getDevice();
        const viewDepth = rt.getViewDepthTarget();

        let srcTex = viewDepth;

        // Run unpack pass to convert into float32 texture if wanted
        if (unpackToFloat) {
            const floatTex = device.createTexture({
                size: [viewDepth.width, viewDepth.height],
                format: 'r32float',
                usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC,
            });
            convertTexture(device, viewDepth, floatTex, UnpackRGB10DepthShader);
            srcTex = floatTex;
        }
        return await CPUTexture.createFromTexture(device, srcTex);
    }

    static async createFromNormalsTarget(renderer, unpackToFloat = false) {
        const rt      = renderer.getRenderTargets();
        const device  = renderer.getDevice();
        const normals = rt.getNormalsTarget();

        let srcTex = normals;

        if (unpackToFloat) {
            const floatTex = device.createTexture({
                size: [normals.width, normals.height],
                format: 'rgba32float',
                usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC,
            });
            convertTexture(device, normals, floatTex, UnpackRGB10NormalsShader);
            srcTex = floatTex;
        }
        return await CPUTexture.createFromTexture(device, srcTex);
    }

    static async createFromIdTarget(renderer, idTargetIndex = 0) {
        const rt     = renderer.getRenderTargets();
        const device = renderer.getDevice();
        const id     = rt.getIdTarget(idTargetIndex);
        return await CPUTexture.createFromTexture(device, id);
    }

    //
    // Some basic format conversion helpers (CPU-side and in-place)
    //

    // Swaps in-place between rgba8unorm and bgra8unorm formats.
    swizzelRGChannels() {

        const isRgba = this.format === CPUTexture.Formats.rgba8unorm;
        const isBgra = this.format === CPUTexture.Formats.bgra8unorm;
        if (!isRgba && !isBgra) {
            console.error("SwizzelRGChannels only supported for rgba8unorm and bgra8unorm formats.");
            return null;
        }

        for (var i = 0; i < this.data.length; i += 4) {
            const tmp      = this.data[i];
            this.data[i]   = this.data[i+2];
            this.data[i+2] = tmp;
        }
        this.format = isRgba ? CPUTexture.Formats.bgra8unorm : CPUTexture.Formats.rgba8unorm;
        return this;
    }

    // Converts single-channel float32 depth values in [0,1] to a new fully opaque rgba8 greyscale image.
    toGreyScaleDepth() {
        return this.toColorImage(DepthToGreyScale);
    }

    // float32 normals (in [-1,1]^3) to rgba8 color image
    toColorizedNormals() {
        return this.toColorImage(ColorizeFloatNormals);
    }

    toColorizedIds() {
        return this.toColorImage(ColorizeIDs);
    }

    // Set all alpha components to constant values.
	// Only for formats rgba8unorm, rgba8uint, and bgra8unorm.
    //   @param {number} alphaByte - in [0,1]
    fillAlpha(alpha = 1.0) {
        if (!isByteColorFormat(this.format)) {
            console.error("fillComponent only supported for rgba8unorm, bgra8unorm, and rgba8uint formats.");
        }

        const val = 255 * alpha;
        for (let i=0; i<this.data.length; i+=4) {
            this.data[i+3] = val;
        }
    }

    getPixel(x, y, outPixel) {
        const itemsPerPixel = getItemsPerPixel(this.format);
        const index = itemsPerPixel * (y * this.width + x);

        outPixel.r = this.data[index];
        if (itemsPerPixel > 1) outPixel.g = this.data[index+1];
        if (itemsPerPixel > 2) outPixel.b = this.data[index+2];
        if (itemsPerPixel > 3) outPixel.a = this.data[index+3];
    }

    setPixel(x, y, inPixel) {
        const itemsPerPixel = getItemsPerPixel(this.format);
        const index = itemsPerPixel * (y * this.width + x);

        this.data[index]   = inPixel.r;
        if (itemsPerPixel > 1) this.data[index+1] = inPixel.g;
        if (itemsPerPixel > 2) this.data[index+2] = inPixel.b;
        if (itemsPerPixel > 3) this.data[index+3] = inPixel.a;
    }

    // Create a new rgba8 image using a given color mapping.
    // (CPU-side and slow but useful for debugging)
    toColorImage(colorMapping) {
        const result = new CPUTexture().init(this.width, this.height, Formats.rgba8unorm);

        // Note: The component names (rgba) are assuming that components are stored in the
		//       order [r,g,b,a]. Therefore, for bgra images, the meanings of r and b are swapped.
        const inPixel  = { r: 0, g: 0, b: 0, a: 0 };
        const outPixel = { r: 0, g: 0, b: 0, a: 0 };
        for (var y = 0; y < this.height; y++) {
            for (var x = 0; x < this.width; x++) {
                this.getPixel(x, y, inPixel);
                colorMapping(inPixel, outPixel);
                result.setPixel(x, y, outPixel);
            }
        }
        return result;
    }

    // Copy image to an html canvas element
    // Requirements:
    //  - Supported formats: rgba8unorm, bgra8unorm, r32float (displayed as greyscale)
    toCanvas(
        canvas = null,		 // auto-created if null
        x = 0, y = 0,        // Optional: rectangular src region to copy
        width  = this.width,
        height = this.height
    ) {
        // For float textures, convert to greyscale first
        let image = this;
		if (this.format === "r32float") {
            image = this.toColorImage(DepthToGreyScale);
        }

        // Note: Unlike WebGL, we don't need the y-flip for WebGPU, because y-axis
        //       in screen space points down like in canvas 2D context.

        // Create canvas element
        canvas        = canvas || getGlobal().document.createElement('canvas');
        canvas.width  = width;
        canvas.height = height;

        var ctx = canvas.getContext('2d');

        var imgData;
        var cbuf = new Uint8ClampedArray(image.data);
        imgData  = new ImageData(cbuf, width, height);

        ctx.putImageData(imgData, x, y, 0, 0, width, height);

        return canvas;
    }

    // Returns an image blob url.
    async toImageUrl() {
        const canvas = this.toCanvas();
        const url    = canvasToBlobUrl(canvas);
        return url;
    }

    // Debugging convenience helper: Opens a new browser tab to show the content of this texture.
    async showInNewTab() {
        const url = await this.toImageUrl();
        window.open(url);
    }
}
