import vertexTextureQuad from "../post/quad.vert.wgsl";
import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import ghosting from "../chunks/ghosting.wgsl";
import gradientShader from "./gradient.wgsl";

export function GradientPass(renderer) {

	let _renderer = renderer;
	let _device;

	// Opacity used for gradient pass output.
	let _alpha = 1.0;

	let _pipeline;
	let _passDescriptor;
	let _vShader;
	let _fShader;

	// Used to delay pipeline creation until clear colors are known.
	let _clearColorsInitialized = false;
	let _r1, _g1, _b1, _r2, _g2, _b2;

	// Requires that device and shaders exists and clearConstants are known.
	function createPipeline() {
		_pipeline = _device.createRenderPipeline({
			layout: "auto",
			vertex: {
				module: _vShader,
				entryPoint: "main"
			},
			fragment: {
				module: _fShader,
				entryPoint: "main",
				targets: [
					{
						format: _renderer.getRenderTargets().getColorTarget().format,
						blend: {
							// Gradient pass overwrites destination values.
							// We output pre-multiplied alpha with a value set by pass.setBlendConstant([r, g, b, a]);
							// The shader must output unmultiplied-alpha rgb values with alpha set to 1.0.
							color: {
								operation: 'add',
								srcFactor: 'constant', // set by pass.setBlendConstant
								dstFactor: 'zero',					
							},
							alpha: {
								operation: 'add',
								srcFactor: 'constant', // set by pass.setBlendConstant
								dstFactor: 'zero',
							}
						}
					}
				],
				constants: {
					0: _r1, 1: _g1, 2: _b1,
					3: _r2, 4: _g2, 5: _b2
				}
			},
			primitive: {
				topology: 'triangle-list',
				cullMode: 'back',
			}
		});
	}

	function createResources() {

		if (!_vShader) {
			_vShader = _device.createShaderModule({ code: vertexTextureQuad });
		}

		if (!_fShader) {
			_fShader = _device.createShaderModule({ code: $wgsl(gradientShader, ghosting) });
		}

		// Pipeline creation fails if clear colors are undefined. Creating a throw-away pipeline with "some" values
		// wouldn't make sense either and the final values depend on viewer settings and are not known yet at this point.
		// Therefore, we defer pipeline creation as long as we have no proper values.
		if (!_pipeline && _clearColorsInitialized) {
			createPipeline();
		}

		if (!_passDescriptor) {
			_passDescriptor = {
				colorAttachments: [
					{
						// view is acquired and set in render loop.
						view: undefined,

						//clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 0.0 }, // Ignored as our blend settings overwrite the destination.
						loadOp: 'clear',
						storeOp: 'store',
					},
				],

			};
		}

	}

	this.init = function() {
		_device = _renderer.getDevice();
		createResources();
	};

	this.setAlpha = function(alpha) {
		_alpha = alpha;
	}

	// Requires that init() was called and clear colors are set.
	this.run = function() {

		if (!_device || !_clearColorsInitialized) {
			return;
		}

		let commandEncoder = _device.createCommandEncoder();

		_passDescriptor.colorAttachments[0].view = _renderer.getRenderTargets().getColorTargetView();

		let pass = commandEncoder.beginRenderPass(_passDescriptor);
		
		pass.setPipeline(_pipeline);
		pass.setBlendConstant([_alpha, _alpha, _alpha, _alpha]);
		pass.draw(3);
		pass.end();

		_device.queue.submit([commandEncoder.finish()]);

	};

	this.setClearColors = function(r1, g1, b1, r2, g2, b2) {
		_r1 = r1;
		_g1 = g1;
		_b1 = b1;

		_r2 = r2;
		_g2 = g2;
		_b2 = b2;

		_pipeline = null;
		if (_device) {
			createPipeline();
		}
		_clearColorsInitialized = true;
	};

	this.getClearColors = function() {
		return [_r1, _g1, _b1, _r2, _g2, _b2];
	};

}
